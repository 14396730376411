import React, {useState} from 'react';
import { Text,View, StyleSheet,Dimensions, TouchableOpacity, PixelRatio, Linking} from 'react-native';
import {FontAwesome} from 'react-native-vector-icons'
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'

i18n.translations = {
    en: english,
    he: hebrew
}

const PR =  PixelRatio.get()
const ICON_CONTAINER_SIZE = 40;
const ICON_SIZE = 22
const WINDOW_HEIGHT = Dimensions.get('window').height;
// Linking.openURL('https://www.facebook.com/SR-Cosmetics-USA-101040242483724') }
//  <TouchableOpacity onPress={() => Linking.openURL(`http://instagram.com/_u/srcosmeticsusa`)}>

const FB_PAGE_ID = '101040242483724'
const fbUrlScheme = `fb://page/${FB_PAGE_ID}`;
const INSTAGRAM_PAGE_USERNAME = 'srcosmeticsusa'
const instagramUrlScheme = `instagram://user?username=${INSTAGRAM_PAGE_USERNAME}`

const ConsultFooter = () => {
return (
<View style={{alignItems:'center', textAlign:'center', marginBottom: WINDOW_HEIGHT*0.07 + 25, }}>
{/* <View style={{backgroundColor:'#ccc', height:1, width: '95%'}} /> */}
<Text style={{fontSize:13, color:'#000', fontFamily:'latoregular'}}>{i18n.t("ConsultFooter.ConsultWith")} </Text>
<View style={{flexDirection:'row', justifyContent:'center'}}>
    <TouchableOpacity onPress={() => {
            Linking.openURL(`https://www.facebook.com/SR-Cosmetics-USA-101040242483724`)
    }}>
        <View style={[styles.iconStyle, {backgroundColor:'#3b5998'}]}>
            <FontAwesome name="facebook" color="#ffffff" size={ICON_SIZE}/>
        </View>
    </TouchableOpacity>
    <TouchableOpacity onPress={()=> Linking.openURL('whatsapp://send?text=Hey, I just came from Face-It application and I have a question&phone=+18334322348')}>
        <View style={[styles.iconStyle, {backgroundColor:'#25D366'}]}>
            <FontAwesome name="whatsapp" color="#ffffff" size={ICON_SIZE}/>
        </View>
    </TouchableOpacity>
    <TouchableOpacity onPress={() => {
        Linking.canOpenURL(instagramUrlScheme)
        .then((supported) =>{
            console.log('Supported = ', supported)
            Linking.openURL(
                supported
                    ? instagramUrlScheme
                    : `https://www.instagram.com/${INSTAGRAM_PAGE_USERNAME}`
                )
        }).catch((err) => console.error('An error occurred', err)); }}>
        <View style={[styles.iconStyle, {backgroundColor:'#e4405f'}]}>
            <FontAwesome name="instagram" color="#ffffff" size={ICON_SIZE}/>
        </View>
    </TouchableOpacity>
    <TouchableOpacity onPress={ () => Linking.openURL('mailto:keith@srcosmeticsusa.com?subject=Face-It application Issue') }>
        <View style={[styles.iconStyle, {backgroundColor:'#a3a3a3'}]}>
                <FontAwesome name="envelope-o" color="#ffffff" size={ICON_SIZE}/>
        </View>
    </TouchableOpacity>
    <TouchableOpacity onPress={ () => Linking.openURL(`tel:+18334322348`)}>
        <View style={[styles.iconStyle, {backgroundColor:'#5d9db3'}]}>
            <FontAwesome name="phone" color="#ffffff" size={ICON_SIZE}/>
        </View>
    </TouchableOpacity>
</View>
</View>

)
}


const styles = StyleSheet.create({
    iconStyle: {
        justifyContent:'center',
        alignItems:'center',
        width: ICON_CONTAINER_SIZE,
        height: ICON_CONTAINER_SIZE,
        borderRadius:30,
        marginHorizontal:5,
        marginTop:5,
        borderColor:'#ececec',
        borderWidth:2
    }

})

export default ConsultFooter;
