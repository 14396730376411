import createDataContext from './createDataContext';

// const baseURL = 'https://acne.axioma-in.com:8000'
const baseURL = 'https://api.srcosmeticsusa.com:8000'
// const baseURL = 'http://10.0.0.26:8000'
// const baseURL = 'http://10.0.0.26:5000'
// const baseURL = 'https://acne.axioma-in.com:8015'
// const baseURL = 'http://10.0.0.7:8000'


const HistoryReducer = (state, action) => {
  switch (action.type) {
      case 'set_history_items':
        const set = new Set()
        const filteredArr = action.payload.filter(el => { // filtering duplicates from history items
          const isDuplicate = set.has(el);
          set.add(el);
          return !isDuplicate;
        })
        // //console.log('Filtered arr: ', filteredArr)
        return { ...state , historyFileNames: filteredArr }
      case 'delete_history':
        return {...state, historyFileNames: []}
    default:
      return state;
  }
};


// const addToHistory = dispatch => (type, mail, urls) => {
//   const historyDetails = {type : type ,mail: mail, urls : urls}
//   fetch(baseURL + '/add_to_history', { 
//     method: "POST",
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(historyDetails)
//   })
//   .then(response => response.json())
//   .then(response => {

//   })
//       .catch((error) => {
//      //console.log(error);
//    });
// }

const recognizeFace = dispatch => (type, mail, url) => {
  const data = {type : type ,mail: mail, url : url}
  console.log('DATA: ', data)
  fetch(baseURL + '/recognize_face', { 
    method: "POST",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(response => response.json())
  .then(response => {
      if (response.status === 'OK') {
        dispatch({type: 'set_history_items', payload: response.msg}) // send array with filenames
      }
      else if (response.status === 'error') {
        dispatch({type: 'delete_history'})

      }
  })
      .catch((error) => {
     console.log('Error inc recognize face:',error);
   });
}

const findHistory = dispatch => (uri) => {
  fetch('https://run.mocky.io/v3/86d28758-2969-45a6-86dd-5543ca3ccdd6', {
    method: "POST",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(uri)
  })
  .then(response => response.json())
  .then(response => {
    dispatch({type: 'set_history_items', payload: response})
  })
      .catch((error) => {
     //console.log("2222222222",error);
   });


    // analyzerApi.post('https://run.mocky.io/v3/86d28758-2969-45a6-86dd-5543ca3ccdd6' , uri).then((result) => {
    //     dispatch({type: 'set_history_items', payload: result.data}) 
    // })
}

const deleteHistory = dispatch => () => {
  dispatch({type: 'delete_history'})
}

export const { Context, Provider } = createDataContext(
    HistoryReducer,
  { findHistory, deleteHistory, recognizeFace},
  { historyFileNames: []} 
);
