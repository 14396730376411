import React, { useContext,useState,useRef,useEffect } from 'react';
import { View, StyleSheet, Text, Dimensions,TextInput,ImageBackground,TouchableOpacity,ScrollView,Image,BackHandler,Platform, Linking } from 'react-native';
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import {Context as TransactionContext} from '../context/TransactionContext'
import { NavigationEvents } from 'react-navigation';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ActivityIndicator, Chip,Divider } from 'react-native-paper';
import HTML from "react-native-render-html";
import {Button} from 'react-native-elements'
import {FontAwesome5} from 'react-native-vector-icons'
import ProductsList from '../components/ProductsList'
import { RadioButton } from 'react-native-paper';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as Device from 'expo-device';


const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
// const WINDOW_WIDTH = Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;

i18n.translations = {
    en: english,
    he: hebrew
}


const ProtocolsEndUsersScreen = ({navigation}) => {
    const { state: {lang,protocol, productsLink} ,getProtocol, resetProtocol, getProductsLink } = useContext(TransactionContext)
    const[disease, setDisease] = useState(null)
    const[showSpinner,setShowSpinner] = useState(false)
    const [ageGroup, setAgeGroup] = useState(null)
    const [countryCode, setCountryCode] = useState(null);

    // Alert stuff
    const [showAlert,setShowAlert] = useState(false)
    const [alertTitle,setAlertTitle] = useState(null)
    const [alertMsg,setAlertMsg] = useState(null)
    const [alertConfirmText,setAlertConfirmText] = useState(null)
    const [alertCancelText,setAlertCancelText] = useState(null)
    const [cancelPressedCallback,setCancelPressedCallback] = useState(null)
    const [confirmPressedCallback,setConfirmPressedCallback] = useState(() => () => {})
    const [alertShowCancelButton,setAlertShowCancelButton] = useState(false)

    useEffect(() => {
        setDisease(null)
        setShowSpinner(false)
        AsyncStorage.getItem('country_code', (err,result) => setCountryCode(result))
        navigation.setParams({'showProducts': false})
        const backAction = () => {
            navigation.setParams({'showProducts': false})
            return true
          };
      
          const backHandler = BackHandler.addEventListener(
            "hardwareBackPress",
            backAction
          );
      
          return () => backHandler.remove();

    }, [])

    useEffect(() => {
        setShowSpinner(false)
        //console.log("Protocol changes: ",protocol)
    }, [protocol])

    useEffect(() => {
        setDisease(null)
    }, [lang])

    useEffect(()=>{
        //console.log(navigation.state.params)
    },[navigation.state.params])


    const openSimpleAlert = (title, msg) => { // alert for simple alerts - title + message + confirm button that dismisses alert
        setAlertTitle(title)
        setAlertMsg(msg)
        setAlertConfirmText('OK')
        setAlertShowCancelButton(false)
        setConfirmPressedCallback(() => () => {setShowAlert(false)})
        setShowAlert(true)
    }      


    const handleProtocolClick = (d) => {
        resetProtocol()
        setAgeGroup(null)
        setShowSpinner(true)
        setDisease(d)
        getProtocol(d,false,lang,false)
        getProductsLink(d === 'Eczema / Skin Redness' ? 'Eczema' : d,false, false,false) // disease = d , pro=false, showScars = false, showPigmentation = false
    }

    const handleAgeGroupChange = (ageGroup) => {
        setAgeGroup(ageGroup)
        resetProtocol()
        setShowSpinner(true)
        setDisease(ageGroup)
        getProtocol(ageGroup,false,lang,false)

    }

    const translatorEnToHe = (d) => {
        switch(d){
            case 'Mild Acne':
                return 'אקנה קל'
            case 'Medium Acne':
                return 'אקנה בינוני'
            case 'Severe Acne':
                return 'אקנה חמור'
            case 'Eczema / Skin Redness':
                return 'אקזמה / אדמומיות בעור'
            case 'Herpes':
                return 'הרפס'
            case 'Scars':
                return 'צלקות'
            case 'Pigmentation':
                return 'כתמים'
            case 'Anti Aging':
                return 'קמטים'
            case 'Eye Care':
                return 'טיפול עיניים'
            case 'Healthy Skin':
                return 'עור בריא'
            case 'Pigmentation':
                return 'כתמים'
            case 'Wrinkles':
                return 'קמטים'            
        }
    }

    return (
        <ImageBackground
        source={require('../../assets/app_background.png')}
        resizeMode='cover'
        style={styles.background}>
        <View style={styles.mainContainer}>
        <ScrollView style={{backgroundColor: 'transparent',flex:1}}>
            <NavigationEvents onWillFocus={()=>{setDisease(null); navigation.setParams({'showProducts': false})}}/>
            <NavigationEvents onWillBlur={()=>{resetProtocol() ;setDisease(null); navigation.setParams({'showProducts': false})}}/>
        {(navigation.state.params && navigation.state.params.showProducts==false) ? 
        <>
        <Text style={styles.title}>{i18n.t("ProtocolsScreen.TitleUser")}</Text>

            <View style={styles.chipSection}>
                <Text style={styles.chipSectionTitle}>{i18n.t('ProtocolsScreen.SkinConditions')}</Text>
                <View style={{flexDirection:lang === 'en' ? 'row' : 'row-reverse', justifyContent:'center'}}>
                    <Chip 
                        textStyle={disease === 'Mild Acne' ? styles.activeChipText : styles.chipText} 
                        style={disease === 'Mild Acne' ? styles.activeChipStyle : styles.chipStyle} 
                        onPress={() => handleProtocolClick('Mild Acne')}>
                        {i18n.t('ProtocolsScreen.MildAcne')}
                    </Chip>
                    <Chip 
                        textStyle={disease === 'Medium Acne' ? styles.activeChipText : styles.chipText} 
                        style={disease === 'Medium Acne' ? styles.activeChipStyle : styles.chipStyle} 
                        onPress={() => handleProtocolClick('Medium Acne')}>
                    {i18n.t('ProtocolsScreen.MediumAcne')}                    
                    </Chip>
                    <Chip 
                        textStyle={disease === 'Severe Acne' ? styles.activeChipText : styles.chipText} 
                        style={disease === 'Severe Acne' ? styles.activeChipStyle : styles.chipStyle} 
                        onPress={() => handleProtocolClick('Severe Acne')}>
                        {i18n.t('ProtocolsScreen.SevereAcne')}
                    </Chip>
                </View>
                <View style={{flexDirection:lang === 'en' ? 'row' : 'row-reverse', justifyContent:'center'}}>
                    <Chip 
                        textStyle={disease === 'Eczema / Skin Redness' ? styles.activeChipText : styles.chipText} 
                        style={disease === 'Eczema / Skin Redness' ? styles.activeChipStyle : styles.chipStyle} 
                        onPress={() => {handleProtocolClick('Eczema / Skin Redness')}}>
                        {i18n.t('ProtocolsScreen.Eczema')}
                    </Chip>
                    <Chip 
                        textStyle={disease === 'Herpes' ? styles.activeChipText : styles.chipText} 
                        style={disease === 'Herpes' ? styles.activeChipStyle : styles.chipStyle} 
                        onPress={() => handleProtocolClick('Herpes')}>
                        {i18n.t('ProtocolsScreen.Herpes')}
                    </Chip>
                    <Chip 
                        textStyle={disease === 'Scars' ? styles.activeChipText : styles.chipText}
                        style={disease === 'Scars' ? styles.activeChipStyle : styles.chipStyle} 
                        onPress={() => handleProtocolClick('Scars')}>
                        {i18n.t('ProtocolsScreen.Scars')}
                    </Chip>
                </View>
            </View>
            <View style={styles.chipSection}>
                <Text style={styles.chipSectionTitle}>{i18n.t('ProtocolsScreen.SkinTreat')}</Text>
                <View style={{flexDirection:lang === 'en' ? 'row' : 'row-reverse', justifyContent:'center'}}>
                    <Chip 
                        textStyle={disease === 'Pigmentation' ? styles.activeChipText : styles.chipText} 
                        style={disease === 'Pigmentation' ? styles.activeChipStyle : styles.chipStyle} 
                        onPress={() => handleProtocolClick('Pigmentation')}>
                        {i18n.t('ProtocolsScreen.Pigment')}
                    </Chip>
                    <Chip 
                        textStyle={disease === 'Eye Care' ? styles.activeChipText : styles.chipText} 
                        style={disease === 'Eye Care' ? styles.activeChipStyle : styles.chipStyle} 
                        onPress={() => handleProtocolClick('Eye Care')}>
                        {i18n.t('ProtocolsScreen.EyeCure')}
                    </Chip>
                </View>
            </View>
            <View style={styles.chipSection}>
                <Text style={styles.chipSectionTitle}>{i18n.t('ProtocolsScreen.SkinUpkeep')}</Text>
                <View style={{flexDirection:lang === 'en' ? 'row' : 'row-reverse', justifyContent:'center'}}>
                    <Chip 
                        textStyle={disease === 'Healthy Skin' ? styles.activeChipText : styles.chipText} 
                        style={disease === 'Healthy Skin' ? styles.activeChipStyle : styles.chipStyle} 
                        onPress={() => handleProtocolClick('Healthy Skin')}>
                        {i18n.t('ProtocolsScreen.HealthySkin')}
                    </Chip>
                    <Chip 
                        textStyle={disease === 'Wrinkles' ? styles.activeChipText : styles.chipText} 
                        style={disease === 'Wrinkles' ? styles.activeChipStyle : styles.chipStyle} 
                        onPress={() => handleProtocolClick('Age Defying')}>
                        {i18n.t('ProtocolsScreen.AntiAging')}
                    </Chip>
                </View>
            </View>
            <Text style={{  fontFamily:'latoregular', 
                                color:'#000', 
                                padding: 20, 
                                textAlign:'center',
                                fontSize:18
                                }}>
                                    For More Protocols see Main menu
                </Text>
            {/* <Divider style={{backgroundColor:'#fff', width: '80%', alignSelf:'center'}}/> */}
            {disease && 
            <View style={styles.protocolContainer}>
                     <Text style={{ fontSize:26, fontFamily:'latobold', paddingHorizontal:5, textAlign:lang==='he'?'right':'left', color:'#fff'}}>
                        {i18n.t('ProductList.ProtocolFor')}{' '}<Text style={{color:'#ff5252'}}>{lang === 'en' ? disease : translatorEnToHe(disease)}</Text>
                    </Text>
                    {disease === 'Mild Acne' && 
                        <Text style={{ fontSize:18, marginTop:5, fontFamily:'latobold', paddingHorizontal:5, textAlign:'center', color:'#ff5252'}}>
                            ({i18n.t('Diseases.Rosi')})
                        </Text>
                        }


                        {
                        (disease === 'Age Defying' || ageGroup) &&
                            <View style={{alignItems:'center' ,marginTop:10, alignSelf:'center'}}>
                                <Text style={{color:'#d2d2d2', fontFamily:'latoregular'}}>{i18n.t("ResultPage.ChooseAgeGroup")}</Text>
                                    <RadioButton.Group onValueChange={newValue => handleAgeGroupChange(newValue)} value={ageGroup}>
                                        <View style={{flexDirection: lang==='en' ? 'row' : 'row-reverse',alignItems:'center'}}>
                                            <View style={{flexDirection: lang==='he'?'row':'row-reverse', alignItems:'center'}}>
                                                <RadioButton.Android value='Age Defying 25-35' color="#A0002A" uncheckedColor="#d2d2d2" />
                                                <Text  style={styles.radioButtonText}>25-35</Text>
                                            </View>
                                            <View style={{flexDirection:lang==='he'?'row':'row-reverse',alignItems:'center'}}>
                                                <RadioButton.Android value='Age Defying 35-45' color="#A0002A" uncheckedColor="#d2d2d2" />
                                                <Text style={styles.radioButtonText}>35-45</Text>
                                            </View>
                                            <View style={{flexDirection:lang==='he'?'row':'row-reverse',alignItems:'center'}}>
                                                <RadioButton.Android value='Age Defying 45-55' color="#A0002A" uncheckedColor="#d2d2d2"/>
                                                <Text style={styles.radioButtonText}>45-55</Text>
                                            </View>
                                            <View style={{flexDirection:lang==='he'?'row':'row-reverse',alignItems:'center'}}>
                                                <RadioButton.Android value='Age Defying 55+' color="#A0002A" uncheckedColor="#d2d2d2"/>
                                                <Text style={styles.radioButtonText}>55+</Text>
                                            </View>
                                        </View>
                                    </RadioButton.Group>
                            </View>
                        }

                    <Button type="solid"
                            title={i18n.t('ProtocolsScreen.GotoProducts')}
                            buttonStyle={[styles.productsBtn,{alignSelf: lang === 'en' ? 'flex-start' : 'flex-end'}]}
                            titleStyle={styles.productsBtnText}
                            onPress={ () => Linking.openURL(productsLink)} 
                        />

                    {!protocol || showSpinner ? <ActivityIndicator color={'#fff'} />
                    :        
                                <HTML source={{html: protocol}}/>
                           }
            </View>}
        </> :
        <>
                    <ProductsList 
                            disease={disease==='Wrinkles'? ageGroup : disease} 
                            override={null} 
                            pro={false} 
                            showProductsForScars={false}
                            showAlsoPigment={false}
                            fromProtocols={true}
                            showCosList={false}
                            forceCanada={(countryCode === 'ca' || countryCode === 'us')}
                            />
                </>

        } 
        </ScrollView>
        </View>
        </ImageBackground>
    );
}



const styles = StyleSheet.create({

    mainContainer:{
        flexDirection:'column',
        flex:1,
        marginTop: WINDOW_HEIGHT*0.1,
        marginHorizontal:15,
        marginBottom: WINDOW_HEIGHT*0.1
        },
    chipText:{
        fontFamily:'latoregular',
        fontSize:12
    },
    
    activeChipText: {
        fontFamily:'latobold',
        fontSize:12,
        color: '#fff'
    },
    
    chipStyle:{
        marginVertical:5,
        marginHorizontal:5,
        backgroundColor:'#bababa'
    },
    
activeChipStyle: {
    marginVertical: 5,
    marginHorizontal: 5,
    backgroundColor: "#A0002A",
},

chipSectionTitle:{
    fontSize:14,
    fontFamily:'latobold',
    color:'#A0002A',
    textAlign:'center',
},

chipSection:{
    marginVertical:5
},

title: {
    fontFamily:'latoblack', 
    color:'#000', 
    padding: 20, 
    textAlign:'center',
    fontSize:22
},
productsBtn: {
    backgroundColor:'#A0002A',
    borderColor:'#A0002A',
    borderRadius: 30,
    width: WINDOW_WIDTH * 0.49,
    height: 35,
    marginTop : 15,
    alignSelf:'center',
    marginBottom: 8

},

productsBtnText:{
    color: '#fff',
    fontSize:16,
    fontFamily: 'latoregular'
},

radioButtonText:{
    color:'#d2d2d2',
    fontFamily:'latoregular',
    fontSize: 14,
    
},

background: {
    width: '100%',
    height: '100%',
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0
},

protocolContainer:{
    backgroundColor:'#1D202A', 
    padding:10, 
    borderRadius:20, 
    margin:  5,
    flex:1, 
    width:isComputerWeb ? WINDOW_WIDTH*1.5 : '100%', 
    alignSelf:'center',
}



});

export default ProtocolsEndUsersScreen;