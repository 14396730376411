import {firebaseStorage} from './config'

    export function uriToBlob(uri) {
        return new Promise((resolve, reject) => {
          // //console.log(new Date() ,': START FROM URI TO BLOB')
            const xhr = new XMLHttpRequest();
            xhr.onload = function() {
              // return the blob
              resolve(xhr.response);
            };
            xhr.onerror = function() {
              // something went wrong
              reject(new Error('uriToBlob failed'));
            };
            // this helps us get a blob
            xhr.responseType = 'blob';
            xhr.open('GET', uri, true);
            xhr.send(null);
            // //console.log(new Date() ,': FINISH FROM URI TO BLOB')

          });
    }

    export function uploadToFirebase(blob,type,mail) {
        return new Promise((resolve, reject)=>{
          var dt = new Date() 
          var sec = dt.getSeconds();
          var min = dt.getMinutes();
          var hour = dt.getHours();
          var day = dt.getDate();
          var month = dt.getMonth()+1;
          var year = dt.getFullYear();
          var filename = hour + ':' + min + ':' + sec
          var folder = day + '-' + month + '-' + year
          var storageRef = firebaseStorage.ref();
          // //console.log(type,mail,folder,filename)
          storageRef.child('uploads').child(type).child(mail? mail : 'guest').child(folder).child(filename).put(blob, {
            contentType: 'image/jpeg'
          }).then((snapshot)=>{
            // blob.close();
            resolve(snapshot);
          }).catch((error)=>{
            reject(error);
          });
        });  
    }



