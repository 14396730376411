import { View, StyleSheet, Image, Dimensions,Text, Alert, SafeAreaView, Button,Platform, NativeModules } from 'react-native';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { WebView } from 'react-native-webview';
import { navigate } from '../navigationRef';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NavigationEvents } from 'react-navigation';
import {Context as AuthContext} from '../context/AuthContext'
import {Context as HistoryContext} from '../context/HistoryContext'
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'

i18n.translations = {
  en: english,
  he: hebrew
}

const WINDOW_WIDTH = Dimensions.get('window').width
const WINDOW_HEIGHT = Dimensions.get('window').height

const WebViewCanada = ({navigation}) => {
const [webViewUrl,setWebViewUrl] = useState('https://dandr.ca/account/login')

//js code to extract user email from the login form
const [jsCode,setJsCode] = 
useState("document.getElementsByClassName('account--sign-in')[0].children[0].addEventListener('click' , function() { window.ReactNativeWebView.postMessage(document.querySelectorAll('input[type=email]')[0].value)})")
const type = navigation.getParam('type', 'login')
const { set_pro, set_pro_name,set_pro_mail,signout } = useContext(AuthContext)
const { deleteHistory } = useContext(HistoryContext)
const [proMail, setProMail] = useState(null)
const webViewRef = useRef();

const checkIfPro = () => {
   AsyncStorage.getItem('is_pro', (err,result) => {
    if (result == 'yes' && type === 'login') {
      Alert.alert("","Already Logged in",[{text: "OK",
            onPress: () => navigate('analyzerFlow')
      }],
    
      );

    } else if (result == 'yes' && type === 'logout') {
          Alert.alert("",i18n.t('UserAuth.ProLogout'),[{text: i18n.t('Alerts.OK'),
          onPress: () => {
                          setWebViewUrl('https://dandr.ca/account'); 
                          setProToken('no'); 
                          AsyncStorage.removeItem('pro_name'); 
                          AsyncStorage.removeItem('CA_USA_Pro_Mail');
                          deleteHistory()
                          // NativeModules.DevSettings.reload();
                          // webViewRef.current.reload()
                          navigation.navigate('UPLOAD');
                        }
          }],
    );

    }

    else if (result == 'no' && type === 'logout'){
      Alert.alert("","Already Logged Out",[{text: "OK",
      onPress: () => {navigation.navigate('UPLOAD')}
      }],
);
    }

    else if (result == 'no' && type === 'login'){
      webViewRef.current.reload()
    }
  })
}



   

  const setProToken = (isPro) => {
    return new Promise((resolve,reject) => {
      //console.log('******* setting is_pro = ', isPro)
      AsyncStorage.setItem('is_pro', isPro)
      set_pro(isPro === 'no' ? false : true)
      resolve()
    })
    }

  const setProName = (name) => {
    return new Promise((resolve,reject) => {
    AsyncStorage.setItem('pro_name', name)
    set_pro_name(name)
    resolve()
  })

  }

  const _setProMail = () => {
      //console.log('*LOGIN* 3) Setting pro mail to: ', proMail)
      AsyncStorage.setItem('CA_USA_Pro_Mail', proMail)
      set_pro_mail(proMail)
  }

  const _onNavigationStateChange = (navState ) => {
}


  const _onMessage = (event) => {
      var data = event.nativeEvent.data
      //console.log('From on message:')
      if (data.includes('@') && data.includes('.')) {
        //console.log('*LOGIN* 1) Setting Pro Mail to:', data)
        setProMail(data)
        //changing js code to extract user name from 'after login' page

        setJsCode("window.ReactNativeWebView.postMessage(document.getElementsByClassName('address--info')[0].children[0].innerHTML)")
      }
      else {
        //   var str = data.split(' ')[2].slice(0, -1) // "ido"
        //   var proNameCapitalized = str.charAt(0).toUpperCase() + str.slice(1)
          //console.log('*LOGIN* 2) Setting Pro name to:', data)
            setProToken('yes').then(() => 
            setProName(data).then(() => {
            Alert.alert("",i18n.t('Alerts.LoggedInSuccessPro'),[{text: "OK",
            onPress: () => {navigation.navigate('UPLOAD') ; signout(false) ; _setProMail()}
            }],)
          })
          )

      // changing js code again to extract mail address
      setJsCode("document.getElementsByClassName('Form__Submit')[0].addEventListener('click' , function() { window.ReactNativeWebView.postMessage(document.querySelectorAll('input[type=email]')[0].value)})")

    } 
 
}


    return (

          <>
                <NavigationEvents onWillFocus={checkIfPro}/>
                  <WebView
                  onNavigationStateChange={_onNavigationStateChange}
                  ref={(ref) => webViewRef.current = ref}
                  source={{ uri: type==='login' ? webViewUrl : 'https://d-r-aesthetic-technologies.myshopify.com/account/logout' }}
                  javaScriptEnabled={true}
                  injectedJavaScript={type==='login' ? jsCode : ''}
                  sharedCookiesEnabled
                  onMessage={type==='login' && _onMessage}
                  style={{ flex: 1 }}
                />
          </>

    );
  
}



export default WebViewCanada;
