import React, {useState} from 'react'
import {View, Dimensions,TouchableOpacity, PixelRatio, Image, Platform, Text} from 'react-native'
import { createAppContainer, createSwitchNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createDrawerNavigator } from 'react-navigation-drawer';
import WelcomeScreen from './src/screens/WelcomeScreen';
import UploadScreen from './src/screens/analyzer/UploadScreen'
import ResolveAuthScreen from './src/screens/auth/ResolveAuthScreen';
import AboutUsScreen from './src/screens/AboutUsScreen';
import ContactUsScreen from './src/screens/ContactUsScreen';
import ResultsScreen from './src/screens/analyzer/resultsScreen';
import {Provider as TransactionProvider} from './src/context/TransactionContext';
import {Provider as AuthProvider} from './src/context/AuthContext';
import {Provider as CartProvider} from './src/context/CartContext';
import {Provider as HistoryProvider} from './src/context/HistoryContext';
import { I18nManager } from 'react-native';
import i18n from 'i18n-js';
import * as Localization from 'expo-localization';
import { MenuProvider } from 'react-native-popup-menu';
import { setNavigator } from './src/navigationRef';
import WebViewScreen from './src/screens/WebViewScreen';
import HistoryScreen from './src/screens/HistoryScreen';
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import {FontAwesome5} from 'react-native-vector-icons'
import {Feather,MaterialIcons} from 'react-native-vector-icons'
import {MaterialCommunityIcons} from 'react-native-vector-icons'
import AuthMainScreen from './src/screens/auth/AuthMainScreen';
import CustomDrawerNavigator from './src/components/CustomDrawerNavigator'
import CustomBottomTab from './src/components/CustomBottomTab'
import TermsScreen from './src/screens/TermsScreen';
import { Provider as PaperProvider } from 'react-native-paper';
import ProtocolsScreen from './src/screens/ProtocolsScreen';
import ProtocolsEndUsersScreen from './src/screens/ProtocolsEndUsersScreen'
import WebViewCanada from './src/screens/WebViewCanada';
import { LogBox } from 'react-native';
import * as Device from 'expo-device';
import 'expo-dev-client';
const PR = PixelRatio.get()
const headerRatio = 0.125
const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = Platform.OS === 'android' || Platform.OS === 'ios' ?  Dimensions.get('window').width :  400
const WINDOW_WIDTH_FULL = Dimensions.get('window').width
const WINDOW_HEIGHT = Dimensions.get('window').height
i18n.locale = Localization.locale;
I18nManager.allowRTL(false); // force LTR (will be changed when adding Hebrew and Arabic)
LogBox.ignoreAllLogs();//Ignore all log notifications

Text.defaultProps = Text.defaultProps || {}; // Disabling font scaling !!!
Text.defaultProps.allowFontScaling = false;

const newHeader = (navigation) => {   
  return {
        headerTitle: () => 
        <Image source={ isComputerWeb ? require('./assets/usalogoweb.png') : require('./assets/usalogo.png')} 
               style={ [
                       !isComputerWeb && {
                       height:26,
                       width: 118
                      },
                      isComputerWeb && {
                        height:52,
                        width: 236,
                        marginLeft: 400
                       }
                      ]
                }/>
          ,              
        headerTitleAlign: 'left',
        headerTransparent: true,
        headerTintColor:'#A9042B',
        headerStyle: {
          height: WINDOW_HEIGHT*0.1, 
          backgroundColor:'transparent',          
        },
        headerBackTitleVisible:false,
        headerRight: () =>
        <TouchableOpacity onPress={() => navigation.toggleDrawer()}
                          style={[isComputerWeb && {marginRight: 400}]}>
                <Image source={require('./assets/Icon_open_menu.png')} style={{height: WINDOW_HEIGHT*0.1*0.3, width:WINDOW_HEIGHT*0.1*0.3, marginRight:15}}/>
        </TouchableOpacity>,
  }
}



const fetchFonts = () => {
  return Font.loadAsync({
  latoregular: require('./assets/fonts/Lato-Regular.ttf'),
  latobold: require('./assets/fonts/Lato-Bold.ttf'),
  latoblack:  require('./assets/fonts/Lato-Black.ttf')
  });
}

const analyzerFlow = createStackNavigator({
  UPLOAD: { screen: UploadScreen, navigationOptions:{ headerBackTitleVisible:false, headerBackTitleStyle:{color:'#A9042B', height:10, width:10} }},
  RESULTS: {screen: ResultsScreen, navigationOptions:{ headerBackTitleVisible:false, headerBackTitleStyle:{color:'#A9042B', height:10, width:10} }},
  ProLogin: WebViewScreen,
  ProLoginCanada: WebViewCanada,
},  { 
  defaultNavigationOptions: ({ navigation }) => {
    return newHeader(navigation)
      }}
      )

const historyFlow = createStackNavigator({
  History: {screen: HistoryScreen,},
},  {   defaultNavigationOptions: ({ navigation }) => {
  return newHeader(navigation)
}}
)



const aboutUsFlow = createStackNavigator({
  AboutUs: {screen: AboutUsScreen,},
},  { defaultNavigationOptions: ({ navigation }) => {
  return  newHeader(navigation)
    }
  }
)

const contactUsFlow = createStackNavigator({
  ContactUs: {screen: ContactUsScreen,},
},  { defaultNavigationOptions: ({ navigation }) => {
  return newHeader(navigation)
}}
)

const TermsFlow = createStackNavigator({
  Terms: {screen: TermsScreen,},
},  {   defaultNavigationOptions: ({ navigation }) => {
  return newHeader(navigation)
    }}
)


contactUsFlow.navigationOptions = {
  title: 'CONTACT US',
}

const protocolsFlow = createStackNavigator({
  Protocols: {screen: ProtocolsScreen,},
},  {   defaultNavigationOptions: ({ navigation }) => {
  return newHeader(navigation)
          
      }
    }
)

const protocolsEndUsersFlow = createStackNavigator({
  ProtocolsEndUsersScreen: {screen: ProtocolsEndUsersScreen,},
},  {   defaultNavigationOptions: ({ navigation }) => {
  return newHeader(navigation) 
    }}
)



analyzerFlow.navigationOptions = () =>  {
  return {
    title:  'FACE-IT'
  }
}

const authNavigator = createStackNavigator({
  AuthMain: {screen: AuthMainScreen},
}, { defaultNavigationOptions: ({ navigation }) => {
  return newHeader(navigation)
}})

const MainFlow = createSwitchNavigator({
  Welcome: WelcomeScreen,
  ResolveAuth: ResolveAuthScreen,
    mainFlow: createBottomTabNavigator({
      analyzerFlow,
      authNavigator,
      TermsFlow,
      protocolsFlow,
      protocolsEndUsersFlow,
      aboutUsFlow,
      contactUsFlow,
      historyFlow,
      aboutUsFlow,
  }, {
  tabBarComponent:CustomBottomTab,
}),                            
},)
        

const proLoginFlow = createStackNavigator({
  WebView : {screen: WebViewScreen,}
} ,{   defaultNavigationOptions: ({ navigation }) => {
  return        {   
    headerTitle: () => 
      <Image source={require('./assets/srusalogo.png')} style={{height: WINDOW_HEIGHT*0.13*0.66, width: WINDOW_HEIGHT*0.13*0.66, marginRight: 15}}/>
      ,              
    headerTitleAlign: 'center',
    headerStyle: {
      height: WINDOW_HEIGHT*0.13, 
      backgroundColor:'#c2af7e',
      
    },
    headerTitleStyle:{
      color:'#fff',
      fontSize: WINDOW_HEIGHT*0.13*0.2,
      fontFamily: 'latoregular',
      textTransform:'capitalize'
    },
    headerTintColor: '#ffffff',
    headerRight: () =>
    <TouchableOpacity onPress={() => navigation.toggleDrawer()}>
            <FontAwesome5 name="bars" color="#fff" size={26} style={{marginRight: 15, alignSelf:'center'}} />
          </TouchableOpacity>
      }
    }}
)

const proLoginCanadaFlow = createStackNavigator({
  WebViewCanada : {screen: WebViewCanada,}
} ,{   defaultNavigationOptions: ({ navigation }) => {
  return        {   
    headerTitle: () => 
      <Image source={require('./assets/srusalogo.png')} style={{height: WINDOW_HEIGHT*0.13*0.66, width: WINDOW_HEIGHT*0.13*0.66, marginRight: 15}}/>
      ,              
    headerTitleAlign: 'center',
    headerStyle: {
      height: WINDOW_HEIGHT*0.13, 
      backgroundColor:'#c2af7e',
      
    },
    headerTitleStyle:{
      color:'#fff',
      fontSize: WINDOW_HEIGHT*0.13*0.2,
      fontFamily: 'latoregular',
      textTransform:'capitalize'
    },
    headerTintColor: '#ffffff',
    headerRight: () =>
    <TouchableOpacity onPress={() => navigation.toggleDrawer()}>
            <FontAwesome5 name="bars" color="#fff" size={26} style={{marginRight: 15, alignSelf:'center'}} />
          </TouchableOpacity>
      }
    }}
)




contactUsFlow.navigationOptions = {
  title: 'CONTACT US',
}



  const drawerNavigator = createDrawerNavigator({
      Main: {screen: MainFlow,navigationOptions: 
        { title: 'Face-It Analyzer', 
          drawerIcon: () => (
        <MaterialCommunityIcons
          name="face-recognition"
          size={18}
          color='#000'
        /> )
        } 
 },
      Auth: {screen: authNavigator, navigationOptions:
        {title:'Login / Sign up',
        drawerIcon: () => (
          <Feather
            name="user"
            size={18}
            color='#000'
          /> )   
      }},
      LoginPro: {screen: proLoginFlow, navigationOptions: 
        { title:  'Login - Pro users', // CHANGE HERE
          drawerIcon: () => (
            <Feather
              name="users"
              size={18}
              color='#000'
            /> )   

      }},
      LoginProCanada: {screen: proLoginCanadaFlow, navigationOptions: 
        { title:  'Login - Pro users', // CHANGE HERE
          drawerIcon: () => (
            <Feather
              name="users"
              size={18}
              color='#000'
            /> )   

      }},

      
      ContactUs: {screen: contactUsFlow, navigationOptions: 
        { title:'Contact Us',
          drawerIcon: () => (
            <MaterialIcons
              name="contact-mail"
              size={18}
              color='#000'
            /> )   

      }},
      AboutUs: {screen: aboutUsFlow, navigationOptions: 
        { title:'About Us',
          drawerIcon: () => (
            <MaterialIcons
              name="info"
              size={18}
              color='#000'
            /> )   

      }},


      Terms: {screen: TermsFlow, navigationOptions: 
        { title:'Terms Of Service',
          drawerIcon: () => (
            <FontAwesome5
              name="book"
              size={18}
              color='#000'
            /> )   

      }},

      Protocols: {
        screen: protocolsFlow, navigationOptions: 
        { title:'Protocols',
          drawerIcon: () => (
            <FontAwesome5
              name="book"
              size={18}
              color='#000'
            /> )   

      }
      },
      ProtocolsEndUsersScreen: {
        screen: protocolsEndUsersFlow, navigationOptions: 
        { title:'Protocols end users',
          drawerIcon: () => (
            <FontAwesome5
              name="book"
              size={18}
              color='#000'
            /> )   

      }
      }
  }, {
      drawerPosition:'right',
      drawerWidth: isComputerWeb ? 300 : '70%',
      drawerType: isComputerWeb ? 'slide' : 'front',
      contentComponent: CustomDrawerNavigator,
      
})

  const App = createAppContainer(drawerNavigator);

export default () => {
  const [fontsLoaded, setFontsLoaded] = useState(false)
  if(!fontsLoaded)
  return (
    <AppLoading
      startAsync={fetchFonts} onError={console.warn}
      // onFinish={() => setFontsLoaded(true)} // add timeout here to extend splash screen
      onFinish={() =>  setTimeout( () => {setFontsLoaded(true)} , 2000 )}
    />
  );
  else
  return (
    <PaperProvider>
      <HistoryProvider>
        <CartProvider>
          <AuthProvider>
            <MenuProvider>
              <TransactionProvider>
              <App
                  ref={(navigator) => {
                    setNavigator(navigator);
                  }}

                />
              </TransactionProvider>
            </MenuProvider>
          </AuthProvider>
      </CartProvider>
  </HistoryProvider>
</PaperProvider>
  );
}
