import React, {useContext, useEffect, useState} from 'react';
import { Text,View, TouchableOpacity, PixelRatio, 
    ScrollView, StyleSheet, Dimensions, Image, Platform, Linking} from 'react-native';
import { Overlay } from 'react-native-elements'
import {} from 'react-native-vector-icons'
import * as Animatable from 'react-native-animatable';
import {Context as TransactionContext} from '../context/TransactionContext'
import HTML from "react-native-render-html";
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import {Divider} from 'react-native-paper'
import { LinearGradient } from 'expo-linear-gradient';
import * as Device from 'expo-device';

i18n.translations = {
    en: english,
    he: hebrew
}
const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 

// const WINDOW_WIDTH= Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;
const PR = PixelRatio.get()
const ProductsList = ({disease, pro, override, showProductsForScars, showAlsoPigment, fromProtocols , rosiUser=false}) => {
    const {state: {lang, protocol, scarsProtocol, pigProtocol, productsLink} , getProtocol, getProductsLink} = useContext(TransactionContext)
    const [showProtocol,setShowProtocol] = useState(false)

    const diseaseNameTranslator = (name) => {
        switch(name) {
            case 'אקנה קל': 
                return 'Mild Acne'
            case 'אקנה בינוני':
                return 'Medium Acne'
            case 'אקנה חמור':
                return 'Severe Acne'
            case 'הרפס':
                return 'Herpes'
            case 'אקזמה / אדמומיות בעור': 
                return 'Eczema'
            case 'Eczema / Skin Redness': 
                return 'Eczema'
            case 'עור בריא':
                return 'Healthy Skin'
            case 'כתמים':
                return 'Pigmentation'
            case 'קמטים':
                return 'Wrinkles'
            case 'קמטים לגילאי 25-35':
                return 'Age Defying 25-35'
            case 'קמטים לגילאי 35-45':
                return 'Age Defying 35-45'            
            case 'קמטים לגילאי 45-55':
                return 'Age Defying 45-55'            
            case 'קמטים לגילאי 55+':
                return 'Age Defying 55+'  
            case 'כתמים וקמטים':
                return 'Pigmentation & Age Defying'
            case 'טיפול עיניים':
                return 'Eye Care'
            case 'צלקות':
                return 'Scars'
            default: // english
                return name
        }
    }


    useEffect(() => { 
        var isEczema = (disease === 'Eczema / Skin Redness' || override === 'Eczema / Skin Redness')
        if (!fromProtocols){
            console.log('Products check 1, Override = ', override, 'Disease = ', disease )
            getProtocol(isEczema ? 'Eczema / Skin Redness' : diseaseNameTranslator(override || disease),pro,lang, showProductsForScars, showAlsoPigment)
            getProductsLink(isEczema ? 'Eczema / Skin Redness' : diseaseNameTranslator(override || disease),pro, showProductsForScars, showAlsoPigment)
        }
      }, []);


    return (
<Animatable.View animation="fadeIn" duration={2000}>
    {   !fromProtocols ? 
        <>
            {pro && 
            <Text style={{fontSize:16, marginHorizontal:30, marginBottom:10, textAlign:'center', fontFamily:'latoregular', color:'#1D202A'}}>
                {i18n.t("ResultPage.ThankYouFeedback")}
            </Text>}

            {!showProductsForScars &&  !showAlsoPigment ? 
            <>
                <Text style={{fontSize:16, marginHorizontal:30, marginBottom:5, textAlign:'center', fontFamily:'latoregular', color:'#1D202A'}}>
                    {i18n.t("ResultPage.HereAreSome")} {override || disease} {rosiUser && '/ Rosacea / Seborrhea'}.
                </Text>
                {
                    (disease == 'Mild Acne' || disease == 'אקנה קל' || override == 'Mild Acne' || override == 'אקנה קל') &&
                    <Text style={{fontSize:16, marginHorizontal:30, textAlign:'center', fontFamily:'latoblack', color:'#1D202A'}}>
                        {i18n.t("ResultPage.Acne1Message")}
                    </Text>
                }
            </>
            : showProductsForScars && !rosiUser ? 
                <Text style={{fontSize:16, marginHorizontal:30, marginBottom:5, textAlign:'center', fontFamily:'latoregular', color:'#1D202A'}}>
                {i18n.t("ResultPage.HereAreSome")} {override || disease} {i18n.t("ResultPage.AndScars")}
                </Text> 
            : showProductsForScars && rosiUser?
                <Text style={{fontSize:16, marginHorizontal:30, marginBottom:5, textAlign:'center', fontFamily:'latoregular', color:'#1D202A'}}>
                {i18n.t("ResultPage.HereAreSome")} {override || disease} , Scars and Rosacea / Seborrhea
                </Text> 
            :
            <Text style={{fontSize:16, marginHorizontal:30, marginBottom:5, textAlign:'center', fontFamily:'latoregular', color:'#1D202A'}}>
            {i18n.t("ResultPage.HereAreSome")} {override || disease} {i18n.t("ProductList.AndPig")}
            </Text>}

        </>
        :   <>
                <Text style={{fontSize:14, marginHorizontal:30, marginBottom:5, textAlign:'center', fontFamily:'latoregular', color:'#1D202A'}}>
                    {i18n.t("ResultPage.HereAreSome")}.
                </Text>
                {
                    (disease == 'Mild Acne' || disease == 'אקנה קל') &&
                    <Text style={{fontSize:14, marginHorizontal:30, textAlign:'center', fontFamily:'latoregular', color:'#1D202A'}}>
                        {i18n.t("ResultPage.Acne1Message")}
                    </Text>
                }

            </>

    }
    <View style={{flexDirection:'row', justifyContent: 'center'}}>
    {!fromProtocols && <View style={{flexDirection: 'column'}}>
        <LinearGradient colors={['#A0002A', '#D31631']} style={{borderRadius:6, marginVertical:10,}}>
            <TouchableOpacity style={styles.protocolBtn} onPress={() => Linking.openURL(productsLink)}>
            <Text style={{      
                            color: '#fff',
                            fontSize:isComputerWeb? 18 : 16,
                            textAlign: "center",
                            fontFamily:'latobold'
                        }}>
                Recommended Products
            </Text>       
        </TouchableOpacity>
        </LinearGradient>
        <LinearGradient colors={['#A0002A', '#D31631']} style={{borderRadius:6, marginVertical:10}}>
        <TouchableOpacity style={styles.protocolBtn} onPress={() => setShowProtocol(true)}>
            <Text style={{color:'#fff',fontFamily:'latobold', fontSize:isComputerWeb? 18 : 16}}>
                View Protocol
            </Text>
        </TouchableOpacity>
        </LinearGradient>
        <Overlay isVisible={showProtocol} 
                overlayStyle={styles.protocolPopupOverlay}
                onBackdropPress={() => setShowProtocol(false)}>
            <View style={styles.protocolPopupView} >
            <Image style={[styles.baloon, lang === 'en' ? {right:7} : {left:7}]} source={require('../../assets/shuli.jpg')}/>
                <Text style={styles.protocolMainTitle}>
                    {i18n.t('ProductList.ProtocolFor')}
                </Text>

                <Text style={styles.protocolDiseaseName}>
                    {override || disease} {rosiUser && 'and ' + i18n.t('Diseases.Rosi')}                     
                {
                    showProductsForScars && 
                    <Text>
                {" "}{i18n.t('ProductList.AndScars')}
                    </Text>
                }

                {
                    showAlsoPigment &&
                    <Text>
                {" "}{i18n.t('ProductList.AndPig')}
                    </Text>

                }

                </Text>
                
                <ScrollView persistentScrollbar={true} indicatorStyle='white' 
                            contentContainerStyle={{textAlign: lang === 'he' ? 'right' : 'left', paddingHorizontal:10}} >
                    <HTML source={{ html: protocol }} contentWidth={ WINDOW_WIDTH }  />
                    {
                        showProductsForScars && 
                        <>
                            <Divider style={{backgroundColor:'#fff'}}/>
                            <Text style={{fontSize:18, color:'#D2D2D2',fontFamily:'latobold'}}>{'\n'}{i18n.t('ProductList.ProtocolForScars')}</Text> 
                            <HTML source={{ html: scarsProtocol }} contentWidth={ WINDOW_WIDTH }  />
                        </>
                    }
                    {
                        showAlsoPigment && 
                        <>
                            <Divider style={{backgroundColor:'#fff'}}/>
                            <Text style={{fontSize:18, color:'#D2D2D2', fontFamily:'latobold'}}>{'\n'}{i18n.t('ProductList.ProtocolForPig')}</Text> 
                            <HTML source={{ html: pigProtocol }} contentWidth={ WINDOW_WIDTH }  />
                        </>
                    }

                </ScrollView>
                <LinearGradient colors={['#A0002A', '#D31631']} style={styles.btn}>
                    <TouchableOpacity onPress={()=>{setShowProtocol(false)}}>
                        <Text style={styles.backBtnText}>{i18n.t('ProductList.Back')}</Text>
                    </TouchableOpacity>
                </LinearGradient>
            </View>
        </Overlay>
        </View>
        }
        
        
    </View>

</Animatable.View>
    );

}

const styles = StyleSheet.create({
    protocolBtn: {
        flexDirection:'row',
        borderRadius: 30,
        width: WINDOW_WIDTH*0.65,
        height: WINDOW_HEIGHT*0.05,
        justifyContent: 'space-evenly',
        alignItems:'center',
        alignSelf:'center',
        margin:5,
    },

    btn: {
        borderRadius: 6,
        padding:8,
        marginTop: 10,
        justifyContent:'center',
        width: WINDOW_WIDTH*0.25,
        height: WINDOW_HEIGHT*0.055,
        alignSelf:'flex-end'
    },

    backBtnText: {
        color:'#fff',
        fontFamily:'latobold',
        fontSize:WINDOW_HEIGHT*0.022,
        alignSelf:'center' 
    },
    

    baloon:{
        height:PR<=2 ? 45 : 55,
        width:PR<=2 ? 45 : 55,
        position:'absolute', 
        top:5, 
        borderRadius:80,
        borderWidth:2,
        borderColor:'#ff5252'
    },

    protocolPopupOverlay: {
        backgroundColor: '#1D202A', 
        borderTopRightRadius:20, 
        borderBottomLeftRadius:20, 
        borderWidth:2, 
        borderColor:'#d2d2d2', 
        height:WINDOW_HEIGHT*0.8, 
        width: WINDOW_WIDTH*0.872
    },

    protocolPopupView: {
        flex:1,
        height: '90%',
        width: WINDOW_WIDTH*0.83,
        justifyContent: 'space-between', 
        padding: 10,
    },

    protocolMainTitle:{ 
        fontSize:26,
        marginTop:10, 
        fontFamily:'latobold', 
        color: "#fff", 
        paddingHorizontal:5, 
    },

    protocolDiseaseName:{ 
        fontSize:26, 
        marginTop:14,
        marginBottom:10, 
        fontFamily:'latobold', 
        paddingHorizontal:5, 
        color: '#ff5252'
    }

    
})

export default ProductsList