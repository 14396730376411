import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Image, Dimensions,Text, Alert } from 'react-native';
import {Divider } from 'react-native-elements';
import {TouchableOpacity, TouchableWithoutFeedback } from 'react-native-gesture-handler';
import {EvilIcons} from 'react-native-vector-icons'
import {Entypo} from 'react-native-vector-icons'
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
  } from 'react-native-popup-menu';
import {firebaseAuth} from '../firebase/config'
import {Context as AuthContext} from '../context/AuthContext'
import { WebView } from 'react-native-webview';

const WINDOW_HEIGHT = Dimensions.get('window').height;
const WINDOW_WIDTH = Dimensions.get('window').width;
const headerRatio = 0.125

const MyMenu = ({toggle, navigation}) => {
const [webView, setWebView] = useState(false)
const {signout} = useContext(AuthContext)

const handleProLogin = () => {
    navigation.navigate('WebView')
}

return (

    <View style={{flexDirection:'row'}}>
    <Menu onBackdropPress={() =>  toggle()}>
    <MenuTrigger>
        <TouchableWithoutFeedback onPress={() =>  toggle() } >
            <View style={styles.headerLeft}>
                <Entypo name="chevron-down" color="#fff" size={10} style={{alignSelf:'center'}} />
                <EvilIcons name="user" color="#fff" size={44} style={{alignSelf:'center'}}/>
            </View>
        </TouchableWithoutFeedback>
    </MenuTrigger>
    <MenuOptions optionsContainerStyle={{ marginTop: WINDOW_HEIGHT * headerRatio - 30, marginLeft: 10, borderRadius: 10 }}>
        <MenuOption style={styles.optionStyle} disabled={true}>
            <Text style={{color: '#000', alignSelf:'center'}}>
            {firebaseAuth.currentUser ? 'Hello User!' : 'Hello Guest!'}
            </Text>
        </MenuOption>
        <Divider />
        { !firebaseAuth.currentUser && <MenuOption style={styles.optionStyle} 
                    onSelect={() => navigation.navigate('loginFlow', {transition: ''})}>
            <Text>Sign Up / Login</Text>
            <Entypo  size={20} name="login" style={{alignSelf: 'center', marginLeft:10}}/>
        </MenuOption>}
        <MenuOption text='Login as Professional' style={styles.optionStyle} onSelect={handleProLogin}/>
        <MenuOption text='Change language' style={styles.optionStyle}/>
        {firebaseAuth.currentUser && 
        <MenuOption style={styles.optionStyle} onSelect={signout}>
            <Text>Sign Out</Text>
            <Entypo  size={20} name="log-out" style={{alignSelf: 'center', marginLeft:10}}/>
        </MenuOption>}
    </MenuOptions>
    </Menu>
    </View>
);
}

const styles = StyleSheet.create({
    headerLeft: {
        flexDirection:'row',
        width: WINDOW_WIDTH/3, 
        marginHorizontal: 5,
        justifyContent:'center',
    },
    optionStyle: {
        paddingVertical: 15,
        paddingHorizontal:25,
        fontSize: 20,
        flexDirection: 'row'
    }
})

export default MyMenu;