import axios from 'axios';

let url;
if (__DEV__) {
  // url = 'https://run.mocky.io/v3';
  // url = 'http://10.0.0.25:8000/'
  // url = 'http://104.154.69.118:8000/'
  url = 'https://acne.axioma-in.com:8000/'
} else {
  url = '';
}

const instance = axios.create({
  baseURL: url, 
});


export default instance;