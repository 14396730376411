import React, {useEffect,useState, } from 'react'
import {View, StyleSheet,Text, Dimensions, TouchableOpacity,Image, Platform, Linking} from 'react-native'
import i18n from 'i18n-js';
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import * as Device from 'expo-device';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {firebaseAuth} from '../firebase/config'


const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
const WINDOW_HEIGHT = Dimensions.get('window').height

i18n.translations = {
    en: english,
    he: hebrew,
  };
  
const CustomBottomTab = ({navigation}) => {
const [activeLabel, setActiveLabel] = useState('face_it')


const handleProtocolsClick = () => {
    setActiveLabel('protocols') // set active label - not working right now

    // Need to check if it is pro or regular user
    // If Guest - send end user protocols
    // Else - If registered but not a pro - send to end user protocols
    //        Else - send to pro protocols

    // First, check if user logged in
    var user = firebaseAuth.currentUser
    if ((user && user.emailVerified) || user && user.providerData[0].providerId === 'facebook.com') { // credential sign in + google + apple || facebook - facebook has no email verified field
        
            //Registered, Check if pro
            AsyncStorage.multiGet(['is_pro','pro_name'], (err,result) => {
                if (result[0][1]==null || result[0][1] == 'no') { // Regietered but Not a pro
                    navigation.navigate('ProtocolsEndUsersScreen')

                } else { // Pro user
                    navigation.navigate('Protocols')
                }
            })      
    } else { // Not regitered - guest - go to end user protocols
        navigation.navigate('ProtocolsEndUsersScreen')
    }
    


}


    return (
        <View style={ [ styles.bottomBar, 
                      { flexDirection: i18n.locale === 'en' ? 'row' : 'row-reverse',         
                        left: isComputerWeb ? '30%' : '5%',
                        right: isComputerWeb ? '30%' : '5%',
                    }]}>
            <TouchableOpacity style={styles.menuItemConatiner} onPress={() => {setActiveLabel('history'); navigation.navigate('historyFlow')}}>
                <Image source={require('../../assets/history.png')} style={[styles.bottomMenuIcon]}/>
                <Text style={[styles.label]}>
                    {i18n.t("BottomNav.History")}
                </Text>
            </TouchableOpacity>
            {/* <TouchableOpacity style={styles.menuItemConatiner} onPress={() => {    setActiveLabel('protocols'); navigation.navigate('ProtocolsEndUsersScreen')}}> */}
            <TouchableOpacity style={styles.menuItemConatiner} onPress={handleProtocolsClick}>
                <Image source={require('../../assets/protocols.png')} style={[styles.bottomMenuIcon,{width: WINDOW_HEIGHT*0.03, height: WINDOW_HEIGHT*0.038}]}/>
                <Text style={[styles.label]}>
                    {i18n.t("BottomNav.Protocols")}
                </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.menuItemConatiner} onPress={() => {setActiveLabel('face_it'); navigation.navigate('UPLOAD')}}>
            <Image source={require('../../assets/analyzer.png')} style={[styles.bottomMenuIcon,{width: WINDOW_HEIGHT*0.035,height: WINDOW_HEIGHT*0.035,}]}/>
                <Text style={[styles.label]}>
                    Analyzer
                </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.menuItemConatiner} onPress={() => {Linking.openURL(`https://www.srcosmeticsusa.com/collections/all`)}}>
                <Image source={require('../../assets/products.png')} style={[styles.bottomMenuIcon,{width: WINDOW_HEIGHT*0.033}]}/>
                <Text style={[styles.label]}>
                    {i18n.t("BottomNav.Products")}
                </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.menuItemConatiner} onPress={() => {setActiveLabel('help'); navigation.navigate('Welcome', {fromMenu: true})}}>
                <Image source={require('../../assets/help.png')} style={[styles.bottomMenuIcon,{}]}/>
                <Text style={[styles.label]}>
                    Help
                </Text>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    bottomBar: {
        height: WINDOW_HEIGHT*0.07 , 
        backgroundColor:'#fff',
        position:'absolute',
        // left: '5%',
        // right: '5%',
        bottom: '2%',
        alignItems:'center',
        elevation: 5,
        borderRadius: 9,
        shadowColor: "#BF0D2E4D",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 6,
        shadowRadius: 16.32,

     },
     label:{
        fontSize: isComputerWeb ? 13 : 11, 
        fontFamily:'latoregular',
        textAlign:'center',
        color:'#000',
        padding:2
     },
     menuItemConatiner:{
        justifyContent:'center',
        flex:1,
        alignItems:'center',
        height:'100%',
        marginBottom:'2.5%',
        // backgroundColor:'#ff0'
     },
     faceitbtn:{
        width: WINDOW_HEIGHT*0.06,
        height: WINDOW_HEIGHT*0.06,
     },

     bottomMenuIcon: {
        marginTop:'15%',
        width: WINDOW_HEIGHT*0.036,
        height: WINDOW_HEIGHT*0.036,
        // alignSelf:'center',
        // backgroundColor:'#00f',
        // flex:1,
     }
})

export default CustomBottomTab