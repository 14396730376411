import React, { useState, useEffect, useContext, useRef } from 'react';
import { View, StyleSheet, Text,Dimensions, Image , TouchableOpacity, TextInput, ScrollView,Keyboard,Platform} from 'react-native';
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import {firebaseAuth, firebaseGoogleAuthProvider, firebaseFacebookAuthProvider, firebaseOAuthProvider} from '../firebase/config'
import {Context as AuthContext} from '../context/AuthContext'
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import * as Animatable from 'react-native-animatable';
import { navigate } from '../navigationRef';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Alert } from 'react-native';
import {Context as TransactionContext} from '../context/TransactionContext'
import * as Google from 'expo-google-app-auth';
import * as Google_Web from 'expo-auth-session/providers/google';
import * as Facebook_Web from 'expo-auth-session/providers/facebook';
import * as WebBrowser from 'expo-web-browser';
import * as Facebook from 'expo-facebook';
import * as Crypto from "expo-crypto";
import * as AppleAuthentication from "expo-apple-authentication";
import * as Device from 'expo-device';
import { LinearGradient } from 'expo-linear-gradient';
import { ResponseType } from 'expo-auth-session';
import AwesomeAlert from 'react-native-awesome-alerts';
import {MaterialCommunityIcons} from 'react-native-vector-icons'

WebBrowser.maybeCompleteAuthSession();
const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const isWeb = Platform.OS === 'web'
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
const WINDOW_HEIGHT = Dimensions.get('window').height;
// const WINDOW_WIDTH = Dimensions.get('window').width;

// // This method should be invoked on the page that the auth popup gets redirected to on web, 
// // it'll ensure that authentication is completed properly. On native this does nothing.
// WebBrowser.maybeCompleteAuthSession(); 

i18n.translations = {
    en: english,
    he: hebrew
}

const Signin = ({navigation}) => {
    const ref_forgot_pass = useRef()
    const ref_pass = useRef()
    const [email,setEmail] = useState(null)
    const [emailForgot, setEmailForgot] = useState(null)
    const [password,setPassword] = useState(null)
    const {state: {errorMsg, currentUser}, signin,signout, clearErrorMsg,signinSocial, tryWriteNewUserToKibana} = useContext(AuthContext)
    const [isPro, setIsPro] = useState(false)
    const [showTerms, setShowTerms] = useState(false)
    const [showForgot, setShowForgot] = useState(false)
    const { state: {lang, alertMsgFromContext,alertMsgFromAuthContext} , setAlertMsgFromContext } = useContext(TransactionContext)
    const [passwordHidden, setPasswordHidden] = useState(true)
    // Alert stuff
    const [showAlert,setShowAlert] = useState(false)
    const [alertTitle,setAlertTitle] = useState(null)
    const [alertMsg,setAlertMsg] = useState(null)
    const [alertConfirmText,setAlertConfirmText] = useState(null)
    const [alertCancelText,setAlertCancelText] = useState(null)
    const [cancelPressedCallback,setCancelPressedCallback] = useState(null)
    const [confirmPressedCallback,setConfirmPressedCallback] = useState(() => () => {})
    const [alertShowCancelButton,setAlertShowCancelButton] = useState(false)
    
    
    const [googleRequest, googleResponse, googlePromptAsync] = Google_Web.useAuthRequest({ // google login hook for web
      expoClientId: '2332342585-2grdpamdje7njj8lcvncf6uokp0acsqg.apps.googleusercontent.com',
      iosClientId: '2332342585-2grdpamdje7njj8lcvncf6uokp0acsqg.apps.googleusercontent.com',
      androidClientId: '2332342585-2grdpamdje7njj8lcvncf6uokp0acsqg.apps.googleusercontent.com',
      webClientId: '2332342585-2grdpamdje7njj8lcvncf6uokp0acsqg.apps.googleusercontent.com',
    });
    const [facebookRequest, facebookResponse, facebookPromptAsync] = Facebook_Web.useAuthRequest({
      expoClientId: '5052464328096995',
      iosClientId: '5052464328096995',
      androidClientId: '5052464328096995',
      webClientId: '5052464328096995',
      responseType: ResponseType.Token,
    });
  


    useEffect(() => {
      if (alertMsgFromAuthContext) {
        
      }
    },[alertMsgFromContext, alertMsgFromAuthContext])


    useEffect(()=> {
        console.log('UseEffect is signin activating clearErrorMsg')
        clearErrorMsg()
        checkIfPro()
    },[])




    useEffect(() => {

      // clear state
      setEmail('')
      setEmailForgot('')
      setPassword('')

      // deal with web bug that sign in form password field + forgot password field shared values somehow
      if (showForgot) { // forgot pass screen
        ref_forgot_pass.current.clear()
      } else {// sign in screen
        ref_pass.current.clear()
      }
    },[showForgot])



    useEffect(()=> {
      if (googleResponse?.type === 'success') {
        //console.log('Google auth success: ', googleResponse)
        const { authentication } = googleResponse;

        authentication.idToken = authentication.idToken || null // if undefined - change to null, firebaseGoogleAuthProvider.credential cannot handle undefined, only real values or null
        authentication.accessToken = authentication.accessToken || null // if undefined - change to null, firebaseGoogleAuthProvider.credential cannot handle undefined, only real values or null
        
        // On web, another api call is necessary to get user email + name
        const method = "GET"
        const headers = {Accept: 'application/json','Content-Type': 'application/json'}
        const url = 'https://www.googleapis.com/oauth2/v3/userinfo?access_token=' + authentication.accessToken
        const requestInfo = {method, headers}
        fetch(url,requestInfo).then( response => response.json() ).then(user => {
          //console.log(user)
          let googleUser = {
            idToken: authentication.idToken,
            accessToken: authentication.accessToken,
            user: user
          }
          onSignInGoogle(googleUser)
          }
        )
      } 
      
      else {

      }
    },[googleResponse])

    useEffect(() => {
      //console.log('Facebook web login response: ', facebookResponse)
      if (facebookResponse?.type === 'success') {
        const { accessToken } = facebookResponse.authentication;
        onSignInFacebook(accessToken)
      } else {
      //
      }
    }, [facebookResponse]);


    const openSimpleAlert = (title,msg) => {
      setAlertTitle(title),
      setAlertMsg(msg),
      setAlertConfirmText(i18n.t("Alerts.OK")),
      setAlertShowCancelButton(false)
      setConfirmPressedCallback(() => () => { setShowAlert(false); })
      setShowAlert(true)

    }


    const loginWithApple = async () => {
      try {

          const csrf = Math.random().toString(36).substring(2, 15);
          const nonce = Math.random().toString(36).substring(2, 10);
          const hashedNonce = await Crypto.digestStringAsync(
            Crypto.CryptoDigestAlgorithm.SHA256, nonce);
          //console.log('1')
          const appleCredential = await AppleAuthentication.signInAsync({
            requestedScopes: [
              AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
              AppleAuthentication.AppleAuthenticationScope.EMAIL
            ],
            state: csrf,
            nonce: hashedNonce
          });
          const { identityToken, email, state } = appleCredential;
            //console.log('2', appleCredential)
              if (identityToken) {  
              const provider = new firebaseOAuthProvider("apple.com");
              const credential = provider.credential({
                idToken: identityToken,
                rawNonce: nonce // nonce value from above
              });
              const appleProfileData = await firebaseAuth.signInWithCredential(credential);
              //console.log(appleProfileData)
              navigate('UPLOAD');
              let new_user = {
                Email: appleProfileData.user.email,
                Name: appleProfileData.user.displayName,
                Provider: 'Apple',
                Firebase_UID: appleProfileData.user.uid,
                Area: 'None',
                Date: Date.now()
              }
              tryWriteNewUserToKibana(new_user)
              setAlertMsgFromContext('NowLoggedIn')  
            }
      } catch(e){
        Alert.alert(e.message)
      }
        
      }
    

    const signinWithFacebook = async() => {
        try {
            const permissions = ['public_profile','email'];  // Permissions required, consult Facebook docs
            await Facebook.initializeAsync({appId: '5052464328096995'});
            const {type,token} = await Facebook.logInWithReadPermissionsAsync({permissions});
            console.log('Facebook login response: type = ',type,'token = ', token)
            switch (type) {
              case 'success': {
                onSignInFacebook(token)
                break;
              }
              case 'cancel': {
                //console.log('type=cancel')
              }
            }
        } catch (e) {
          console.log(e)
            if (e.code === 'auth/account-exists-with-different-credential'){
              // setAlertMsgFromContext('DifferentCredential')
              openSimpleAlert('Error - Different Credentials',"An account already exists with the same email address but different sign-in credentials.",)
            }
            else {
              // setAlertMsgFromContext(e.msg)
              openSimpleAlert('Error',e.msg,)
              navigate('UPLOAD');
            }
        }
      
    }

    const signinWithGoogle = async() => { // only mobile, not web
        try {
          const result = await Google.logInAsync({
            iosStandaloneAppClientId: '2332342585-39p03a41i4ejlja4chhpumk5h86rqjo4.apps.googleusercontent.com', // standalone client id ios
            iosClientId: '2332342585-phht0lba9r89vn3dmrs92shr6ivgpj4o.apps.googleusercontent.com', // expo client id ios
            androidStandaloneAppClientId: '2332342585-i4d29s8r2vmvddh5cd51ega9a4m77l21.apps.googleusercontent.com', // standalone client id android
            androidClientId: '2332342585-uhiea4dcpdgov4m6okkkofj5ibc10coa.apps.googleusercontent.com', // expo client id android
            // webClientId: '2332342585-2grdpamdje7njj8lcvncf6uokp0acsqg.apps.googleusercontent.com',
            scopes: ['profile', 'email']
          });
          if (result.type === 'success') {
            onSignInGoogle(result);
            return result.accessToken;
          } else {
            return { cancelled: true };
          }
        } catch (e) {
          return { error: true };
        }
    }


    const onSignInFacebook = async (token) => {
      try {
        var unsubscribe = firebaseAuth.onAuthStateChanged( async(firebaseUser) => {
          unsubscribe()
          var credential = firebaseFacebookAuthProvider.credential(token);
          console.log('Firebase cred: ', credential)
          firebaseAuth.signInWithCredential(credential).then( facebookProfileData => {

            console.log('facebookProfileData:', facebookProfileData)
            signinSocial(facebookProfileData.user)
            setAlertMsgFromContext('NowLoggedIn')  
            navigate('UPLOAD');
            let new_user = {
              Email: facebookProfileData.user.email || null,
              Name: facebookProfileData.user.displayName || null,
              Provider: 'Facebook',
              Date: Date.now()
            }
            tryWriteNewUserToKibana(new_user)
            }
          ).catch( e => {
            console.log(e)
            if (e.code === 'auth/account-exists-with-different-credential'){
              openSimpleAlert('Error',"An account already exists with the same email address but different sign-in credentials.",)
            }
            else {
              openSimpleAlert('Error',e.msg,)
            }
    
          })  // Sign in with Facebook credential
        })
      } catch (e) {
      }

    }
    const onSignInGoogle = (googleUser) => { 
      try {
        // We need to register an Observer on Firebase Auth to make sure auth is initialized.
        var unsubscribe = firebaseAuth.onAuthStateChanged(async (firebaseUser) => {
          unsubscribe();
          // Check if we are already signed-in Firebase with this user.
          if (!isUserEqual(googleUser, firebaseUser)) {
            // Build Firebase credential with the Google ID token.
            var credential = firebaseGoogleAuthProvider.credential(
                googleUser.idToken, googleUser.accessToken);
      
            // Sign in with credential from the Google user.
            firebaseAuth.signInWithCredential(credential).then( (googleProfileData) => { 

                let first_name = googleUser?.user?.givenName 
                let last_name = googleUser?.user?.familyName
                let new_user = {
                  Email: googleUser?.user?.email || null,
                  Name: first_name + ' ' + last_name,
                  Provider: 'Google',
                  Firebase_UID: 'N/A',
                  Area: 'None',
                  Date: Date.now()
                }

                //console.log('Writing new user to kibana', new_user);
                tryWriteNewUserToKibana(new_user)
                setAlertMsgFromContext('NowLoggedIn')
                navigation.navigate('UPLOAD')
            } ).catch((error) => {
              // Handle Errors here.
              //console.log('Error in firebaseAuth.signInWithCredential: ', error)
            });
          } else {
            //console.log('User already signed-in Firebase.');
          }
        });
      } catch (e) {
        alert('Error in onSignInGoogle:', e)
        //console.log('Error in onSignInGoogle: ', e)
      }
    
    }
      
      const isUserEqual = (googleUser, firebaseUser) => {
        if (firebaseUser) {
          var providerData = firebaseUser.providerData;
          for (var i = 0; i < providerData.length; i++) {
            if (providerData[i].providerId === firebaseGoogleAuthProvider.PROVIDER_ID &&
                providerData[i].uid === googleUser.getBasicProfile().getId()) {
              // We don't need to reauth the Firebase connection.
              //console.log('Is user equal: True')
              return true;
            }
          }
        }

        //console.log('Is user equal: False')
        return false;
      }
      

    const checkIfPro = async() => {
        await AsyncStorage.getItem('is_pro', (err,result) => {
            if (result==null || result == 'no') {
              setIsPro(false)
            } else {
                setIsPro(true)
            }
          })

      }

      const handleForgotPass = () => {
        firebaseAuth.sendPasswordResetEmail(emailForgot).then(() => {
          setAlertTitle(i18n.t('Alerts.Great'))
          setAlertMsg(i18n.t('Alerts.PassRecoverySent'))
          setAlertConfirmText('OK')
          setAlertShowCancelButton(false)
          setConfirmPressedCallback(() => () => {setShowForgot(false); setShowAlert(false); Keyboard.dismiss() })
          setShowAlert(true) 
        }).catch((error) => {
          switch(error.code){
              case 'auth/invalid-email':
                openSimpleAlert('Error',i18n.t('Alerts.InvalidEmail'),)
                break;
              case 'auth/user-not-found':
                openSimpleAlert('Error',i18n.t('Alerts.UserNotFound'),)
                break;
              default: 
                openSimpleAlert('Error',i18n.t('Alerts.UnknownError'),)
                break;
          }
          //console.log(error.code)
        });
        
      }

    // if (currentUser) return ( // Case 1: User is already logged in and tries to log in again. Currently not possible, used to be in the past.
    //     <Animatable.View animation="fadeIn" duration={1000} style={[styles.mainView, {justifyContent:'flex-start'}]}>
    //                 <TouchableOpacity onPress={signout}> 
    //                     <Text style={{fontFamily:'latobold', fontSize:WINDOW_HEIGHT*0.02, color:'#A9042B', padding:20, marginVertical:5}}>
    //                       You are already logged in. If you want to switch account, log out by clicking here and then sign in again.
    //                     </Text>
    //                 </TouchableOpacity>
    //     </Animatable.View>
    // );
    if (!showForgot) return ( // Case 2: Regular login, Not "Forgot password"
            <Animatable.View animation="fadeIn" duration={1000} style={styles.mainView}>
                <View style={styles.inputsContainer}> 
                  <TextInput  autoCapitalize='none' 
                              autoCorrect={false} 
                              style={[styles.input, {textAlign : lang === 'he' ? 'right' : 'left'} ]}
                              placeholder={i18n.t("UserAuth.EmailPlaceholder")}
                              placeholderTextColor={'#a3a3a3'}
                              placeholderStyle={{fontFamily:'latoregular'}}
                              returnKeyType='done'
                              onChangeText={ (text) => setEmail(text) }
                              />
                  <View>
                    <TextInput  autoCapitalize='none' 
                                autoCorrect={false} 
                                style={[styles.input, {textAlign : lang === 'he' ? 'right' : 'left'} ]}
                                placeholder={i18n.t("UserAuth.PasswordPlaceholder")}
                                placeholderTextColor={'#a3a3a3'}
                                placeholderStyle={{fontFamily:'latoregular'}}
                                returnKeyType='done'
                                onChangeText={ (text) => setPassword(text) }
                                secureTextEntry={passwordHidden ? true : false}
                                ref={ref_pass}/>
                      <MaterialCommunityIcons 
                          style={{position:'absolute', right: 10, bottom: 15}}
                          name={passwordHidden ? "eye" : "eye-off"}
                          size={24}
                          onPress={() => {setPasswordHidden(!passwordHidden); }}
                          color="#A9042B"/>
                  </View>
                </View>
                    <Text style={{color:'#ff4747', alignSelf:'center',fontFamily:'latobold', fontSize:16, textAlign:'center'}}>{errorMsg}</Text>
                    <LinearGradient colors={['#A0002A', '#D31631']} style={{borderRadius: 11}}>
                      <View>
                        <TouchableOpacity style={styles.button}
                                onPress={() => {
                                    if (email && password)
                                        signin({email,password})
                                    else 
                                        openSimpleAlert('Error',i18n.t('Alerts.MustFillEmailPassword'),)
                                    }}>
                            <Text style={styles.buttonText}>{i18n.t("UserAuth.SignInBtn",{locale: lang})}</Text>
                        </TouchableOpacity>
                      </View>
                    </LinearGradient>
                    <TouchableOpacity onPress={()=>{setShowForgot(true); setPassword(''); setEmail('')}}> 
                        <Text style={{fontFamily:'latobold', fontSize:16, color:'#A9042B'}}>{i18n.t("UserAuth.ForgotPassword",{locale: lang})}</Text>
                    </TouchableOpacity>
                        <View style={styles.divider}/>
                        <Text style={{fontFamily:'latoregular', fontSize:16, color:'#000'}}>{i18n.t("UserAuth.ContinueWith")}</Text>
                        <TouchableOpacity style={[styles.socialButton,{backgroundColor:'#4285F4'}]} onPress={isWeb ? googlePromptAsync : signinWithGoogle} disabled={isWeb && !googleRequest}>
                            <Text style={styles.socialButtonText}>{i18n.t("UserAuth.Google")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.socialButton,{backgroundColor:'#4267B2'}]} 
                                          onPress={isWeb? facebookPromptAsync : signinWithFacebook} abled={isWeb && !facebookRequest}>
                            <Text style={styles.socialButtonText}>{i18n.t("UserAuth.Facebook")}</Text>
                        </TouchableOpacity>
                        { Platform.OS === 'ios' &&<TouchableOpacity style={[styles.socialButton,{backgroundColor:'#fff'}]} onPress={loginWithApple}>
                            <Text style={[styles.socialButtonText, {color:'#000'}]}>{i18n.t("UserAuth.Apple")}</Text>
                        </TouchableOpacity>}
                    {/* <View style={styles.divider}/>
                        { !isPro &&
                                <TouchableOpacity onPress={()=>navigate('LoginPro')}>
                                    <Text style={{fontFamily:'latoregular', fontSize:12, color:'#A9042B',alignSelf:'center', textAlign:'center'}}>
                                        {i18n.t("UserAuth.AreYouProLogin",{locale:lang})}
                                    </Text>
                                </TouchableOpacity>
                        } */}

            <AwesomeAlert
                    show={showAlert}
                    title={alertTitle}
                    message={alertMsg}
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showConfirmButton={true}
                    showCancelButton={alertShowCancelButton}
                    cancelText={alertCancelText}
                    confirmText={alertConfirmText}
                    confirmButtonColor="#A9042B"
                    cancelButtonColor="#A9042B"
                    onDismiss={() => setShowAlert(false)}
                    onCancelPressed={cancelPressedCallback}
                    onConfirmPressed={confirmPressedCallback}
                    actionContainerStyle={{flexDirection: 'row-reverse'}}/>
            </Animatable.View>

        );
        else if (showForgot) return ( // Case 3: "Forgot password"
            <Animatable.View animation="fadeIn" duration={1000} style={[styles.mainView,{justifyContent:'space-between'}]}>
              <View>
                <Text style={{fontFamily:'latobold', fontSize:14, color:'#A9042B', marginVertical:20, padding:10,textAlign:'center'}}>{i18n.t("ForgotPasswordForm.MainMessage",{locale: lang})}</Text>
                <TextInput  autoCapitalize='none'
                            autoCorrect={false} 
                            style={styles.input}
                            placeholder={i18n.t("UserAuth.EmailPlaceholder",{locale: lang})}
                            placeholderTextColor={'#a3a3a3'}
                            placeholderStyle={{fontFamily:'latoregular'}}
                            returnKeyType='done'
                            onChangeText={ (text) => setEmailForgot(text) }
                            ref={ref_forgot_pass}
                            secureTextEntry={false}
                />
                <LinearGradient colors={['#A0002A', '#D31631']} style={{borderRadius:11, marginTop:'7.5%'}}>
                  <View>
                    <TouchableOpacity style={styles.button}
                                      onPress={() => {
                                          if (emailForgot)
                                              handleForgotPass(emailForgot)
                                          else 
                                              openSimpleAlert('Error',i18n.t('Alerts.MustFillEmail'),)
                                          }}>
                      <Text style={styles.buttonText}>{i18n.t("ForgotPasswordForm.ContinueBtn",{locale: lang})}</Text>
                    </TouchableOpacity>
                  </View>
                </LinearGradient>
              </View>
              <View style={{flexDirection:'row'}}>
                  <TouchableOpacity onPress={()=>setShowForgot(false)}> 
                      <Text style={{fontFamily:'latobold', fontSize:16, color:'#A9042B',alignSelf:'center', marginBottom:'7.5%'}}>{i18n.t("ForgotPasswordForm.BackToSIgnIn",{locale: lang})}</Text>
                  </TouchableOpacity>
              </View>
              <AwesomeAlert
                    show={showAlert}
                    title={alertTitle}
                    message={alertMsg}
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showConfirmButton={true}
                    showCancelButton={alertShowCancelButton}
                    cancelText={alertCancelText}
                    confirmText={alertConfirmText}
                    confirmButtonColor="#A9042B"
                    cancelButtonColor="#A9042B"
                    onDismiss={() => setShowAlert(false)}
                    onCancelPressed={cancelPressedCallback}
                    onConfirmPressed={confirmPressedCallback}
                    actionContainerStyle={{flexDirection: 'row-reverse'}}/>
            </Animatable.View>
        );
    }


    

const styles = StyleSheet.create({
    mainView:{
        flexDirection:'column',
        justifyContent:'space-evenly',
        alignItems: 'center',
        flex: 1,
        marginTop: isComputerWeb ? '2%' : '5%',
        marginBottom: WINDOW_HEIGHT*0.1

    },
    title: {
        fontSize:34,
    },

    text:{
        fontSize: 15,
        color: '#687089',
        marginVertical: 10,
        textAlign:'center'
    },
    inputsContainer: {
        width: WINDOW_WIDTH * 0.872,
    },

    forgotPassword: {
        fontSize: 10,
        alignSelf:'flex-end',
        marginTop: 5,
        color:'#687089'
    
    },

    button: {
        // borderRadius: 11,
        width: WINDOW_WIDTH * 0.7,
        height: WINDOW_HEIGHT * 0.07,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems:'center',
        alignSelf:'center'
    },
    socialButton: {
        borderRadius: 11,
        width: WINDOW_WIDTH * 0.7,
        height: WINDOW_HEIGHT * 0.055,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems:'center'
    },

    socialButtonText: {
        fontFamily:'latoregular',
        fontSize:16,
        color: '#fff',
        alignSelf:'center',

    },
    buttonText: {
        fontFamily:'latobold',
        fontSize:18,
        color: '#fff',
        alignSelf:'center',
    },


input: {
    marginVertical:10,
    width: WINDOW_WIDTH*0.80, 
    borderBottomColor:'#A9042B',
    borderBottomWidth:2,
    color:'#000',
    fontFamily:'latoregular',
    padding:3,
    alignSelf:'center',
    fontSize:16
},

agreeBtn: {
    backgroundColor:'#919191',
    borderColor:'#919191',
    borderRadius: 30,
    width: WINDOW_WIDTH * 0.656,
    height: 45,
    marginVertical:10,
    alignSelf:'center',
    justifyContent:'center'
  },
  divider:{ 
    borderBottomColor: '#707070', 
    borderBottomWidth: 1, 
    width: WINDOW_WIDTH*0.85}

});

export default Signin;