import React, {useContext, useState} from 'react';
import { Text,View, StyleSheet, Dimensions, Image, Platform} from 'react-native';
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import {Context as TransactionContext} from '../context/TransactionContext'
import * as Device from 'expo-device';
import { LinearGradient } from 'expo-linear-gradient';

i18n.translations = {
    en: english,
    he: hebrew
}
const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 

// const WINDOW_WIDTH= Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;
const NewInstructionsPopup = () => {
    const { state: {lang}} = useContext(TransactionContext)


  
    return (
        <LinearGradient colors={['#A0002A', '#D31631']} style={{flex:1, borderRadius:15}}>
        <View style={styles.mainContainer}>
            <View style={{marginTop:15}}> 
                <Text style={[styles.title,{fontFamily:'latoregular'}]}>Follow the instructions</Text>
                <Text style={[styles.title, {fontFamily:'latobold'}]}>For best results</Text>
            </View>
                <View style={styles.row}> 
                    <View styles={styles.square}> 
                        <Text style={styles.number}>1.</Text>
                        <Image source={require('../../assets/instructions1.png')} style={styles.image}></Image>
                        <Text style={styles.text}>Cleanse your face</Text>
                    </View>
                    <View styles={styles.square}> 
                        <Text style={styles.number}>2.</Text>
                        <Image source={require('../../assets/instructions2.png')} style={{height:90, width:65, alignSelf:'center' }}></Image>
                        <Text style={styles.text}>Put up your hair</Text>
                    </View>
                </View>
                <View style={styles.row}> 
                    <View styles={styles.square}> 
                        <Text style={styles.number}>3.</Text>
                        <Image source={require('../../assets/instructions3.png')} style={styles.image}></Image>
                        <Text style={styles.text}>Brightly lit area</Text>
                    </View>
                    <View styles={styles.square}> 
                        <Text style={styles.number}>4.</Text>
                        <Image source={require('../../assets/instructions4.png')} style={styles.image}></Image>
                        <Text style={styles.text}>Take a selfie</Text>
                    </View>
                </View>
            </View>
        </LinearGradient>
        )
}


const styles = StyleSheet.create({
    mainContainer:{
        flex:1,
        justifyContent:'space-around',
    },
    title: {
        fontSize:20,
        color:'#fff',
        textAlign:'center',
        paddingHorizontal:5,
    },

    backBtn: {
        marginHorizontal:5,
        borderRadius: 30,
        padding:8,
        marginTop: 10,
        justifyContent:'center',
        width: WINDOW_WIDTH*0.2,
        backgroundColor:'#9599B3',
        alignSelf:'center',
        marginTop:15
    },
    image: {
        height: 90,
        width: 90,
        alignSelf:'center',
    },
    number: {
        position: 'absolute',
        top:-15,
        left:-15,
        color:'#fff',
        fontFamily:'latoregular',
        fontSize:18
    },
    row:{
        justifyContent:'space-evenly',
        alignItems:'center',
        flexDirection:'row',
    },

    text: {
        color:'#fff',
        fontFamily:'latoregular',
        fontSize:14,
        textAlign:'center',
        marginTop:10
    },

    square: {
        flex:1,
        justifyContent:'space-between',
        alignItems:'center',
        alignSelf:'center',
    }
    
})

export default NewInstructionsPopup