import React, { useEffect,useState } from 'react';
import {ProgressCircle} from "react-native-svg-charts";
import {View, Dimensions, Text, PixelRatio, Platform} from 'react-native'
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import * as Device from 'expo-device';

const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 

// const WINDOW_WIDTH = Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;
const PR = PixelRatio.get()

i18n.translations = {
    en: english,
    he: hebrew
}


const AnalysisResultGraph = ({analysis}) => {    

    useEffect(() => {
   }, [])

    const diseaseNameTranslator = (name) => {
        switch(name) {
            case 'acne_mild': 
                return i18n.t("Diseases.Acne1")
            case 'acne_medium':
                return i18n.t("Diseases.Acne2")
            case 'acne_severe':
                return i18n.t("Diseases.Acne3")
            case 'herpes':
                return i18n.t("Diseases.Herpes")
            case 'eczema': 
                return i18n.t("Diseases.EczemaForGraph")
            case 'clean':
                return i18n.t("Diseases.CleanFace")
            case 'no_face':
                return 'No Face'
            default: // No disease here 
                return null
        }
    }



    return (
        <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-evenly',  marginTop:10, alignSelf:'center'}}>
            <ProgressCircle
                style={{ height: WINDOW_HEIGHT * 0.16, width: WINDOW_WIDTH * 0.4, alignSelf:'center',}}
                progress={ analysis[0].probability * 0.01 }
                startAngle={180}
                endAngle={0}
                progressColor={'#A9042B'}
                strokeWidth={10}
                backgroundColor={'#1D202A'}
            >
            </ProgressCircle>
            <View style={{position:'absolute', top:'30%', right: -50}}>
                <Text style={{color: '#A9042B', fontFamily:'latobold', textAlign:'center', fontSize:15}}>
                    {analysis[0].probability}%  
                </Text>
                <Text style={{color: '#A9042B', fontFamily:'latobold', fontSize:13, textAlign:'center',marginTop:3}}>
                    { 
                    analysis[0].name !== 'herpes' ? diseaseNameTranslator(analysis[0].name).split(' ')[0] + "\n" + (diseaseNameTranslator(analysis[0].name).split(' ')[1] || ' ') + "\n" + (diseaseNameTranslator(analysis[0].name).split(' ')[2] || ' ')
                    : diseaseNameTranslator(analysis[1].name).split(' ')[0] + "\n" + (diseaseNameTranslator(analysis[1].name).split(' ')[1] || ' ') + "\n" + (diseaseNameTranslator(analysis[1].name).split(' ')[2] || ' ')
                    } 
                    {/* {i18n.t('Diseases.CleanFace')} */}
                </Text>
            </View>
            {analysis.length==2 && 
            <View style={{position:'absolute', top:'30%', left: -50}}>
                <Text style={{color: '#1D202A', fontFamily:'latobold', textAlign:'center', fontSize:  15}}>
                {analysis[1].probability}%
                </Text>
                <Text style={{color: '#1D202A', fontFamily:'latobold', textAlign:'center', fontSize: 13}}>
                { 
                    analysis[0].name !== 'herpes' ? diseaseNameTranslator(analysis[1].name).split(' ')[0] + "\n" + (diseaseNameTranslator(analysis[1].name).split(' ')[1] || ' ') + "\n" + (diseaseNameTranslator(analysis[1].name).split(' ')[2] || ' ')
                    : diseaseNameTranslator(analysis[0].name).split(' ')[0] + "\n" + (diseaseNameTranslator(analysis[0].name).split(' ')[1] || ' ') + "\n" + (diseaseNameTranslator(analysis[0].name).split(' ')[2] || ' ')
                } 
                    {/* {i18n.t('Diseases.Herpes')} */}
                </Text>
            </View>
            }


        </View>
    );
}

export default AnalysisResultGraph;