import createDataContext from './createDataContext';
import {firebaseFirestore} from '../firebase/config'
import i18n from 'i18n-js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Alert } from 'react-native';
import { navigate } from '../navigationRef';


const baseURL = 'https://api.srcosmeticsusa.com:8000'



const TransactionReducer = (state, action) => {
  switch (action.type) {
    case 'get_result':
      return { ...state, analysis: action.payload.analysis, last_transaction_id: action.payload.kid  , errorMsg: '' };
    case 'set_products_link':
      console.log('In action:', action)
      return { ...state, productsLink: action.payload};
    case 'set_products':
      var ids = []
      var products_without_duplicates = action.payload.filter( product => {
          if (ids.includes(product.shopify_product_id)) { // found duplicate
            //console.log('Filtering: ', product.name, product.order)
            return false;
          }
          else {
            ids.push(product.shopify_product_id)
            return true
          }
        }
      )
      return { ...state, products: products_without_duplicates , errorMsg: '' };
    case 'set_scar_products':
      var ids = []
      var _products = [...state.products, ...action.payload]
      var products_without_duplicates = _products.filter( product => {
          if (ids.includes(product.shopify_product_id)) { // found duplicate
            //console.log('Filtering: ', product.name, product.order)
            return false;
          }
          else {
            ids.push(product.shopify_product_id)
            return true
          }
        }
      )
      return {...state, products:[...state.products, ...action.payload] , errorMsg: ''}
    case 'set_pig_products':
      var ids = []
      var _products = [...state.products, ...action.payload]
      var products_without_duplicates = _products.filter( product => {
          if (ids.includes(product.shopify_product_id)) { // found duplicate
            //console.log('Filtering: ', product.name, product.order)
            return false;
          }
          else {
            ids.push(product.shopify_product_id)
            return true
          }
        }
      )
      return {...state, products:[...state.products, ...action.payload] , errorMsg: ''}  
    case 'set_protocol':
      return { ...state, protocol: action.payload , errorMsg: '' };
    case 'reset_protocol':
      return { ...state, protocol: null , errorMsg: '' };  
    case 'set_scars_protocol': 
      return {...state, scarsProtocol: action.payload, errorMsg: ''};
    case 'set_pig_protocol': 
      return {...state, pigProtocol: action.payload, errorMsg: ''};
    case 'clear_analysis':
      return { ...state , analysis: null, products: [], protocol: '' };
    case "change_lang": 
      return {...state, lang: action.payload}
    case "sending_mail": 
        return {...state, sendingMail: !state.sendingMail}
    case "set_server_version": 
        return {...state, serverVersion: action.payload}
    case "set_show_products_in_protocols_page":
        return {...state, showProductsFromProtocolScreen: action.payload}
    case "set_browser": 
        return {...state, browser: action.payload}
    case "set_alert_msg":
      return {...state, alertMsgFromContext: action.payload}
    case "dismiss_alert":
      return {...state, alertMsgFromContext: ''}
    default:
      return state;
  }
};

const storeTransaction = dispatch => (transaction) => { // STORING Transaction in Firestore DB and then writing to log file
  var transactionObject = {
    data: transaction,
    index: 'all_transactions',
  }
  firebaseFirestore.collection("transactions").doc().set(transaction)
  .then(() => {
    // //console.log("Document successfully written!")
    // //console.log(transaction)
    fetch(baseURL + '/write_transaction', {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(transactionObject)
    }).then( res => res.json()). then( res => {
      //console.log(res)
      // dispatch({type: 'update_last_transaction_id', payload: res.Id})
    })
      .catch((error) => {
       //console.log('from store transaction',error);
     });
 

    // analyzerApi.post('/write_transaction', transaction).then((res) => {
    //   //console.log(res.data)
    // }).catch(err => //console.log(err))

  })
  .catch((err) => console.error("Error writing document: ", err))
}

const updateTransactionCleanFace = dispatch => (data) => {
  const method = "POST"
  const headers = {Accept: 'application/json','Content-Type': 'application/json'}
  const body = JSON.stringify(data)
  const url = baseURL+'/update_transaction_clean'
  const requestInfo = {method, headers, body}
  fetch(url,requestInfo).then( response => response.json() ).then(response => {
    //console.log('',response)
} )

}
const updateTransactionOverride = dispatch => (data) => {
  const method = "POST"
  const headers = {Accept: 'application/json','Content-Type': 'application/json'}
  const body = JSON.stringify(data)
  const url = baseURL+'/update_transaction_override'
  const requestInfo = {method, headers, body}
  fetch(url,requestInfo).then( response => response.json() ).then(response => {
    //console.log(response)
} )
}


const getDiagnosis = dispatch => (urls) => { //Sending array with 1 or 2 images url to the machine
  const method = "POST"
  const headers = {Accept: 'application/json','Content-Type': 'application/json'}
  const body = JSON.stringify(urls)
  const url = baseURL+'/analyze'
  const requestInfo = {method, headers, body}
  // Promise.race([
    fetch(url,requestInfo)
    // ,
  //   new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 30 * 1000))
  // ])
  .then(function (response) {
    return response.json();
  }).then(function (response) {
    dispatch({type: 'get_result', payload: response})
  }).catch(e => {
    switch (e.message) {
      case 'timeout':
        dispatch( {type: 'set_alert_msg', payload: 'DiagnosisTimeout'} )
        break;
      default: 
        dispatch( {type: 'set_alert_msg', payload: 'DiagnosisError'} )
      break;          
    
    }
  });
}

const getScarProducts = dispatch => async(pro) => {
  var db = pro ? 
  firebaseFirestore.collection('products').doc('cosmetician').collection('Scars')
  : firebaseFirestore.collection('products').doc('user').collection('Scars')
  const snapshot = await db.get()
  var scar_products = snapshot.docs.map(doc => doc.data())
  dispatch({type: 'set_scar_products', payload: scar_products})

}

const getPigProducts = dispatch => async(pro) => {
  var db = pro ? 
  firebaseFirestore.collection('products').doc('cosmetician').collection('Pigmentation')
  : firebaseFirestore.collection('products').doc('user').collection('Pigmentation')
  const snapshot = await db.get()
  var pig_products = snapshot.docs.map(doc => doc.data())
  dispatch({type: 'set_pig_products', payload: pig_products})

}

const getProductsForDisease = dispatch => async(disease, pro) => {
  //console.log(disease,pro)
  var db = pro ? 
  firebaseFirestore.collection('products').doc('cosmetician').collection(disease)
  : firebaseFirestore.collection('products').doc('user').collection(disease)
  const snapshot = await db.get()
  var products = snapshot.docs.map(doc => doc.data())
  dispatch({type: 'set_products', payload: products})
  // if (showProductsForScars==true) {
  //   //console.log('inside scars if')
  //   getScarProducts(pro)
  // }
}

const getProtocol = dispatch => async (disease,pro,language, withScars ,withPig) => {
  var type = pro? 'pro' : 'user' 
  var db = firebaseFirestore.collection('protocols')
  var query = db.where("type", "==", type).where("disease", "==", disease).where("lang","==",language)
  //console.log('6666, ' ,type,disease,language)
  var querySnapshot = await query.get();
  //console.log('777, ' ,querySnapshot)
  var _protocol = querySnapshot.docs[0].data().protocol
  dispatch({type: 'set_protocol', payload: _protocol})
  if (withScars) {
    var db = firebaseFirestore.collection('protocols')
    var query = db.where("type", "==", type).where("disease", "==", "Scars").where("lang","==",language)
    var querySnapshot = await query.get();
    var _scars_protocol = querySnapshot.docs[0].data().protocol
    dispatch({type: 'set_scars_protocol', payload: _scars_protocol})
  }
  if (withPig) {
    var db = firebaseFirestore.collection('protocols')
    var query = db.where("type", "==", type).where("disease", "==", "Pigmentation").where("lang","==",language)
    var querySnapshot = await query.get();
    var _pig_protocol = querySnapshot.docs[0].data().protocol
    dispatch({type: 'set_pig_protocol', payload: _pig_protocol})

  }

}


const getProductsLink = dispatch => async(disease,pro, withScars ,withPig) => {
  console.log('link for: ',disease)
  if (disease == 'Eczema / Skin Redness') {
    disease = 'Eczema'
  }
  var query = pro ? 
  firebaseFirestore.collection('products').doc('cosmetician').collection(disease)
  : firebaseFirestore.collection('products').doc('user').collection(disease)
  const snapshot = await query.get()
  const {link} = snapshot.docs[0].data()
  console.log('The link:',link)
  dispatch({type: 'set_products_link', payload: link})
}

const resetProtocol = dispatch => () => {
  dispatch({type: 'reset_protocol'})
}



const clearAnalysis = dispatch => () => {
  dispatch({type:'clear_analysis'})
}


const changeLang = dispatch => (lang) => {
  i18n.locale  = lang
  dispatch({type: 'change_lang', payload:lang})
  AsyncStorage.setItem('Face_It_Lang', lang)
}

const setServerVersion = dispatch => (value) => {
  dispatch({type: 'set_server_version', payload:value})
}

const sendContactUsMail = dispatch => (msgObject) => {
  dispatch({type:'sending_mail'}) // Start loading circle
  const method = "POST"
  const headers = {Accept: 'application/json','Content-Type': 'application/json'}
  const body = JSON.stringify(msgObject)
  const url = baseURL+'/send_mail'
  const requestInfo = {method, headers, body}
  fetch(url,requestInfo).then( response => response.json() ).then(response => {
    console.log(response)
    dispatch({type:'sending_mail'}) // Stop loading circle
    if (response.Status === 'Ok'){
      dispatch( {type: 'set_alert_msg', payload: 'MessageSent'} )
    } else {
      dispatch( {type: 'set_alert_msg', payload: 'MessageNotSent'} )
    }
  }
    ).catch( e => {
      console.log('CONTACT US MAIL FAILED: ',e);
      dispatch({type:'sending_mail'}) // stop loading circle if error
      dispatch( {type: 'set_alert_msg', payload: 'MessageNotSent'} )
    })
}
const getFaceBoxForWeb = dispatch => (base64obj) => {
  return new Promise(  (resolve, reject)=> {
    const method = "POST"
      const headers = {}
      const body = JSON.stringify(base64obj)
      const url = baseURL+'/get_face_box_web'
      const requestInfo = {method, headers, body}
      fetch(url,requestInfo).then( response => response.json() ).then(response => {
        resolve(response)
      }
      ).catch( e => {
        reject(e.msg); 

    })
    
  })

}
const getFaceBoxForMobile = dispatch => (data) => {
  return new Promise(  (resolve, reject)=> {
    const method = "POST"
      const headers = {}
      const body = data
      const url = baseURL+'/get_face_box_mobile'
      const requestInfo = {method, headers, body}
      fetch(url,requestInfo).then( response => response.json() ).then(response => {
        // dispatch({type: 'get_box_ios', payload:response})
        resolve(response)
      }
      ).catch( e => {reject(e.msg); })
    
  })

}

const setShowProductsFromProtocolScreen = dispatch => (showProducts) => {
        dispatch({type: 'set_show_products_in_protocols_page', payload:showProducts})
      } 



const setBroswer = dispatch => (browser) => {
    dispatch({type: 'set_browser', payload: browser})
}

const setAlertMsgFromContext = dispatch => (msgName) => {
  dispatch( {type: 'set_alert_msg', payload: msgName} )
}


const dismissAlertFromContext = dispatch => () => {
  dispatch({type: 'dismiss_alert'})
}

export const { Context, Provider } = createDataContext(
  TransactionReducer,
  { getDiagnosis, getProductsForDisease, storeTransaction, 
    getProtocol, getScarProducts,clearAnalysis, changeLang,
    getPigProducts, sendContactUsMail, setServerVersion, resetProtocol, getProductsLink,
    getFaceBoxForMobile,getFaceBoxForWeb, updateTransactionOverride, updateTransactionCleanFace,
    setShowProductsFromProtocolScreen, setBroswer, dismissAlertFromContext, setAlertMsgFromContext},
  { analysis: null, errorMsg: '', images_download_urls: [], products: [], serverVersion: 'production',
  protocol: '', lang: '', scarsProtocol: '',pigProtocol:'', sendingMail:false, box: null, productsLink: null,
  last_transaction_id: null, showProductsFromProtocolScreen: false, browser: null, alertMsgFromAuthContext:'' } 
);
