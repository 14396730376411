import React, { useContext,useState,useRef,useEffect } from 'react';
import { View, StyleSheet, Text, Dimensions,TextInput,Keyboard,TouchableOpacity,Platform, ImageBackground } from 'react-native';
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import {Context as TransactionContext} from '../context/TransactionContext'
import { Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ActivityIndicator } from 'react-native-paper';
import { NavigationEvents } from 'react-navigation';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as Device from 'expo-device';


const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
// const WINDOW_WIDTH = Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;

i18n.translations = {
    en: english,
    he: hebrew
}
const ContactUsScreen = ({navigation}) => {
    const ref_name_input = useRef()
    const ref_email_input = useRef()
    const ref_message_input = useRef()
    const [current,setCurrent] = useState(null)
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [message,setMessage] = useState(null)
    const { state: {lang,sendingMail,alertMsgFromContext}, sendContactUsMail, dismissAlertFromContext } = useContext(TransactionContext)
    const [country,setCountry] = useState(null)

    // Alert stuff
    const [showAlert,setShowAlert] = useState(false)
    const [alertTitle,setAlertTitle] = useState(null)
    const [alertMsg,setAlertMsg] = useState(null)
    const [alertConfirmText,setAlertConfirmText] = useState(null)
    const [alertCancelText,setAlertCancelText] = useState(null)
    const [cancelPressedCallback,setCancelPressedCallback] = useState(null)
    const [confirmPressedCallback,setConfirmPressedCallback] = useState(() => () => {})
    const [alertShowCancelButton,setAlertShowCancelButton] = useState(false)
    
    useEffect(() => {
        setCurrent(null)
        getCountry()
        setEmail(null)
        setName(null)
        setMessage(null)
    }, [])

    useEffect(() => {
        if (alertMsgFromContext === 'MessageSent') {
            setAlertTitle('')
            setAlertMsg(i18n.t('Alerts.MessageSent'))
            setAlertConfirmText('OK')
            setAlertShowCancelButton(false)
            setConfirmPressedCallback(() => () => {setShowAlert(false); dismissAlertFromContext()})
            setShowAlert(true)
            dismissAlertFromContext()    
        } else if (alertMsgFromContext === 'MessageNotSent')  {
            setAlertTitle('')
            setAlertMsg(i18n.t("Alerts.MessageNotSent"))
            setAlertConfirmText('OK')
            setAlertShowCancelButton(false)
            setConfirmPressedCallback(() => () => {setShowAlert(false); dismissAlertFromContext()})
            setShowAlert(true) 
        }
      },[alertMsgFromContext])


    const openSimpleAlert = (title, msg) => { // alert for simple alerts - title + message + confirm button that dismisses alert
        setAlertTitle(title)
        setAlertMsg(msg)
        setAlertConfirmText('OK')
        setAlertShowCancelButton(false)
        setConfirmPressedCallback(() => () => {setShowAlert(false)})
        setShowAlert(true)
    }      


    const getCountry = () => {
        AsyncStorage.getItem('country_code',(err,result) => {
            if (result) setCountry(result)
        })
    }
    const handleSubmitPress = () => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(email) === false) {
          openSimpleAlert('Error',i18n.t('Alerts.InvalidEmail'))
        }
        
        else if (name && email && message){
            sendContactUsMail({name: name, email: email, message: message, country: country})

        } else {
            openSimpleAlert('Error',i18n.t('Alerts.MustFillAllFields'))
        }
    }

    return (
        <ImageBackground
        source={require('../../assets/app_background.png')}
        resizeMode='cover'
        style={styles.background}>
        <View style={styles.mainContainer}>
            <Text style={styles.title}>{i18n.t("ContactUs.Wedlove")}</Text>
            <View style={{flex:1,justifyContent:'space-around'}}>
            <NavigationEvents 
                    onDidBlur={()=>{
                        setEmail(null)
                        setName(null)
                        setMessage(null)
                        }
                    }
            />
                <TextInput  autoCapitalize='none' 
                            selectionColor='#A9042B'
                            autoCorrect={false} 
                            style={[styles.input,{borderBottomColor: current==='name' ? '#A9042B' : '#000', textAlign: lang==='he' ? 'right' : 'left'}]}
                            placeholder={i18n.t("ContactUs.NamePlaceholder")}
                            placeholderTextColor={'#8f8f8f'}
                            placeholderStyle={{fontFamily:'latoregular'}}
                            autoFocus={true}
                            returnKeyType="next"
                            onSubmitEditing={() => ref_email_input.current.focus()}
                            onChangeText={ (text) => setName(text) }
                            onFocus={()=>setCurrent('name')}   
                            value={name}
                            />

                <TextInput  autoCapitalize='none' 
                            selectionColor='#A9042B'
                            autoCorrect={false} 
                            style={[styles.input,{borderBottomColor: current==='email' ? '#A9042B' : '#7d7d7d', textAlign: lang==='he' ? 'right' : 'left'}]}
                            placeholder={i18n.t("ContactUs.EmailPlaceholder")}
                            placeholderTextColor={'#7d7d7d'}
                            placeholderStyle={{fontFamily:'latoregular'}}
                            onChangeText={ (text) => setEmail(text) }
                            returnKeyType="next"
                            onSubmitEditing={() => ref_message_input.current.focus()}
                            onFocus={()=>setCurrent('email')}   
                            ref={ref_email_input}
                            value={email}
                            />
                <TextInput  autoCapitalize='none' 
                            selectionColor='#A9042B'
                            multiline
                            autoCorrect={false} 
                            style={[styles.inputBig,{borderColor: current==='message' ? '#A9042B' : '#7d7d7d', textAlign: lang==='he' ? 'right' : 'left', justifyContent:'flex-start'}]}
                            placeholder={i18n.t("ContactUs.YourMsg")}
                            placeholderTextColor={'#7d7d7d'}
                            placeholderStyle={{fontFamily:'latoregular'}}
                            onChangeText={ (text) => setMessage(text) }
                            maxLength={400}
                            blurOnSubmit={true}
                            onSubmitEditing={()=>{Keyboard.dismiss()}}
                            returnKeyType="go"
                            onFocus={()=>setCurrent('message')}   
                            ref={ref_message_input}
                            value={message}
                            textAlignVertical={'top'}

                            />
                    {sendingMail ? 
                        <ActivityIndicator color='#fff'/>
                    :<TouchableOpacity 
                            style={[styles.button, {backgroundColor: '#A9042B',}]}
                            onPress={handleSubmitPress}>
                        <Text style={styles.buttonText}>{i18n.t("ContactUs.Submit")}</Text>
                    </TouchableOpacity>}

            </View>

            <AwesomeAlert
                    show={showAlert}
                    title={alertTitle}
                    message={alertMsg}
                    closeOnTouchOutside={true}
                    closeOnHardwareBackPress={false}
                    showConfirmButton={true}
                    showCancelButton={alertShowCancelButton}
                    cancelText={alertCancelText}
                    confirmText={alertConfirmText}
                    confirmButtonColor="#A9042B"
                    cancelButtonColor="#A9042B"
                    onDismiss={() => setShowAlert(false)}
                    onCancelPressed={cancelPressedCallback}
                    onConfirmPressed={confirmPressedCallback}
                    actionContainerStyle={{flexDirection: 'row-reverse'}}/>

        </View>
        </ImageBackground>
    );
}




const styles = StyleSheet.create({
    input: {
        marginVertical:10,
        width: isComputerWeb ? WINDOW_WIDTH*1.3 : WINDOW_WIDTH*0.8, 
        borderBottomWidth:2,
        color:'#000',
        fontFamily:'latoregular',
        padding:3,
        fontSize: WINDOW_HEIGHT*0.02,
        alignSelf:'center',
    },

    inputBig:{
        marginVertical:10,
        width: isComputerWeb ? WINDOW_WIDTH*1.3 : WINDOW_WIDTH*0.8, 
        height:WINDOW_HEIGHT*0.3,
        borderColor:'#d2d2d2',
        borderWidth:2,
        color:'#000',
        fontFamily:'latoregular',
        padding:4,
        fontSize: WINDOW_HEIGHT*0.02,
        alignSelf:'center',
    },
    button: {
        alignSelf:'center',
        borderRadius: 30,
        width: isComputerWeb ? WINDOW_WIDTH*1.3 : WINDOW_WIDTH*0.8, 
        height: WINDOW_HEIGHT * 0.079,
        marginVertical:5,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems:'center'

    },

    buttonText: {
        fontFamily:'latobold',
        fontSize:WINDOW_HEIGHT*0.025,
        color: '#fff',
        alignSelf:'center',
    },

    mainContainer: {
        backgroundColor: 'transparent',
        flex:1, 
        padding:25,
        marginTop: WINDOW_HEIGHT*0.1 + 5,
        marginHorizontal:15,
        alignSelf:'center',
        marginBottom: WINDOW_HEIGHT*0.1,
    },

    title: {
        fontFamily:'latoregular', 
        fontSize:WINDOW_HEIGHT*0.028, 
        textAlign:'center', 
        color:'#A9042B'
    },
    background: {
        width: '100%',
        height: '100%',
        flex: 1,
        position: 'absolute',
        top: 0,
        left: 0
    },
    

});

export default ContactUsScreen;