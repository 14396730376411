import React, {useState, useEffect, useContext} from 'react';
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, Dimensions, Platform, ImageBackground} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { v4 as uuidv4 } from 'uuid';
import 'react-native-get-random-values'
import TermsTextEnglish from '../components/TermsTextEnglish';
import PrivacyPolicyTextEnglish from '../components/PrivacyPolicyTextEnglish';
import {Context as TransactionContext} from '../context/TransactionContext'
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import { isIE, isFirefox } from 'react-device-detect';
import Tutorial from '../components/Tutorial';
import * as Animatable from 'react-native-animatable';
import { LinearGradient } from "expo-linear-gradient";
import * as Device from 'expo-device';

const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const isWeb = Platform.OS === 'web' 
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
const WINDOW_HEIGHT = Dimensions.get('window').height;

i18n.translations = {
  en: english,
  he: hebrew,
};

// Set the locale once at the beginning of your app.
// i18n.locale = Localization.locale;
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = true;

i18n.translations = {
    en: english,
    he: hebrew
}

const WelcomeScreen = ({navigation}) => {
  const[seenTutorial,setSeenTutorial] = useState(null)
  const[_lang] = useState('he')
  const[agreeToTerms,setAgreeToTerms] = useState(null)
  const {state: {lang} , changeLang, setBroswer} = useContext(TransactionContext)
  const isFromMenu = navigation.state?.params?.fromMenu

  useEffect(() => {
    checkUID(); // ID For each device, not using it really
    checkAgreeTokenAndTutorialToken(); // check agree token and tutorial
    changeLang('en') // set default language english for now
    isIE ? setBroswer('IE') : isFirefox ? setBroswer('FF') : setBroswer('other')
    //console.log('isFromMenu = ', isFromMenu)
  }, []);

  const storeTutorialToken = () => {
    try {
      AsyncStorage.setItem('seenTutorial', 'yes')
      navigation.navigate('ResolveAuth')
    } catch (err) {
      //console.log(err);
    }
  }

  const checkTutorialToken = async () => {
    try {
        await AsyncStorage.getItem('seenTutorial', (err,result) => {
          if (result==='yes'){ 
            setSeenTutorial(true)
            if (!isFromMenu) navigation.navigate('ResolveAuth')
          } else {
            setSeenTutorial(false)
          }
      })
    } catch(e) {
      //console.log(e)
    }
  }

  const setUID = async() => {
    const uid = uuidv4()
    await AsyncStorage.setItem('SR_Face_It_UID', uid)
  }
  
  const checkUID = async() => {
    try {
      await AsyncStorage.getItem('SR_Face_It_UID', (err,result) => {
        if (result===null) {
          setUID()
        } else {
        }
      })
    } catch (e) {
      //console.log(e)
    }
  }




  
  const checkAgreeTokenAndTutorialToken = async () => {
    // first, check if user agreed to terms
    AsyncStorage.getItem('agree_to_terms_main',(err,result) => {
      if (result===null || result ==='no'){
        setAgreeToTerms(false)
      } else if (result==='yes') {
        setAgreeToTerms(true)
        checkTutorialToken() // check if user saw tutorial
      }})
  }

  const handleAgreeClick = async() => {
    await AsyncStorage.setItem('agree_to_terms_main', 'yes')
    setAgreeToTerms(true)
    checkTutorialToken()
  }

    if(agreeToTerms === false)  // show terms if user haven't agree yet
      return ( 
        <ImageBackground
        source={require('../../assets/app_background.png')}
        resizeMode='cover'
        style={styles.background}>

        <View style={styles.mainContainer}>
          <ScrollView showsVerticalScrollIndicator={true} indicatorStyle='white'>
              <>
                <Text style={[styles.title, {textAlign: 'center'}]}>Face-It Terms & conditions</Text>
                <TermsTextEnglish/>
                <Text style={[styles.title, {textAlign:  'center' }]}>Face-It Privacy Policy</Text>
                <PrivacyPolicyTextEnglish/>
              </> 
          </ScrollView>
          <TouchableOpacity style={styles.agreeBtn} onPress={handleAgreeClick}>
            <Text style={{fontFamily:'latobold', color:'#fff',textAlign:'center', padding:10}}>{i18n.t("WelcomeScreen.agreeToTerms")}</Text>
          </TouchableOpacity> 
        </View>
        </ImageBackground>
        );
    else if (isFromMenu || seenTutorial === false) return ( // show tutorial if user agreed to terms but haven't seen tutorial yet
      <Animatable.View animation="fadeIn" duration={2000} style={{ flex:1}}>
        <LinearGradient colors={['#A0002A', '#D31631']} style={{ flex:1,justifyContent:'space-between', alignItems:'center' }}>
          <Tutorial onConfirm={storeTutorialToken} isFromMenu={isFromMenu} />
        </LinearGradient>
      </Animatable.View>

    );
    else return null // show nothing if user agreed to terms and saw tutorial - goes to main page eventually

  
}
const styles = StyleSheet.create({
  mainContainer: {
    width:WINDOW_WIDTH,
    backgroundColor: 'transparent',
    flex:1, 
    paddingHorizontal:10,
    alignSelf:'center'
},
  title: {
    fontFamily:'latobold', 
    color:'#000', 
    paddingHorizontal: 20, 
    paddingVertical: 35, 
    textAlign:'center',
    fontSize:22
  },

  agreeBtn: {
    backgroundColor:'#A0002A',
    borderColor:'#A0002A',
    borderRadius: 30,
    width: WINDOW_WIDTH * 0.8,
    marginVertical:10,
    alignSelf:'center',
    justifyContent:'center'
  },

    background: {
      width: '100%',
      height: '100%',
      flex: 1,
      position: 'absolute',
      top: 0,
      left: 0

    }
    })

 export default WelcomeScreen;
