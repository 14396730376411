import React, { useContext,useState } from 'react';
import { View, StyleSheet, Text, ScrollView,Dimensions,Image,Linking,Platform,ImageBackground } from 'react-native';
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import {Context as TransactionContext} from '../context/TransactionContext'
import * as Device from 'expo-device';


const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
// const WINDOW_WIDTH = Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;


i18n.translations = {
    en: english,
    he: hebrew
}

const AboutUsScreen = ({navigation}) => {
    const {state: {lang}} = useContext(TransactionContext)
    

    return (
        <ImageBackground
        source={require('../../assets/app_background.png')}
        resizeMode='cover'
        style={styles.background}>

        <View style={{
            width:isComputerWeb ? WINDOW_WIDTH*1.3 : WINDOW_WIDTH,
            backgroundColor: 'transparent', 
            flex:1, 
            marginTop: WINDOW_HEIGHT*0.1 + 5,
            marginHorizontal:15,
            paddingHorizontal:25,
            alignSelf:'center'
    }}>
            <ScrollView indicatorStyle='white'>
            <View style={{flex:1, justifyContent:'space-around', alignItems:'center', marginTop: 10}}>
                <Text style={{color:'#000', fontFamily:'latobold', fontSize: WINDOW_HEIGHT*0.032, marginBottom:10, textAlign:'center'}}>{i18n.t('AboutUs.AboutUsBigTitle')}</Text>
                <View style={{flexDirection:'row', flex:1, width:'100%', justifyContent:'space-around'}}>
                    <Image style={{height:90*1.5,width:90}} source={require('../../assets/shuli.jpg')}/>
                </View>
            </View>
                
                <Text style={{color:'#000', fontFamily:'latobold',fontSize:WINDOW_HEIGHT*0.022,marginVertical:10, textAlign: lang === 'en' ? 'left' : 'right'}}>
                 {i18n.t('AboutUs.AboutUsTitle')}
                    <Text style={{color:'#6792f0', textDecorationLine:'underline'}} onPress={() => Linking.openURL('https://www.axioma-in.com/') }>
                    {' '}{i18n.t('AboutUs.Axioma')}
                    </Text>
                </Text>
                <Text style={[styles.paragraph,{textAlign:lang === 'en' ? 'left' : 'right'}]}>
                    {i18n.t('AboutUs.AboutUsP1')}                
                </Text>
            </ScrollView>
        </View>
        </ImageBackground>
    );
}



const styles = StyleSheet.create({
paragraph: {
    color:'#000', 
    fontFamily:'latoregular', 
    marginVertical:7,
    fontSize:WINDOW_HEIGHT*0.02
},
background: {
    width: '100%',
    height: '100%',
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0
},

});

export default AboutUsScreen;