import React, {useEffect,useState,useContext} from 'react'
import {View, StyleSheet,Text, Dimensions, ImageBackground, Platform} from 'react-native'
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import {Context as AuthContext} from '../context/AuthContext'
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import { TouchableOpacity } from 'react-native-gesture-handler';
import {Feather,MaterialCommunityIcons,MaterialIcons,FontAwesome5} from 'react-native-vector-icons'
import {firebaseAuth} from '../firebase/config'
import i18n from 'i18n-js';
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import * as Device from 'expo-device';
import AwesomeAlert from 'react-native-awesome-alerts';


const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
// const WINDOW_WIDTH = Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;

i18n.translations = {
    en: english,
    he: hebrew,
  };
  

const CustomDrawerNavigator = props => {
    const {signout } = useContext(AuthContext)
    const [user,setUser] = useState(null)
    const [isPro,setIsPro] = useState(null)
    const [proName, setProName] = useState(null)
    const [activeLabel, setActiveLabel] = useState('main')
    const [proMail, setProMail] = useState(null)

    // Alert stuff
    const [showAlert,setShowAlert] = useState(false)
    const [alertTitle,setAlertTitle] = useState(null)
    const [alertMsg,setAlertMsg] = useState(null)
    const [alertConfirmText,setAlertConfirmText] = useState(null)
    const [alertCancelText,setAlertCancelText] = useState(null)
    const [cancelPressedCallback,setCancelPressedCallback] = useState(null)
    const [confirmPressedCallback,setConfirmPressedCallback] = useState(() => () => {})
    const [alertShowCancelButton,setAlertShowCancelButton] = useState(false)

    useEffect(() => {
        if (props.navigation.state.isDrawerOpen) {
            checkIfUserOrPro();
        }
    }, [props.navigation.state.isDrawerOpen])

    const checkIfUserOrPro = () => { // First, check if it a user registered in Firebase Auth and his email verified
        firebaseAuth.onAuthStateChanged(function(user) {
            if ((user && user.emailVerified) || user && user.providerData[0].providerId === 'facebook.com') { // credential sign in + google + apple || facebook - facebook has no email verified field
                setUser(user)
                checkIfPro() // Second step is to check if user is pro. (still firebase user)
            } else {
                setUser(null)
            }
        
    })}

    const checkIfPro = () => {
        AsyncStorage.multiGet(['is_pro','pro_name','SR_IL_Pro_Mail'], (err,result) => {
            if (result[0][1]==null || result[0][1] == 'no') {
                setIsPro(false)
                AsyncStorage.removeItem('pro_name')
            } else {
                setIsPro(true)
                setProName(result[1][1])
                setProMail(result[2][1])
            }
        })      
    }


    const handleLogout = () => {
        signout(true);
        isPro && setIsPro(false) // set pro = false in case pro was = true
        props.navigation.toggleDrawer();
    }

    return (
        <View style={[styles.mainContainer]}>
            <View>
                <ImageBackground source={require('../../assets/drawer_white.png')} style={{width:'100%', height: WINDOW_HEIGHT*0.25}} imageStyle={styles.imageContainer}>
                    { isPro ? 
                    <>
                        <Text style={styles.helloText}> 
                            {i18n.t('SideMenu.Hello')} {proName ?  proName : user?.displayName ? user?.displayName : ''} !
                        </Text>  
                        <Text style={[styles.helloText, {fontSize:18}]}>
                            {i18n.t('SideMenu.ProUser')}
                        </Text>
                    </> 
                    : user ? 
                    <Text style={styles.helloText}> 
                    {i18n.t('SideMenu.Hello')} {user ? user.displayName : ''}!
                    </Text>  
                    : 
                    <Text style={styles.helloText}> 
                        {i18n.t('SideMenu.HelloGuest')}
                    </Text>  

                    }
                    <Text style={[styles.versionText, i18n.locale==='en' ? {right:0} : {left:0}]}>{i18n.t('SideMenu.Version')}: {Constants.manifest.version}</Text>
                </ImageBackground>
                {/* <DrawerItems 
                    {...props}
                /> */}
                <View style={styles.menu}>
                    <TouchableOpacity style={{padding:18,
                                              flexDirection: i18n.locale === 'en' ? 'row' : 'row-reverse',
                                              alignItems:'center'}} 
                    onPress={()=>{props.navigation.navigate('UPLOAD'); setActiveLabel('main')}}>
                        <MaterialCommunityIcons
                            style={[i18n.locale === 'en' ? {paddingRight:15} : {paddingLeft:15} ,activeLabel === 'main' && {color: '#A9042B'}]}
                            name="face-recognition"
                            size={18}
                            color='#000'
                        />
                        <Text style={activeLabel === 'main' ? styles.activeMenuLabel : styles.menuLabel}>{i18n.t('SideMenu.FaceItAnalyzer')}</Text>
                    </TouchableOpacity>  

                    {
                      //  LOGIN / SIGNUP ---> PRO + REGULAR USER

                      !user && !isPro &&          
                        <TouchableOpacity style={{padding:18,
                                                  flexDirection: i18n.locale === 'en' ? 'row' : 'row-reverse',
                                                  alignItems:'center'}} 
                        onPress={()=>{props.navigation.navigate('AuthMain'); setActiveLabel('main')}}>
                            <Feather
                            style={[i18n.locale === 'en' ? {paddingRight:15} : {paddingLeft:15}]}
                                name="user"
                                size={18}
                                color='#000'
                            />
                            <Text style={styles.menuLabel}>{i18n.t("SideMenu.LoginSignupUsers")}</Text>
                        </TouchableOpacity>

                    }

                    {
                        //PROTOCOLS FOR PROS/USERS
                        <TouchableOpacity     style={{padding:18,
                                                  flexDirection: i18n.locale === 'en' ? 'row' : 'row-reverse',
                                                  alignItems:'center'}}
                                              onPress={() => {
                                              props.navigation.navigate('Protocols',{"forceUserProtocols":false}); 
                                              setActiveLabel('protocols'); 

                                              }}>
                            <MaterialCommunityIcons
                                style={[i18n.locale === 'en' ? {paddingRight:15} : {paddingLeft:15} ,activeLabel === 'protocols' && {color: '#A9042B'}]}
                                name="content-paste"
                                size={18}
                                color='#000'
                            /> 
                            <Text style={activeLabel === 'protocols' ? styles.activeMenuLabel : styles.menuLabel}>{i18n.t("SideMenu.ProtocolsForPros")}</Text>
                        </TouchableOpacity>

                    }

                    {
                        <TouchableOpacity style={{padding:18,
                                                  flexDirection: i18n.locale === 'en' ? 'row' : 'row-reverse',
                                                  alignItems:'center'}}
                        onPress={() => {props.navigation.navigate('ProtocolsEndUsersScreen'); setActiveLabel('protocols_extra_end_users');}}>
                            <MaterialCommunityIcons
                                style={[i18n.locale === 'en' ? {paddingRight:15} : {paddingLeft:15} ,activeLabel === 'protocols_extra_end_users' && {color: '#A9042B'}]}
                                name="content-paste"
                                size={18}
                                color='#000'
                            /> 
                            <Text style={activeLabel === 'protocols_extra_end_users' ? styles.activeMenuLabel : styles.menuLabel}>
                                {  i18n.t("SideMenu.ProtocolsForEndUsers")}
                            </Text>
                        </TouchableOpacity>
                    }
                    <TouchableOpacity style={{padding:18,
                                                  flexDirection: i18n.locale === 'en' ? 'row' : 'row-reverse',
                                                  alignItems:'center'}} 
                    onPress={()=>{props.navigation.navigate('ContactUs'); setActiveLabel('contact')}}>
                        <MaterialIcons
                            style={[i18n.locale === 'en' ? {paddingRight:15} : {paddingLeft:15}, activeLabel === 'contact' && {color: '#A9042B'}]}
                            name="contact-mail"
                            size={18}
                            color='#000'
                        />
                        <Text style={activeLabel === 'contact' ? styles.activeMenuLabel : styles.menuLabel}>{i18n.t("SideMenu.ContactUs")}</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{padding:18,
                                                  flexDirection: i18n.locale === 'en' ? 'row' : 'row-reverse',
                                                  alignItems:'center'}} 
                    onPress={()=>{props.navigation.navigate('AboutUs'); setActiveLabel('about')}}>
                        <MaterialIcons
                            style={[i18n.locale === 'en' ? {paddingRight:15} : {paddingLeft:15}, activeLabel === 'about' && {color: '#A9042B'}]}
                            name="info"
                            size={18}
                            color='#000'
                        />
                        <Text style={activeLabel === 'about' ? styles.activeMenuLabel : styles.menuLabel}>About Us</Text>
                    </TouchableOpacity>


                    <TouchableOpacity style={{padding:18,
                                                  flexDirection: i18n.locale === 'en' ? 'row' : 'row-reverse',
                                                  alignItems:'center'}}  
                    onPress={()=>{props.navigation.navigate('Terms'); setActiveLabel('terms')}}>
                        <FontAwesome5
                            style={[i18n.locale === 'en' ? {paddingRight:15} : {paddingLeft:15}, activeLabel === 'terms' && {color: '#A9042B'}]}
                            name="book"
                            size={18}
                            color='#000'
                        />
                        <Text style={activeLabel === 'terms' ? styles.activeMenuLabel : styles.menuLabel}>{i18n.t("SideMenu.Terms")}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        
            { user &&
                <View style={{flexDirection:'row', justifyContent:'space-around'}}>
            
                    {/* LOGOUT - FOR USER OR PRO */}
                    <TouchableOpacity style={{flexDirection:'row', padding: 20}} onPress={handleLogout}>
                        <Feather name="log-out" color="#000" size={12} style={{alignSelf:'center'}} />
                        <Text style={{color:'#000',fontFamily:'latoregular',fontSize:12, paddingLeft:10}}>{i18n.t("SideMenu.Logout")}</Text>
                    </TouchableOpacity>

                    {/*  CHANGE PRO STATUS - FOR USER OR PRO */}
                    <TouchableOpacity style={{padding: 20}} 
                                      onPress=
                                                { 
                                                    () => 
                                                            {
                                                                setAlertTitle(i18n.t("Alerts.AreYouSure")),
                                                                setAlertMsg(isPro ? i18n.t("Alerts.IAmNotProMsg") : i18n.t("Alerts.IAmProMsg")),
                                                                setAlertCancelText(i18n.t("Alerts.Cancel")),
                                                                setAlertConfirmText(i18n.t("Alerts.OK")),
                                                                setConfirmPressedCallback(() => async() => { // stuff to do when user becomes pro / not pro
                                                                                                        await AsyncStorage.setItem('is_pro',isPro ? 'no' : 'yes')
                                                                                                        props.navigation.toggleDrawer();
                                                                                                        props.navigation.navigate('UPLOAD')
                                                                                                        setShowAlert(false)
                                                                                                    })
                                                                setAlertShowCancelButton(true)
                                                                setShowAlert(true)
                                                            }
                                                    
                                                }>
                        <Text style={{color:'#A9042B',fontFamily:'latoblack',fontSize:12, paddingLeft:10, textDecorationLine:'underline'}}>
                            {isPro ? i18n.t("SideMenu.AreYouNotPro") : i18n.t("SideMenu.AreYouPro")}
                        </Text>
                    </TouchableOpacity>

                </View>
            }
        
            <AwesomeAlert
                show={showAlert}
                title={alertTitle}
                message={alertMsg}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                showCancelButton={alertShowCancelButton}
                cancelText={alertCancelText}
                confirmText={alertConfirmText}
                confirmButtonColor="#A9042B"
                cancelButtonColor="#b7b7b7"
                onDismiss={() => setShowAlert(false)}
                onCancelPressed={() => setShowAlert(false)}
                onConfirmPressed={confirmPressedCallback}
                actionContainerStyle={{flexDirection: 'row-reverse'}}

        />



        </View>
    );
}

const styles = StyleSheet.create({
    mainContainer: {
        marginVertical: 10,
        flexDirection:'column',
        flex:1,
        justifyContent:'space-between'
        
    },

    helloText: {
        color:'#A9042B',
        fontFamily:'latoregular',
        fontSize:24,
        paddingHorizontal:30,
        paddingVertical:20,
        opacity:1
    },

    versionText: {
        color:'#A9042B',
        fontFamily:'latoregular',
        paddingHorizontal:30,
        paddingVertical:20,
        opacity:1,
        fontSize:10, 
        position:'absolute', 
        bottom:0
    },
    imageContainer: {
        opacity:0.3,
    },

    menuLabel:{
        fontFamily: 'latobold',
        color:'#000',
        fontSize:14
    },
    activeMenuLabel:{
        fontFamily: 'latobold',
        color:'#A9042B',
        fontSize:14

    },


})

export default CustomDrawerNavigator