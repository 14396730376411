import createDataContext from './createDataContext';
import {firebaseFirestore} from '../firebase/config'
import analyzerApi from '../api/analyzer';

// const baseURL = 'http://10.0.0.7:8000'
const baseURL = 'https://acne.axioma-in.com:8000'
// const baseURL = 'http://10.0.0.26:8000'


const CartReducer = (state, action) => {
  switch (action.type) {
    case "set_cosmeticians": 
        return {...state, cosmeticians: action.payload}
    case "set_init_quantities_to_zero":
        return {...state, initQuantitiesToZero: action.payload}
    case "add_item":
         return { ...state, cart: [...state.cart, action.payload]};
    case "remove_item":
        return { ...state, cart: state.cart.filter(item => item !== action.payload)}
    case "empty_cart":
        return { ...state, cart: []}
    case "set_dollar_rate": 
        return {...state, dollarRate: action.payload}
    case "update_quantity":
        const newCart = state.cart.map((itemInCart => {
          // //console.log('Item in cart', itemInCart, "Item to increase:" , action.payload.item)
            if (itemInCart.shopify_product_id === action.payload.item.shopify_product_id) {
              itemInCart.quantity = action.payload.q
            }
            return itemInCart;
          }))
          return {...state, cart: newCart}
    default:
      return state;
  }
};

const updateQuantityForItemInCart = dispatch => (item,q) => {
  dispatch({type:'update_quantity', payload:{item,q}})
}

const addToCart = dispatch => (item) => {
    item.quantity = 1
    dispatch({type: 'add_item', payload: item}) 
}
 
const removeFromCart = dispatch => (item) => { 
    dispatch({type: 'remove_item', payload: item}) 
}

const emptyCart = dispatch => () => { 
    dispatch({type: 'empty_cart'}) 
}

const getCurrenciesRate = dispatch => () => {
  fetch('https://api.exchangerate.host/latest?base=USD', { 
    method: "GET",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  }).then(response => response.json())
  .then(currencies => {
    dispatch({type:'set_dollar_rate', payload: currencies.rates.ILS})
  })

}

const getCosmeticiansFromAreaIsrael = dispatch => async(area) => {
  // //console.log('TEST 1', area)
  var db =  firebaseFirestore.collection('cosmeticians')

  const snapshot = await db.get()
  var all_cosmeticians = snapshot.docs.map(doc => doc.data())
  var cosmeticians_from_area
  if (area === 'south' || area ==='north' || area ==='central') {
    cosmeticians_from_area = all_cosmeticians.filter(cos => cos.area === area)
  } else {
    cosmeticians_from_area = all_cosmeticians
  }
  // //console.log('TEST 2', cosmeticians_from_area)

  dispatch({type: 'set_cosmeticians', payload: cosmeticians_from_area})


}

const writeCheckoutToKibana = dispatch => async(checkout) => {
  //console.log('WRITING NEW CHECKOUT')
  const method = "POST"
  const headers = {Accept: 'application/json','Content-Type': 'application/json'}
  const body = JSON.stringify(checkout)
  const url = baseURL+'/write_new_checkout'
  const requestInfo = {method, headers, body}
  fetch(url,requestInfo).then( response => response.json() ).then(response => {
    //console.log(response)
} )


}

const setInitQuantitiesToZero = dispatch => (toInit) => {
  dispatch({type: 'set_init_quantities_to_zero', payload: toInit})
}



export const { Context, Provider } = createDataContext(
CartReducer,
  { addToCart, removeFromCart, emptyCart, getCurrenciesRate,updateQuantityForItemInCart, 
    getCosmeticiansFromAreaIsrael, writeCheckoutToKibana, setInitQuantitiesToZero},
  { cart: [], dollarRate: null, cosmeticians: [], initQuantitiesToZero:false, price:0 } 
);
