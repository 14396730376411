import React, {useEffect, useState, useContext, useRef } from 'react';
import { View, StyleSheet, Image, Dimensions,Text, Alert
    ,Platform,PixelRatio,ImageBackground, ActivityIndicator, Animated, TouchableOpacity } from 'react-native';
import { Button, Overlay } from 'react-native-elements';
import * as ImagePicker from 'expo-image-picker';
import {  PinchGestureHandler, State } from 'react-native-gesture-handler';
import {Context as TransactionContext} from '../../context/TransactionContext'
import {Context as AuthContext} from '../../context/AuthContext'
import {Context as HistoryContext} from '../../context/HistoryContext'
import {AntDesign,MaterialIcons,EvilIcons,MaterialCommunityIcons} from 'react-native-vector-icons'
import AsyncStorage from '@react-native-async-storage/async-storage';
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import { NavigationEvents } from 'react-navigation';
import { Camera } from 'expo-camera';
import * as ImageManipulator from 'expo-image-manipulator';
import {uriToBlob,uploadToFirebase } from '../../firebase/firebaseStorageUploader'
import * as MediaLibrary from 'expo-media-library';
import i18n from 'i18n-js';
import english from '../../lang/en.json'
import hebrew from '../../lang/he.json'
import * as Network from 'expo-network';
import NewInstructionsPopup from '../../components/NewInstructionsPopup';
import { LinearGradient } from 'expo-linear-gradient';
import * as Device from 'expo-device';
import * as Animatable from 'react-native-animatable';
import {firebaseAuth} from '../../firebase/config'
import AwesomeAlert from 'react-native-awesome-alerts';

const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const isWeb = Platform.OS === 'web'
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
const WINDOW_HEIGHT = Dimensions.get('window').height
const screenRatio = WINDOW_HEIGHT/WINDOW_WIDTH
const PR = PixelRatio.get();
const ZOOM_F = 0.01
const ELLIPSE_SIZE = WINDOW_HEIGHT * 0.19
const ELLIPSE_SIZE_COMPUTER_WEB = 125


i18n.translations = {
    en: english,
    he: hebrew,
  };

const UploadScreen = ({navigation}) => {
    const { state: {lang, alertMsgFromContext},clearAnalysis, getFaceBoxForMobile,getFaceBoxForWeb, dismissAlertFromContext } = useContext(TransactionContext)
    const { state: {}, deleteHistory, recognizeFace} = useContext(HistoryContext)
    const { state: {currentUser,alertMsgFromAuthContext, userCred} , dismissAlertFromAuthContext, signout} = useContext(AuthContext)
    const[imagesURI, setImagesURI] = useState([])
    const[imagesURL, setImagesURL] = useState([])
    const [cameraType, setCameraType] = useState('back');
    const [showCamera,setShowCamera] = useState(false)
    const cameraRef = useRef()
    const [previewImage, setPreviewImage] = useState(null)
    const [showCameraPreview, setShowCameraPreview] = useState(false)
    const [nowUploading, setNowUploading] = useState(false)
    const [isPro, setIsPro] = useState(false)
    const [SR_IL_ProMail, set_SR_IL_ProMail] = useState(null)
    const [SR_CA_US_ProMail, set_SR_CA_US_ProMail] = useState(null)
    const [user, setUser] = useState(null)
    const [index,setIndex] = useState(null)
    const [zoom,setZoom] = useState(0)
    const [prevPinch,setPrevPinch] = useState(0)
    const [flashMode, setFlashMode] = useState('off')
    const [showInstructionsPopup,setShowInstructionsPopup] = useState(false)
    const [frontPicExists, setFrontPicExists] = useState(false)
    const [sidePicExists, setSidePicExists] = useState(false)
    const [isRatioSet, setIsRatioSet] = useState(false)
    const [cameraRatio,setCameraRatio] = useState(null)
    const [cameraPadding,setCameraPadding] = useState(null)
    const [cameraAnimatableState, setCameraAnimatableState] = useState('fadeIn')

    // Alert stuff
    const [showAlert,setShowAlert] = useState(false)
    const [alertTitle,setAlertTitle] = useState(null)
    const [alertMsg,setAlertMsg] = useState(null)
    const [alertConfirmText,setAlertConfirmText] = useState(null)
    const [alertCancelText,setAlertCancelText] = useState(null)
    const [cancelPressedCallback,setCancelPressedCallback] = useState(null)
    const [confirmPressedCallback,setConfirmPressedCallback] = useState(() => () => {})
    const [alertShowCancelButton,setAlertShowCancelButton] = useState(false)
    

    useEffect(() => {
        checkIfUserOrPro() 
        
    }, [])

    useEffect(() => {
        checkIfUserOrPro()        
    }, [currentUser])


    useEffect(() => {
        if(imagesURL.length == 0) setNowUploading(false)
          }, [imagesURL]);



        useEffect(() => {
        if (alertMsgFromContext === 'NowLoggedIn' || alertMsgFromAuthContext === 'NowLoggedIn') {
            setAlertTitle(i18n.t('Alerts.Great'))
            setAlertMsg(i18n.t('Alerts.YouAreNowLoggedIn'))
            setAlertConfirmText('OK')
            setAlertShowCancelButton(false)
            setConfirmPressedCallback(() => () => {setShowAlert(false); dismissAlertFromContext();})
            setShowAlert(true)

        } else if (alertMsgFromContext === 'DifferentCredential')  {
            setAlertTitle('')
            setAlertMsg(i18n.t("Alerts.DifferentCredential"))
            setAlertConfirmText('OK')
            setAlertShowCancelButton(false)
            setConfirmPressedCallback(() => () => {setShowAlert(false); dismissAlertFromContext();       })
            setShowAlert(true) 
        } else if (alertMsgFromAuthContext === 'AlmostDoneEmailVerification') {
            setAlertTitle(i18n.t('Alerts.AlmostDone'))
            setAlertMsg(i18n.t('Alerts.SendVerificationMail1') + userCred.email + i18n.t('Alerts.SendVerificationMail2'))
            setAlertConfirmText('OK')
            setAlertShowCancelButton(false)
            setConfirmPressedCallback(() => () => {setShowAlert(false); dismissAlertFromAuthContext(); })
            setShowAlert(true) 
        } else if (alertMsgFromAuthContext === 'NotVerifiedYet') {
            setAlertTitle(i18n.t('Alerts.DidntVerifyYetTitle'))
            setAlertMsg(i18n.t('Alerts.DidntVerifyYetMsg'))
            setAlertConfirmText(i18n.t('Alerts.Resend'))
            setAlertShowCancelButton(false)
            setConfirmPressedCallback(() => () => { 
                                                    userCred.sendEmailVerification().then(() => {
                                                        setShowAlert(false); 
                                                        dismissAlertFromAuthContext();
                                                        signout(false)
                                                        firebaseAuth.signOut()
                                                    }).catch((error) => {
                                                        console.log(error)
                                                        setShowAlert(false); 
                                                        dismissAlertFromAuthContext();
                                                        signout(false)
                                                        firebaseAuth.signOut()
                                                    });      
                                 })
            setShowAlert(true) 
            setAlertCancelText('Cancel')
            setAlertShowCancelButton(true)
            setCancelPressedCallback(() => () => { setShowAlert(false); dismissAlertFromAuthContext();})

        } else if (alertMsgFromAuthContext === 'ByeBye') {
            setAlertTitle(i18n.t('Alerts.ByeByeDisconnectedTitle'))
            setAlertMsg(i18n.t('Alerts.ByeByeDisconnectedMsg'))
            setAlertConfirmText('OK')
            setAlertShowCancelButton(false)
            setConfirmPressedCallback(() => () => {setShowAlert(false); dismissAlertFromAuthContext(); })
            setShowAlert(true) 
        } else if (alertMsgFromAuthContext === 'ByeBye') {
            setAlertTitle('')
            setAlertMsg(i18n.t('Alerts.AlreadyLoggedOut'))
            setAlertConfirmText('OK')
            setAlertShowCancelButton(false)
            setConfirmPressedCallback(() => () => {setShowAlert(false); dismissAlertFromAuthContext(); })
            setShowAlert(true) 

        }
        },[alertMsgFromContext,alertMsgFromAuthContext])
    

    const checkIfUserOrPro = () => { // First, check if it a user registered in Firebase Auth and his eail verified
        // //console.log('checking if user or pro in main screen')
        firebaseAuth.onAuthStateChanged(function(user) {
            if (user && user.emailVerified) {
                //console.log('0', user)
                setUser(user)
                checkIfPro() // Second step is to check if user is pro. (still firebase user)
            } else {
                // //console.log('User not logged in or not exists or not verified')
                setIsPro(false)
                setUser(null)
            }
    })}
    

    const checkIfPro = async() => {
        await AsyncStorage.getItem('is_pro', (err,result) => {
            // //console.log(result)
            if (result==null || result == 'no') {
              setIsPro(false)
            } else {
              setIsPro(true)
            }
          })
    }
    const storeImage = (uri) => {


            return new Promise((resolve,reject) => {
              return uriToBlob(uri)
              .then((blob) => {
                var type = SR_IL_ProMail ? 'sr_il_pros' : SR_CA_US_ProMail ? 'sr_canada_usa_pros' : currentUser ? 'user' : 'guest'
                var email
                if (SR_IL_ProMail) { // israeli pro
                    email = SR_IL_ProMail
                }
                else if (SR_CA_US_ProMail){// CANADA AMERICA PRO
                    email = SR_CA_US_ProMail
                } else if (currentUser && currentUser.providerData[0].providerId === 'facebook.com' && currentUser.email === null){ // facebook user without mail
                    email = 'Facebook_User_' + currentUser.displayName
                } else if(currentUser){ // mail&pass / google logins OR facebook logins from accounts with mail
                    email = currentUser.email
                } else { //guest
                    email = ''
                }

                // var email = SR_IL_ProMail ? SR_IL_ProMail : currentUser ? currentUser.email : ''
                return uploadToFirebase(blob,type,email) // upload
              })
              .then((snapshot) => { //get download url from each photo
                snapshot.ref.getDownloadURL().then((downloadURL) => {  
                      resolve(downloadURL)
                });
              })
              .catch((e => reject(e)))
        })
    };


    const CameraPreview = ({photo}) => {
            useEffect(() => {
                //console.log('Photo:', photo)
            }, [])
    
            return (
                <View style={{flex:1, alignItems:'center', justifyContent:'center'}}>
                    <ImageBackground
                    source={{uri: photo && photo.uri}} // validate that photo exists
                    style={{
                        width: WINDOW_WIDTH, 
                        height: isComputerWeb ? WINDOW_HEIGHT * 0.75 : WINDOW_HEIGHT, 
                        marginTop: isComputerWeb ? WINDOW_HEIGHT * 0.02 : isWeb ? WINDOW_HEIGHT*0.1 : Platform.OS === 'ios' ? WINDOW_HEIGHT*0.2 : WINDOW_HEIGHT*0.2,
                        alignSelf:'center'
                    }}
                    >
                        <LinearGradient colors={['#A0002A', '#D31631']} 
                                        style={{ borderRadius:6, 
                                                 width: '40%',
                                                 position:'absolute',
                                                 bottom: isComputerWeb ? 20 : isWeb ? '22%' : '20%', 
                                                 left: '5%', 
                                               }} >
                            <TouchableOpacity  onPress={() => { 
                                                                setNowUploading(true)
                                                                cropFace(photo,index)
                                                                setCameraAnimatableState('fadeOut')
                                                                setShowCamera(false); 
                                                                setPreviewImage(null); 
                                                                setShowCameraPreview(false);
                                                }}>
                                <Text style={{padding:15, color:'#fff', fontFamily:'latoblack', textAlign:'center'}}>
                                    SAVE
                                </Text>
                            </TouchableOpacity>
                        </LinearGradient>
                        <LinearGradient colors={['#A0002A', '#D31631']} 
                                        style={{ borderRadius:6, 
                                                 width: '40%',
                                                 position:'absolute',
                                                 bottom: isComputerWeb ? 20 : isWeb ? '22%' : '20%', 
                                                 right: '5%', 
                                               }} >
                            <TouchableOpacity onPress={() => { setCameraAnimatableState('fadeIn');setShowCameraPreview(false); setShowCamera(true) }}>
                                <Text style={{padding:15, color:'#fff', fontFamily:'latoblack', textAlign:'center'}}>
                                    RETAKE
                                </Text>
                            </TouchableOpacity>
                        </LinearGradient>
                    </ImageBackground>
                </View>
            )
          }
    
    const openSimpleAlert = (title, msg) => { // alert for simple alerts - title + message + confirm button that dismisses alert
        setAlertTitle(title)
        setAlertMsg(msg)
        setAlertConfirmText('OK')
        setAlertShowCancelButton(false)
        setConfirmPressedCallback(() => () => {setShowAlert(false)})
        setShowAlert(true)
    }      

    const openPhotoAlert = (x) => { // alert for pressing main 2 front/profile boxes 
        setAlertTitle(i18n.t('Alerts.CameraOrGalleryTitle'))
        setAlertMsg(i18n.t('Alerts.CameraOrGalleryText'))
        setAlertCancelText(i18n.t('Alerts.Camera'))
        setAlertConfirmText(i18n.t('Alerts.Gallery'))
        setConfirmPressedCallback(() => async() => { // CONFIRM = GALLERY
                                                if (imagesURI.length >= 2) {
                                                    openSimpleAlert('Error', i18n.t("Alerts.TwoPhotos"))
                                                }
                                                var isConnected = await checkNetworkConnection();
                                                if (isConnected === false) {
                                                    openSimpleAlert('Error',i18n.t("Alerts.NoInternetConnection"))
                                                }
                                                let permissionResult = await ImagePicker.requestCameraRollPermissionsAsync();
                                            
                                                if (permissionResult.granted === false || permissionResult.accessPrivileges === 'limited' || permissionResult.canAskAgain === false) {
                                                    if (Platform.OS === 'ios'){
                                                        openSimpleAlert(i18n.t('Alerts.CameraRollPermissionsTitle'),i18n.t('Alerts.CameraRollPermissionsTextIphone'))
                                                    } else if (Platform.OS === 'android') {
                                                        openSimpleAlert(i18n.t('Alerts.CameraRollPermissionsTitle'),i18n.t('Alerts.CameraRollPermissionsTextAndroid'))     
                                                    } else { // WEB???

                                                    }
                                                    return;
                                                }
                                                let result = await ImagePicker.launchImageLibraryAsync({
                                                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                                                    allowsEditing: false,
                                                    quality: 1,
                                                    exif: true
                                                });

                                                if(!result.cancelled) {
                                                    setNowUploading(true)
                                                    //console.log('1 iundex=', x)
                                                    cropFace(result,x)
                                                    setShowAlert(false)
                                                }
                                            })

        setCancelPressedCallback(() => async() => {     
                                if (imagesURI.length >= 2) {
                                    openSimpleAlert('Error',i18n.t('Alerts.TwoPhotos'))          
                                }    
                                var isConnected = await checkNetworkConnection();

                                if (isConnected === false) {
                                    openSimpleAlert('Error',i18n.t('Alerts.NoInternetConnection'))          
                                    return;
                                }

                                let permissionResult = await ImagePicker.requestCameraPermissionsAsync();
                        
                                if (permissionResult.granted === false || permissionResult.canAskAgain === false) {
                                    if (Platform.OS === 'ios') { 
                                        openSimpleAlert(i18n.t('Alerts.CameraPermissionsTitle'),i18n.t('Alerts.CameraPermissionsTextIphone'))          
                                    }
                                
                                    else if (Platform.OS==='android'){
                                        openSimpleAlert(i18n.t('Alerts.CameraRollPermissionsTitle'),i18n.t('Alerts.CameraRollPermissionsTextAndroid'))     

                                    }
                                    return;    
                                }
                        
                                setCameraAnimatableState('fadeIn')
                                setShowCamera(true)
                                setShowAlert(false)
                            })
        setAlertShowCancelButton(true)                    
        setShowAlert(true)

    }



    const removeImage = (x) => {
        setImagesURL(imagesURL.filter((item)=>(item.index !== x)))
        setImagesURI(imagesURI.filter((item)=>(item.index !== x)))
    }

    const addImageToImagesArray = async (data,flip, createAsset = true) => {
        try{
        var w = data.width
        var h = data.height
        var max=1500
        var image
        
        if (w>1500 || h>1500) {
            var ratio = Math.min(max / w, max / h);
            var _w =  w*ratio
            var _h = h*ratio
            image = await ImageManipulator.manipulateAsync(
                data.uri,
                [{ resize: { width: _w, height: _h } }],
                { format: 'jpeg', compress:0.6 }
                );
            } 

            else 
            {
                image=data
            }

        var x
        if (imagesURI.length===1) {
             x = imagesURI[0].index == 0 ? 1 : 0 // case that there is already one photo (side or front), not relevant for USA but stays
             // in USA version, user have to upload front before side.
        } else { // length=0 
             x = 0
        }
        image.index = x
        setImagesURI([...imagesURI, image]);
        setNowUploading(true)
        var filename = "Not Available"
        if(createAsset && Platform.OS !== 'web') {
            //console.log('[Asset creation start] : ', new Date())
            //upload to "Face-It" folder inside phones gallery)
            const albumName = 'Face-It'
            
                const asset = await MediaLibrary.createAssetAsync(data.uri);
                filename = asset.filename || "Not Available"
                if(Platform.OS === 'android') {
                    const albumResult =  await MediaLibrary.createAlbumAsync(albumName, asset)
                }
                
                else if(Platform.OS === 'ios') {
                    const album =  await MediaLibrary.getAlbumAsync('Face-It')
                    if (album===null) MediaLibrary.createAlbumAsync(albumName, asset)
                    else MediaLibrary.addAssetsToAlbumAsync(asset,album.id)
                } 
                //console.log('[Asset creation end] : ', new Date())
        }
        //console.log('[FB Upload start] : ', new Date())
        
        storeImage(image.uri).then( result =>
            {
                setImagesURL([...imagesURL, {index: x, uri: result, name: filename}]) // name = name in local storage (Face-It directory in phones gallery)
                setNowUploading(false)
                //console.log('[FB Upload end] : ', new Date())
                if (x===0) {
                    setFrontPicExists(true)
                } else if (x===1) {
                    setSidePicExists(true)
                }
                // sendToFaceRecognitionMachine(result);
            }
        )

        } catch (e) {
            if (e.code =='E_NO_PERMISSIONS') {
                if (Platform.OS === 'android') {
                    openSimpleAlert(i18n.t('Alerts.CameraRollPermissionsTitle'),i18n.t('Alerts.CameraRollPermissionsTextAndroid'))
                } else  {
                    openSimpleAlert(i18n.t('Alerts.CameraRollPermissionsTitle'),i18n.t('Alerts.CameraRollPermissionsTextIphone'))
                }
                setImagesURL([]);
                setImagesURI([]);
                setNowUploading(false)
                return 
            }
        }
        // findHistory(image)

    }


    const addImageToImagesArrayWithIndex = async (data,x,flip,createAsset = true) => {
        //image now is in data.uri
        try{

        
        var max=1500
        var w = flip? data.height : data.width
        var h = flip? data.width : data.height
        var image
        if (w>1500 || h>1500) {
            var ratio = Math.min(max / w, max / h);
            var _w =  w*ratio
            var _h = h*ratio
            image = await ImageManipulator.manipulateAsync(
                data.uri,
                [{ resize: { width: _w, height: _h } }],
                { format: 'jpeg', compress:0.5 }
                );
            } else {
                image=data
            }
            image.index = x
        setImagesURI([...imagesURI, image]);
        setNowUploading(true)
        var filename = "Not Available"
        //upload to "Face-It" folder inside phones gallery)
        if(createAsset && Platform.OS !== 'web') {
            const albumName = 'Face-It'
            const asset = await MediaLibrary.createAssetAsync(data.uri);
            filename = asset.filename || "Not Available"
            if(Platform.OS === 'android') 
                MediaLibrary.createAlbumAsync(albumName, asset)
            else {
                const album =  await MediaLibrary.getAlbumAsync('Face-It')
                if (album===null) MediaLibrary.createAlbumAsync(albumName, asset)
                else MediaLibrary.addAssetsToAlbumAsync(asset,album.id)
            } 
        }
        
        storeImage(image.uri).then( result =>
            {
                setImagesURL([...imagesURL, {index: x, uri: result, name: filename}])
                // sendToFaceRecognitionMachine(result,filename);
                setNowUploading(false)
                if (x===0) {
                    setFrontPicExists(true)
                } else if (x===1) {
                    setSidePicExists(true)
                }

            }
        )
        } catch (e) {
            if (e.code =='E_NO_PERMISSIONS') {
                if (Platform.OS === 'android') {
                    openSimpleAlert(i18n.t('Alerts.CameraRollPermissionsTitle'),i18n.t('Alerts.CameraRollPermissionsTextAndroid'))
                } else  {
                    openSimpleAlert(i18n.t('Alerts.CameraRollPermissionsTitle'),i18n.t('Alerts.CameraRollPermissionsTextIphone'))
                }
                setImagesURL([])
                setImagesURI([])
                setNowUploading(false)
                return 
            }
        }

    }

    const sendToFaceRecognitionMachine = () => {
        recognizeFace('Pros',user.email,imagesURL)
    }

    const checkNetworkConnection = () => {
        return new Promise(  async (resolve, reject)=> {
            const _network = await Network.getNetworkStateAsync();
            if (_network) {
                // //console.log(_network)
                resolve(_network.isConnected)
            } else {
                reject('Error in Expo.Network')
            }
            
        })
    
    }

    const handleTransactionStart =  () => {
        try {
            if (isPro){
                sendToFaceRecognitionMachine()
            } 
            else if (currentUser) {
                deleteHistory();
            }
            else {
                deleteHistory();
            }
            navigation.navigate('RESULTS', {"images": imagesURL, "uris": imagesURI})
        } catch (e) {
            alert(e)
        }
    }



    const pickImageFromGallery = async () => {
        //console.log('1')
        try {
            if (imagesURI.length >= 2) {
                openSimpleAlert('Error', i18n.t("Alerts.TwoPhotos"))
                return;
            }
            //console.log('2')

            var isConnected = await checkNetworkConnection();
            if (isConnected === false) {
                openSimpleAlert('Error', i18n.t("Alerts.NoInternetConnection"))
                return;
            }
            //console.log('3')

            const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
            //console.log('4',permissionResult)
            if (permissionResult.granted === false || permissionResult.accessPrivileges ==='limited' || permissionResult.canAskAgain === false) {
                Platform.OS === 'ios' ?
                    openSimpleAlert(i18n.t('Alerts.CameraRollPermissionsTitle'), i18n.t('Alerts.CameraRollPermissionsTextIphone'))
                : 
                    openSimpleAlert(i18n.t('Alerts.CameraRollPermissionsTitle'), i18n.t('Alerts.CameraRollPermissionsTextAndroid'))

                return 
            }

            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: false,
                quality: 1,
                exif:true
            });

                if (!result.cancelled) {
                    setNowUploading(true)
                    cropFace(result,null)
            }
            } catch (e) {
                if (e.code === 'E_IMAGE_MANIPULATOR_CROP_DATA' || e.code==='E_FACE_DETECTION_FAILED') {
                    //console.log('ERROR IN pickImageFromGallery related to crop or face detection: ', e.message)
                    addImageToImagesArray(result,false, false) // flip=false, createAsset=false
                } else {
                    //console.log('UNKNOWN ERROR IN pickImageFromGallery:', e)
                }
            }
    };
    
    // Function to resize image.
    // The resize taking is taking the bigger dimension and reduce it to "max" while keeping ratio

    const resizeImage = async (photo,max) => {
        var w = photo.width // original w
        var h = photo.height // original h

        var ratio = Math.min(max / w, max / h); // calculate desired ratio + find larger dimensoin
        // For example: w=2000, h=1000, max = 500, max/w=0.25, max/h=0.5. 
        // We found that max/w < max/h ===> In that way we find the bigger dimension and the ratio at the same time

        var _w =  w*ratio // new w
        var _h = h*ratio // new h

        var result = await ImageManipulator.manipulateAsync( // resizing to new dimensions and compressing
            photo.uri,
            [{ resize: { width: _w, height: _h } }],
            { format: 'jpeg', compress:0.6}
            );

        return {result: result, ratio: ratio}
    }


    const cropFace = async (photoObject,index) => {
        try {
            var flip=false; // should flip the photo or not (sometimes it looks like mirror photo)
            // Forced flip = false cause i found the problem and fixed it in ****FLIP FIX***

            var w = photoObject.width
            var h = photoObject.height
            var imageForCrop // Small photo for crop 
            var R // Ratio between original image and resized image (resized for crop)

            //***FLIP FIX**** First, verifying that the image is in jpeg format + flipping it to the correct direction to avoid mirroring
            photoObject = await ImageManipulator.manipulateAsync(
                        photoObject.uri,
                        [],
                        { format: 'jpeg',  }
                        );

            // First resize for CROP - 500*500 - resizing the image to do the crop faster. 
            // This 500*500 image is not uploaded to DB and not sent to analyzer

            if (w>500 || h>500) { 
                const {result,ratio} =  await resizeImage(photoObject,500) // send to resize function
                imageForCrop=result //
                R=ratio
            }

            //if both dims smaller than 500 - only compressing to 0.6 without resizing
            else {
                R=1
                imageForCrop = await ImageManipulator.manipulateAsync(
                    photoObject.uri,
                    [],
                    { format: 'jpeg', compress:0.6}
                    );
            }

            // We are done preparing image for crop, now lets send it to python server to get coordinates for face boxes
            var response
            if (isWeb) { // Sending photo from web to python server
                let base64str = imageForCrop.uri.split(',')[1] // extracting the vase64 string part
                let base64obj = {base64str: base64str} // putting base64str in object that will be sent to server
                response = await getFaceBoxForWeb(base64obj) // sending base64str to server, server decodes it to a real image and send us the face box
            } else { // mobile
                var formdata = new FormData(); // sending data to server as form data
                formdata.append('file', {
                    uri: imageForCrop.uri, // your file path string
                    name: 'my_photo.jpg',
                    type: 'image/jpg'
                })    
                response = await getFaceBoxForMobile(formdata) // sending photo to server, server decodes it to a real image and send us the face box
            }

            //Python crop result:
            // { 
                    // Status : 'OK' (1 face) / 'Error' (0 faces or more than 1 face)
                    // msg: <Face boxes array>
            // }

            if (response.Status === 'OK'){ // 1 face detected
                var margins = response.msg[0] // margins array: [top,right,bottom,left]
                var top = margins[0] 
                var right = margins[1]
                var bottom = R === 1 ? margins[2] : parseInt(Math.min(margins[2]*1.05,Math.min(500,photoObject.height)))  // 
                var left = margins[3]
                var width = right - left // measuring width
                var height = bottom - top // measuring height
                var originX = left // originX
                var originY = Math.max(top - (height*0.25),0) // originY
                height = height*1.25   //update new height
                const cropObject = { // ImageManipulator.manipulateAsync expects cropObject in this format
                    originX:  originX / R,
                    originY: originY / R,
                    width: width / R,
                    height: height / R
                }  

                if (cropObject.height > photoObject.height ) { 
                    cropObject.height = photoObject.height
                }
                if (cropObject.width > photoObject.width ) {
                    cropObject.width = photoObject.width
                }



        // checking if we need to crop

        if (
            ((Math.max(photoObject.width,photoObject.height)<=500 && !isWeb) // Small image from mobile - probably got cropped already, 
            // anyway cropping again will make it too small for machines
            //SO NO CROPPING
            && 
            (photoObject.width - cropObject.width < 100) // Small difference between crop and original image
            && 
            (photoObject.height-cropObject.height<100)) // Small difference between crop and original image
            || 
            (photoObject.width>photoObject.height && !isWeb)) // Horizontal image from mobile
            {
                    //If small image (after or before crop) (one of the dimensions smaller than 500) - enlrage it to 500
                    if (photoObject.width < 1500 || photoObject.height < 1500) {
                        var w = photoObject.width // original w
                        var h = photoObject.height // original h
                
                        var ratio = Math.max(1500 / w, 1500 / h); // calculate desired ratio + find larger dimensoin
                        // For example: w=2000, h=1000, max = 500, max/w=0.25, max/h=0.5. 
                        // We found that max/w < max/h ===> In that way we find the bigger dimension and the ratio at the same time
                
                        var _w =  w*ratio // new w
                        var _h = h*ratio // new h
                
                        photoObject = await ImageManipulator.manipulateAsync( // resizing to new dimensions and compressing
                            photoObject.uri,
                            [{ resize: { width: _w, height: _h } }],
                            { format: 'jpeg'}
                            );
                    } 
                index ? addImageToImagesArrayWithIndex(photoObject,index,flip, false) : addImageToImagesArray(photoObject,flip, false) // adding to image array (main screen)
            } else { // MAKE CROP
                let shouldAddAsset
                //console.log('DID CROP')
                ImageManipulator.manipulateAsync(
                    photoObject.uri,
                    [{ crop: cropObject }],
                ).then(async(new_image_only_face) => { // recieved image with cropped face, now maybe need to resize again
                    //final resize!!!!
                    var image
                    if (new_image_only_face.width>=1500 || new_image_only_face.height>=1500) { // resizing to 1500 if needed - when image is too big
                        const {result,ratio} = await resizeImage(new_image_only_face, 1500)   
                        image=result
                        shouldAddAsset = true            
                    } else {
                        image = new_image_only_face; // no need to resize
                        shouldAddAsset = false            
                    }
                    //If small image (after or before crop) (one of the dimensions smaller than 500) - enlrage it to 500
                    if (image.width < 500 || image.height < 500) {
                        let w = image.width // original w
                        let h = image.height // original h
                
                        let ratio = Math.max(500 / w, 500 / h); // calculate desired ratio + find larger dimensoin
                        // For example: w=2000, h=1000, max = 500, max/w=0.25, max/h=0.5. 
                        // We found that max/w < max/h ===> In that way we find the bigger dimension and the ratio at the same time
                
                        let _w =  w*ratio // new w
                        let _h = h*ratio // new h
                
                        image = await ImageManipulator.manipulateAsync( // resizing to new dimensions and compressing
                            image.uri,
                            [{ resize: { width: _w, height: _h } }],
                            { format: 'jpeg'}
                            );
                        shouldAddAsset = true            
                    } 
                    
                    //console.log(image)

                    //console.log('2, index = ', index)

                    index ? addImageToImagesArrayWithIndex(image,index,flip) : addImageToImagesArray(image,flip) // adding to image array (main screen)
                });
            }


            // RECOGNIZED 0/MORE THAN 1 FACE, STATUS != OK, no croping. only resizing if needed
            } else {
                var image
                if (photoObject.width>=1500 || photoObject.height>=1500) { // resizing to 1500 if needed
                    const {result,ratio} = await resizeImage(photoObject,1500)   
                    image=result
                } else {
                    image = photoObject;
                }
                index ? addImageToImagesArrayWithIndex(image,index,flip,false) : addImageToImagesArray(image,flip,false)  // adding to image array (main screen)
            }



        } catch (e) {   
                //console.log('CROP ERROR: ',e.message)
                index ? addImageToImagesArrayWithIndex(photoObject,index,flip, false) : addImageToImagesArray(photoObject,flip,false) // adding to image array (main screen)

        } 

    }


    const takePhotoWithCamera = async () => {
        try {

            if (imagesURI.length >= 2) {
                openSimpleAlert('Error', i18n.t("Alerts.TwoPhotos"))
                return;
            }    
        
            var isConnected = await checkNetworkConnection();
            if (isConnected === false) {
                openSimpleAlert('Error', i18n.t("Alerts.NoInternetConnection"))
                return;
            }
            

            // if (isComputerWeb){
            //     let deviceHasCamera = await Camera.isAvailableAsync()
            //     console.log('Device has camera: ', deviceHasCamera)
            //     console.log('Camera Types available: ', cameraTypesAvailable)
            //     if (!deviceHasCamera) {
            //         openSimpleAlert('Error', 'This device does not have camera!')
            //         return;
            //     }
            // }

        
            
            let permissionResult = await ImagePicker.requestCameraPermissionsAsync();
            console.log('TEST IDO',permissionResult)
            if (permissionResult.granted === false || permissionResult.canAskAgain === false) {
                if (Platform.OS === 'ios'){
                    openSimpleAlert(i18n.t('Alerts.CameraPermissionsTitle'), i18n.t("Alerts.CameraPermissionsTextIphone"))
                }
        
                else if (Platform.OS === 'android'){
                    openSimpleAlert(i18n.t('Alerts.CameraPermissionsTitle'), i18n.t("Alerts.CameraPermissionsTextAndroid"))
                }
                return;    
            }
            setCameraAnimatableState('fadeIn')
        
            setShowCamera(true)
        } catch (e) {
            console.log('Error in opening camera:', e)
        }

    }

    const accessCamera = async () => {
        var photo = await cameraRef.current.takePictureAsync({exif: true, skipProcessing: true,})
        var flipped_photo
        
        console.log('EXIF: ', photo)
        console.log(Platform.OS)
        // In mobile, exif data has Orientation value. More on Orientation: https://sirv.com/help/articles/rotate-photos-to-be-upright/
        // Checking if we get problematic orientation values, if so - we manipulate
        let isMobileFlipped = photo.exif.Orientation && (
            (photo.exif.Orientation === 8) && Platform.OS === 'android' || // Orientation 8 and 0 in android cause flips
            ( photo.exif.Orientation === -90 && Platform.OS === 'ios' && cameraType === 'front' ) // orientation -90 in IOS (same like 8 in android)
            )

        // In web, exif data has no Orientation field.
        // After testing in web from computer, android, ios, I found that all photo filmed from FRONT camera (PC & Mobile), are flipped horizontally.
        // Exif data for web has "facingMode" value, facingMode=user ---> FRONT Camera.
        // Addition: in macOS computers, there is no facingMode field also
        let isWebFlippedNotMac = Platform.OS === 'web' && photo.exif.facingMode && photo.exif.facingMode === 'user'
        let isWebFlippedMac = Platform.OS === 'web' && !photo.exif.facingMode

        if (isMobileFlipped || isWebFlippedNotMac || isWebFlippedMac){
            flipped_photo = await ImageManipulator.manipulateAsync(
                photo.uri,
                [{ flip: ImageManipulator.FlipType.Horizontal }],
                { format: 'jpeg' }
                );
        } else {
            flipped_photo=photo
        }
        flipped_photo.exif = photo.exif
        setShowCameraPreview(true)
        setPreviewImage(flipped_photo)    
    }

    const setFreeAttempts = async (attempts) => {
        // await AsyncStorage.removeItem('guest_analyzes')
        await AsyncStorage.setItem('guest_analyzes', attempts)
    }


    const checkFreeAttemptsForGuest = async () => {
        // AsyncStorage.removeItem('guest_analyzes')

        try {
            await AsyncStorage.getItem('guest_analyzes', (err,result) => {
              var result_integer = parseInt(result)
              if (result==null){ // first attempt
                setFreeAttempts('1')
                if(imagesURL.length>0 && !nowUploading) handleTransactionStart()
                else if (nowUploading) alert('Still Uploading images to server..')
              }
              else if (result_integer==1 || result_integer==2) { // second / third attempt
                var new_attempes = result_integer+1
                setFreeAttempts(new_attempes.toString())
                if(imagesURL.length>0 && !nowUploading) handleTransactionStart()
                else if (nowUploading) alert('Still Uploading images to server..')
              }
              else if (result_integer==3) { // fourth+ attempt
                    setAlertTitle(i18n.t('Alerts.GuestAlertTitle'))
                    setAlertMsg(i18n.t('Alerts.GuestAlertMsg'))
                    setAlertCancelText(i18n.t('Alerts.Cancel'))
                    setAlertConfirmText(i18n.t('Alerts.OK'))
                    setConfirmPressedCallback(() => () => {navigation.navigate('authNavigator'); setShowAlert(false)})
                    setCancelPressedCallback(() => () => setShowAlert(false))
                    setShowAlert(true)
                }
          })
        } catch(err) {
          //console.log(err)
        }

    }

    const handleAnalyzePress = async() => {
        //didnt upload nothing
        if(imagesURL.length === 0) {
             openSimpleAlert('Error',i18n.t('Alerts.MustUploadOne'))
        }

        //guest
        else if(!currentUser && !isPro){
            checkFreeAttemptsForGuest()
        }

        //pro/user
        else  {
            if(imagesURL.length>0 && !nowUploading) handleTransactionStart()
            else if (nowUploading) alert('Still Uploading images to server..')
        }

    }

    const handleInstructionsPress = () => {
        setShowInstructionsPopup(true)
    }
    
    const setCameraReady = async() => {
        if (!isRatioSet) {
            if (Platform.OS === 'android') await prepareRatio()
            else {
                setIsRatioSet(true)
            }
        } 
    }
    const prepareRatio = async() => { // Find best ratio for camera component - relevant for Android only!!!!!

        // get all supported Ratios: ["1:1", "2:1", "4:3", "16:9" ...]
        let ratios = []
        ratios = await cameraRef.current.getSupportedRatiosAsync()
        // calculate ratio by division of height and width: [1, 2, 1.33333, 1.77777]
        // After calculating, we filter values smaller than screenRatio to avoid a ratio too wide

        // Now we will try to find closest ratio to our screenRatio
        // Sorting the distances array, removing negative distances 
        let desiredRatio = '2:1' // default

        //realratios and distances will look like: {'1:1': 1, '2:1': 2}
        let realRatios = {}
        let distances = {}

        // minDistance will keep the currently minimal distance ratio
        let minDistance = null

        for (const ratio of ratios) {
            let realRatio = parseInt(ratio.split(':')[0]) / parseInt(ratio.split(':')[1])
            realRatios[ratio] = realRatio
            let distance = screenRatio - realRatio
            distances[ratio] = distance

            if (minDistance == null) {
                minDistance = ratio
            } else {
                if (distance >= 0 && distance < distances[minDistance]) {
                    minDistance = ratio
                }
            }
        }

        desiredRatio = minDistance
        let remainder = Math.floor((Dimensions.get('window').height - realRatios[desiredRatio] * Dimensions.get('window').width) / 2)
        setCameraRatio(desiredRatio)
        setCameraPadding(remainder)
        setIsRatioSet(true)


    }

    const onGesturePinch = ({nativeEvent}) => {

        let p = nativeEvent.scale
        let p2 = p - prevPinch
        if (p2 > 0 && p2 > ZOOM_F) {
            setPrevPinch(p)
            setZoom(Math.min(zoom + ZOOM_F , 1))
        }
        else if (p2<0 && p2 < -ZOOM_F) {
            setPrevPinch(p)
            setZoom(Math.max(zoom - ZOOM_F , 0))
        }
        
    }


    const onHandlerStateChange = ({nativeEvent}) => {
        const pinch_start = nativeEvent.state === State.END
        const pinch_begin = nativeEvent.oldState === State.BEGAN
        const pinch_active = nativeEvent.state === State.ACTIVE

    }

        if (showCameraPreview) return <CameraPreview photo={previewImage} />
        else if (showCamera) return (
            <View style={{backgroundColor:'transparent', flex:1, alignItems:'center', justifyContent:'center'}}>
                <NavigationEvents onWillFocus={() => {setCameraAnimatableState('fadeOut'); setShowCamera(false)}}/>
                <PinchGestureHandler onGestureEvent={onGesturePinch} onHandlerStateChange={onHandlerStateChange}>

                    <Animatable.View animation={cameraAnimatableState} 
                                     duration={2000} 
                                     useNativeDriver={true}
                                     style={{ width: WINDOW_WIDTH, 
                                              height: isComputerWeb ? WINDOW_HEIGHT * 0.75 : WINDOW_HEIGHT, 
                                              marginTop: isComputerWeb ? WINDOW_HEIGHT * 0.02 : isWeb ? WINDOW_HEIGHT*0.1 : Platform.OS === 'ios' ? WINDOW_HEIGHT*0.2 : WINDOW_HEIGHT*0.2,
                                              position: 'relative'
                                    }}>
                        <Camera 
                            style={{width: WINDOW_WIDTH, height: WINDOW_HEIGHT}}
                            onCameraReady={setCameraReady}
                            type={cameraType === 'back' ? Camera.Constants.Type.back : Camera.Constants.Type.front} 
                            ref={cameraRef}
                            zoom={zoom}
                            flashMode={flashMode}
                            ratio={cameraRatio}
                         />
                        <View style={styles.ellipse}/>
                        <TouchableOpacity onPress={()=>{setCameraAnimatableState('fadeOut') ;setShowCamera(false)}} 
                                          style={{position:'absolute', top: '3%', left: '3%'}}>
                            <MaterialIcons name="chevron-left" color="#fff" size={PR <= 2 ? 34 : 38} />
                        </TouchableOpacity>
                        { !isWeb &&
                            <Text style={{
                                fontFamily:'latoregular', color:'#fff', position:'absolute', 
                                top: '4%', right: '6%', fontSize:18}}>
                                Zoom level: {(zoom*10).toFixed(1)}x
                            </Text>
                        }
                        {   !isWeb &&
                            <TouchableOpacity onPress={async()=>{
                                setCameraType(cameraType==='front' ? 'back' : 'front')
                                console.log(`Old:${Camera.Constants.Type.back} ${Camera.Constants.Type.front}`)

                            }}
                                            style={{ position:'absolute', 
                                                    bottom: isComputerWeb ? 20 : isWeb ? '22%' : '20%', 
                                                    right: '7.5%'
                                                    }}>
                                <MaterialIcons name="flip-camera-ios" color="#fff" size={PR <= 2 ? 34 : 38}/>
                            </TouchableOpacity>
                        }
                        { !isWeb &&<TouchableOpacity onPress={() => setFlashMode( flashMode === 'off' ? 'on' : flashMode==='on' ?  'auto' : 'off')} //FLASH DOES NOT WORK IN WEB
                                          style={{ position:'absolute', 
                                                   bottom: isComputerWeb ? 20 : isWeb ? '22%' : '20%', 
                                                   left: '7.5%',
                                                }}>
                            { flashMode === 'off' ? <MaterialIcons name="flash-off" color="#fff" size={PR <= 2 ? 34 : 38} /> 
                            : flashMode === 'on' ? <MaterialIcons name="flash-on" color="#fff" size={PR <= 2 ? 34 : 38} /> 
                            : <MaterialIcons name="flash-auto" color="#fff" size={PR <= 2 ? 34 : 38}/> }
                        </TouchableOpacity>}
                        <TouchableOpacity onPress={accessCamera} 
                                          style={{ position:'absolute', 
                                                   left: (WINDOW_WIDTH / 2) - (50/2), // Middle of window MINUS half of width (50)
                                                   bottom: isComputerWeb ? 20 : isWeb ? '22%' : '20%'
                                                }}>
                            <Image source={require('../../../assets/cameraclick.png')} style={{width: 50, height: 50}}/>
                        </TouchableOpacity>
                    </Animatable.View>
                </PinchGestureHandler>
            </View>
        );

        else
        return (
                <ImageBackground
                 source={require('../../../assets/app_background.png')}
                 resizeMode='cover'
                 style={styles.background}>
            <View  style={[styles.MainView, 
                            isComputerWeb ? {
                                marginVertical:'15%'
                            } : {
                                marginVertical:'15%'
                            }
                            ]}>
                    <NavigationEvents onWillFocus={() => {
                        checkIfUserOrPro();
                        clearAnalysis();
                    if (navigation.state.params && navigation.state.params.resetPhotos) {    
                        if (!nowUploading){
                            setImagesURL([]); 
                            setImagesURI([]);
                        }                
                        setFrontPicExists(false)
                        setSidePicExists(false)
                        setIndex(null)
                    }
                    else {
                        // dont reset images
                        //console.log(imagesURL)
                        navigation.setParams({'resetPhotos': true})        
                        }                       
                    }}/>
                <View style={[styles.topView, isComputerWeb && {marginVertical:30}]}>

                    <Button type="solid"
                        title={i18n.t('MainScreen.UploadBtn')}
                        buttonStyle={styles.uploadButton}
                        titleStyle={styles.uploadButtonText}
                        opa
                        onPress={ () => { 
                            if(!nowUploading) pickImageFromGallery()
                        } }
                        icon={
                        <MaterialCommunityIcons
                        name="image-multiple"
                        size={isComputerWeb ? 30 : 24}
                        color="#A9042B"
                        />}
                        iconRight={true}
                        TouchableComponent={TouchableOpacity}
                    />
                    { !isWeb ?  
                    <Button type="solid"
                            title={i18n.t('MainScreen.TakePhotoBtn')} 
                            buttonStyle={styles.takePhotoButton}
                            titleStyle={styles.uploadButtonText}
                            onPress={ () => { 
                            if(!nowUploading) takePhotoWithCamera()
                        } }
                        icon={
                        <MaterialCommunityIcons
                        name="camera-plus"
                        size={isComputerWeb ? 30 : 24}
                        color="#A9042B"
                        />}
                        iconRight={true}
                        TouchableComponent={TouchableOpacity}
                    />

                    : <View style={{flexDirection:'row', 
                                    justifyContent:'space-between',
                                    width:  WINDOW_WIDTH * 0.656,
                                }}>
                    <Button type="solid"
                            title='Front Camera' 
                            buttonStyle={[styles.takePhotoButton,{width:WINDOW_WIDTH * 0.656 * 0.45, marginBottom:0}]}
                            titleStyle={styles.uploadButtonText}
                            onPress={ () => { 
                                setCameraType('front')
                                if(!nowUploading) takePhotoWithCamera()
                        } }
                        icon={
                        <MaterialCommunityIcons
                        name="camera-plus"
                        size={isComputerWeb ? 30 : 24}
                        color="#A9042B"
                        />}
                        iconRight={true}
                        TouchableComponent={TouchableOpacity}
                    />
                    <Button type="solid"
                            title='Rear Camera'  
                            buttonStyle={[styles.takePhotoButton,{width:WINDOW_WIDTH * 0.656 * 0.45, marginBottom:0}]}
                            titleStyle={styles.uploadButtonText}
                            onPress={ () => { 
                                setCameraType('back')
                                if(!nowUploading) takePhotoWithCamera()
                            } }
                        icon={
                        <MaterialCommunityIcons
                        name="camera-plus"
                        size={isComputerWeb ? 30 : 24}
                        color="#A9042B"
                        />}
                        iconRight={true}
                        TouchableComponent={TouchableOpacity}
                    />
                    </View>

                    }
                </View>
                <View style={[styles.centerView, isComputerWeb && {marginBottom:30}]}>
                    <View>  
                    
                            <TouchableOpacity
                              onPress={() => 
                                { 
                                    if (!isWeb) {
                                        if ((!imagesURL.some( item => item.index === 0)) && !nowUploading) {
                                                    setIndex(0)
                                                    openPhotoAlert(0)
                                                }
                                            }
                                }
                              }
                             >
                                {imagesURL.some( item => item.index === 0) ? 
                                    <Image source={{uri: imagesURI.find(item => item.index === 0).uri}} style={[styles.imageContainer]}/>
                                :  
                                <> 
                                    <Image source={require('../../../assets/new_front.png')} style={[styles.imageContainer]}/>
                                    <Text 
                                        style={{color:'#000', alignSelf:'center', position:'absolute', fontFamily:'latobold', bottom:5}}>
                                        {i18n.t('MainScreen.Front')}
                                    </Text>
                                </>
                                }
                            </TouchableOpacity>
                            { imagesURL.some( item => item.index === 0) &&
                            <>
                                <TouchableOpacity style={styles.removeImage} 
                                    onPress={() => 
                                        {   
                                            if (imagesURL.some( item => item.index === 0) && !nowUploading) {
                                                removeImage(0)
                                                setFrontPicExists(false)
                                            } 
                                        }
                                    }>
                                        <AntDesign name="close" size={15} color="#fff"/>
                                </TouchableOpacity>
                            </>
                            }

                    </View>
                    <View style={{opacity: !frontPicExists ? 0.2 : 1}}>
                    <TouchableOpacity disabled={!frontPicExists} 
                                    onPress={() => { 
                                        if (!isWeb){
                                        if ((!imagesURL.some( item => item.index === 1)) && !nowUploading) 
                                        {setIndex(1); openPhotoAlert(1)}
                                    }}
                                }
                                    >
                                {imagesURL.some( item => item.index === 1) ? 
                                    <Image source={{uri: imagesURI.find(item => item.index === 1).uri}} style={[styles.imageContainer]}/>
                                :
                                <>
                                    <Image source={require('../../../assets/new_profile.png')} style={[styles.imageContainer]}/>
                                    <Text 
                                    style={{color:'#000', alignSelf:'center', fontFamily:'latobold', position:'absolute', bottom:5}}>
                                        {i18n.t('MainScreen.AnySide')}
                                    </Text>
                                </>
                                }
                    </TouchableOpacity>
                        { imagesURL.some( item => item.index === 1) && frontPicExists &&
                            <>
                                <TouchableOpacity style={styles.removeImage} 
                                    onPress={() => 
                                        {   
                                            if (imagesURL.some( item => item.index === 1) && !nowUploading) {
                                                !nowUploading && removeImage(1); 
                                                setSidePicExists(false)
                                            } 
                                        }
                                    }>
                                        <AntDesign name="close" size={15} color="#fff"/>
                                </TouchableOpacity>
                            </>
                        }
                    </View>
                </View>
                <TouchableOpacity style={{flexDirection: lang==='en' ? 'row' : 'row-reverse', alignItems:'center'}} onPress={handleInstructionsPress}>
                    <Text style={{fontFamily:'latoblack', fontSize:18, color:'#A9042B',textAlign:'center', textDecorationLine:'underline'}}>
                        {i18n.t("MainScreen.ForBest")}
                    </Text>
                </TouchableOpacity>
                { imagesURL.length > 0 && !frontPicExists &&
                    <Text style={{color:'#fa0c0c', fontFamily:'latoblack', fontSize:14, marginTop: 10}}>You must upload front picture!</Text>
                }
                { 
                showInstructionsPopup && 
                        <Overlay  
                        overlayStyle={styles.instructionsPopupOverlayStyle}
                        onBackdropPress={() => setShowInstructionsPopup(false)}>
                            <TouchableOpacity style={{position:'absolute', top:30, right:25, zIndex:99}} onPress={() => setShowInstructionsPopup(false)}>
                                <MaterialIcons name="close" color="#fff"  size={22}/>
                            </TouchableOpacity>
                            <NewInstructionsPopup />
                        </Overlay>
                    }
                <View style={styles.bottomView}>
                {nowUploading? 
                <View style={{flexDirection:'row', justifyContent:'center'}}>
                    <ActivityIndicator size="small" color="#A9042B"/>
                    <Text style={{fontFamily:'latoregular', color:'#A9042B',}}> {i18n.t('MainScreen.Uploading')}</Text>
                </View>
                :
                
                <LinearGradient colors={['#A0002A', '#D31631']} style={[{borderRadius:6,opacity: !frontPicExists ? 0 : 1},isComputerWeb && {marginTop:10}]} disabled={!frontPicExists}>
                    <View>
                        <TouchableOpacity style={styles.analyzeButton} 
                                        onPress={handleAnalyzePress}
                                        disabled={!frontPicExists}>
                            <Text style={styles.analyzeButtonText}>
                                {i18n.t('MainScreen.AnalyzeBtn')}
                            </Text>
                        </TouchableOpacity>
                    </View>
                </LinearGradient>
                }

                </View>
                {/* <Image source={require('../../../assets/progress_bar.png')} style={styles.progressBar}/> */}

                <AwesomeAlert
                    show={showAlert}
                    title={alertTitle}
                    message={alertMsg}
                    closeOnTouchOutside={true}
                    closeOnHardwareBackPress={false}
                    showConfirmButton={true}
                    showCancelButton={alertShowCancelButton}
                    cancelText={alertCancelText}
                    confirmText={alertConfirmText}
                    confirmButtonColor="#A9042B"
                    cancelButtonColor="#A9042B"
                    onDismiss={() => setShowAlert(false)}
                    onCancelPressed={cancelPressedCallback}
                    onConfirmPressed={confirmPressedCallback}
                    actionContainerStyle={{flexDirection: 'row-reverse'}}/>

            </View>
                </ImageBackground>
            
        );
}
    
const styles = StyleSheet.create({
MainView: {
    flexDirection:'column',
    // backgroundColor: '#2E323F',
    justifyContent: 'space-around', 
    alignItems: 'center',
    flex: 1,
    },
topView: {
    alignItems: "center",
},
centerView: {
    flexDirection:"row",
    justifyContent: 'space-evenly',
    alignItems:'center',
},
bottomView: {
    marginBottom: 5,
    // position: 'absolute',
    // bottom: '-30%',
    alignSelf: 'center',

},
uploadButton: {
    backgroundColor:'transparent',
    borderColor:'#A9042B',
    borderWidth:3,
    borderRadius: 9,
    width:  WINDOW_WIDTH * 0.656,
    height: 45,
    marginBottom:10,
    justifyContent:'center',
    paddingHorizontal:'4%'
    
},

takePhotoButton: {
    backgroundColor:'transparent',
    borderColor:'#A9042B',
    borderWidth:3,
    borderRadius: 9,
    width: WINDOW_WIDTH * 0.656,
    height: 45,
    marginVertical:10,
    paddingHorizontal:'4%'
},

uploadButtonText:{
    color: '#A9042B',
    fontSize:16,
    fontFamily: 'latoblack',
    flex:1
    // marginHorizontal:10
},
imageContainer: {
    padding:10,
    marginHorizontal:10,
    height:  isComputerWeb ? 200 : WINDOW_HEIGHT*0.25,
    width: isComputerWeb ? 150 : WINDOW_WIDTH*0.40,
    borderColor:'#A9042B',
    borderWidth:3,
    borderRadius: 20,
},

analyzeButton: {
    // backgroundColor:'#A9042B',
    // background: linear-gradient('#A0002A', '#D31631'),
    width: WINDOW_WIDTH * 0.656,
    height: 52,
    justifyContent:'center',
    alignSelf:'center'
},


analyzeButtonText: {
    color: '#fff',
    fontSize:20,
    textAlign: "center",
    fontFamily:'latoregular'
},
removeImage: {
    position: "absolute",
    justifyContent:'center',
    alignItems:'center',
    bottom: 10,
    left: 20,
    height:22,
    width:22,
    borderRadius:30,
    borderColor:'#fff',
    borderWidth:1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)'

},
backBtn: {
    marginHorizontal:5,
    borderRadius: 30,
    padding:8,
    marginTop: 10,
    justifyContent:'center',
    width: WINDOW_WIDTH*0.2,
    alignSelf:'center',
    marginTop:15,
    height: WINDOW_HEIGHT*0.065,
}, 

background: {
    width: '100%',
    height: '100%',
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0
},

progressBar: {
    alignSelf:'center',
    marginBottom:'6.5%'
},

ellipse: {
    position:'absolute',
    top: isComputerWeb ? '30%' : '30%',
    left: isComputerWeb ? WINDOW_WIDTH / 2 - ELLIPSE_SIZE_COMPUTER_WEB / 2 : WINDOW_WIDTH / 2 - ELLIPSE_SIZE / 2,
    width: isComputerWeb ? ELLIPSE_SIZE_COMPUTER_WEB : ELLIPSE_SIZE,
    height: isComputerWeb ? ELLIPSE_SIZE_COMPUTER_WEB : ELLIPSE_SIZE,
    borderRadius: isComputerWeb ? ELLIPSE_SIZE_COMPUTER_WEB : ELLIPSE_SIZE,
    transform: [
        {scaleY: 2.5},
        {scaleX: 1.8},
    ],
    backgroundColor:'transparent',
    borderWidth:3,
    borderColor:'#fff'
},

instructionsPopupOverlayStyle: { 
    height:WINDOW_HEIGHT*0.77, 
    width: isComputerWeb ? WINDOW_WIDTH*1.1 : WINDOW_WIDTH*0.83,
    padding:0,
    borderRadius:15
}



});

export default UploadScreen;