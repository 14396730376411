import React, { useState, useContext, useEffect } from 'react';
import { Text,View, StyleSheet, Image,Dimensions, Platform } from 'react-native';
import { Flow } from 'react-native-animated-spinkit'
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import * as Device from 'expo-device';

const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 


// const WINDOW_WIDTH = Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;

i18n.translations = {
    en: english,
    he: hebrew
}


const ResultsLoader = ({images, isLoading}) => {

    return (
        isLoading ? 
        <View style={{justifyContent:'center', alignItems:'center'}}>
                {
                images.length == 2 ?
                <View style={{flexDirection:'row', justifyContent:'space-around'}}>
                    <Image source={{uri: images[0].uri}} style={styles.imageContainer}/>
                    <Image source={{uri: images[1].uri}} style={styles.imageContainer}/>
                </View> : 
                images.length == 1 ?
                <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Image source={{uri: images[0].uri}} style={styles.imageContainer}/>
                </View> : 
                null
                }
            <View style={{alignItems: 'center', marginTop:50, marginHorizontal:10}}>
                <Text style={{fontSize:20, textAlign:'center',fontFamily:'latobold', color:'#000'}}>{i18n.t("ResultLoader.SystemProccessingData")}</Text>
                <Text style={{marginBottom: 15 ,fontSize:20, textAlign:'center',fontFamily:'latoregular', color:'#000'}}> {i18n.t("ResultLoader.PleaseWait")}</Text>
                <Flow size={70} color="#A9042B" />
            </View>
            
        </View>
        : null
    );
}

const styles = StyleSheet.create({
    imageContainer: {
        padding:10,
        marginHorizontal:10,
        height: WINDOW_HEIGHT*0.25,
        width: WINDOW_WIDTH*0.40,
        borderRadius:5,
        resizeMode:'contain'
        },

})


export default ResultsLoader