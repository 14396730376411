import { Dimensions, Alert ,Platform } from 'react-native';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { WebView } from 'react-native-webview';
import { navigate } from '../navigationRef';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NavigationEvents } from 'react-navigation';
import {Context as AuthContext} from '../context/AuthContext'
import {Context as HistoryContext} from '../context/HistoryContext'
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import * as Device from 'expo-device';


const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
// const WINDOW_WIDTH = Dimensions.get('window').width
const WINDOW_HEIGHT = Dimensions.get('window').height

i18n.translations = {
  en: english,
  he: hebrew
}


const WebViewScreen = ({navigation}) => {
const [webViewUrl,setWebViewUrl] = useState('https://srcosmetics.co.il/account/login')
const [jsCode,setJsCode] =useState("document.getElementsByClassName('Form__Submit')[0].addEventListener('click' , function() { window.ReactNativeWebView.postMessage(document.querySelectorAll('input[type=email]')[0].value)})")
const type = navigation.getParam('type', 'login')
const { set_pro, set_pro_name,set_pro_mail,signout } = useContext(AuthContext)
const { deleteHistory } = useContext(HistoryContext)
const [proMail, setProMail] = useState(null)
const webViewRef = useRef();
const [showWebView,setShowWebView] = useState(false)

const checkIfPro = () => {
   AsyncStorage.getItem('is_pro', (err,result) => {
    if (result == 'yes' && type === 'login') {
      Alert.alert("","Already Logged in",[{text: "OK",
            onPress: () => navigate('analyzerFlow')
      }],
    
      );

    } else if (result == 'yes' && type === 'logout') {
          Alert.alert("",i18n.t('UserAuth.ProLogout'),[{text: i18n.t('Alerts.OK'),
          onPress: () => {
                          setWebViewUrl('https://srcosmeticsglobal.myshopify.com/account'); 
                          setProToken('no'); 
                          AsyncStorage.removeItem('pro_name'); 
                          AsyncStorage.removeItem('SR_IL_Pro_Mail');
                          deleteHistory()
                          navigation.navigate('UPLOAD');
                        }
          }],
    );

    }

    else if (result == 'no' && type === 'logout'){
      Alert.alert("","Already Logged Out",[{text: "OK",
      onPress: () => {navigation.navigate('UPLOAD')}
      }],
);
    }

    else if (result == 'no' && type === 'login'){
      // webViewRef.current.reload()
    }
  })
}



   

  const setProToken = (isPro) => {
    return new Promise((resolve,reject) => {
      //console.log('******* setting is_pro = ', isPro)
      AsyncStorage.setItem('is_pro', isPro)
      set_pro(isPro === 'no' ? false : true)
      resolve()
    })
    }

  const setProName = (name) => {
    return new Promise((resolve,reject) => {
    AsyncStorage.setItem('pro_name', name)
    set_pro_name(name)
    resolve()
  })

  }

  const _setProMail = () => {
      //console.log('*LOGIN* 3) Setting pro mail to: ', proMail)
      AsyncStorage.setItem('SR_IL_Pro_Mail', proMail)
      set_pro_mail(proMail)
  }

  const _onNavigationStateChange = (navState ) => {
}


  const _onMessage = (event) => {
      var data = event.nativeEvent.data
      //console.log('From on message:')
      if (data.includes('@') && data.includes('.')) {
        //console.log('*LOGIN* 1) Setting Pro Mail to:', data)
        setProMail(data)
        setJsCode("window.ReactNativeWebView.postMessage(document.getElementsByClassName('SectionHeader__Description')[0].innerHTML)")
      }
      else if  (data && (data.split(' ')[0] === 'Welcome' || data.split(' ')[0] === 'ברוכים') ){
          
        //english name
        if (data.split(' ')[0] === 'Welcome')       
          {  

            //console.log('Welcome ', data)
            var str = data.split(' ')[2].slice(0, -1) // "ido"
            var proNameCapitalized = str.charAt(0).toUpperCase() + str.slice(1)
            //console.log('Name: ', proNameCapitalized)
            setProToken('yes').then(() => 
            setProName(proNameCapitalized).then(() => {
            Alert.alert("",i18n.t('Alerts.LoggedInSuccessPro'),[{text: "OK",
            onPress: () => {navigation.navigate('UPLOAD') ; signout(false) ; _setProMail()}
            }],)
  
          })
          
          )
      } // end of english

        //hebrew
        if (data.split(' ')[0] === 'ברוכים')       
          {          
            // //console.log('Your name is: ', proNameCapitalized)
            //console.log('*LOGIN* 2) ברוכים הבאים בעברית בלי שם:')
            setProToken('yes').then(() => 
            setProName('').then(() => {
            Alert.alert("",i18n.t('Alerts.LoggedInSuccessPro'),[{text: "OK",
            onPress: () => {navigation.navigate('UPLOAD'); signout(false) ; _setProMail()}
            }],)
  
          })
          
          )
      } // end of hebrew

      setJsCode("document.getElementsByClassName('Form__Submit')[0].addEventListener('click' , function() { window.ReactNativeWebView.postMessage(document.querySelectorAll('input[type=email]')[0].value)})")

 } 
 
 else {
        //console.log('PROBLEM HERE? setting pro token to no from else')
        setProToken('no')
      }
  }


    return (

          <>
                <NavigationEvents onWillFocus={checkIfPro}/>
                  <WebView
                  onNavigationStateChange={_onNavigationStateChange}
                  ref={(ref) => webViewRef.current = ref}
                  source={{ uri: type==='login' ? webViewUrl : 'https://srcosmeticsglobal.myshopify.com/account/logout' }}
                  javaScriptEnabled={true}
                  injectedJavaScript={type==='login' ? jsCode : ''}
                  sharedCookiesEnabled
                  onMessage={type==='login' && _onMessage}
                  style={{ flex: 1 }}
                />
          </>

    );
  
}

// WebViewScreen.navigationOptions =  ({ navigation }) => {
//   return {
//       title: 'PRO LOGIN',
//       headerTitleAlign: 'center',
//       headerTitleStyle: {
//         fontFamily: 'montregularnew',
//         letterSpacing: 2.5,
//         fontSize: Platform.OS === 'android' ? WINDOW_HEIGHT*0.09*0.33 : WINDOW_HEIGHT*0.13*0.33,
//       },
//       headerStyle: {
//           backgroundColor:'#c2af7e',
//       },
//       headerTintColor: '#ffffff'
//   }
// }


export default WebViewScreen;
