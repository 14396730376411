import React, { useState, useContext, useEffect } from 'react';
import { Text,View, StyleSheet, Image,ImageBackground,Dimensions, 
    TouchableOpacity, PixelRatio,ScrollView, TextInput,Alert} from 'react-native';
import {CheckBox} from 'react-native-elements'
import {Context as TransactionContext} from '../../context/TransactionContext'
import {Context as CartContext} from '../../context/CartContext'
import {Context as AuthContext} from '../../context/AuthContext'
import AnalysisResultGraph from '../../components/AnalysisResultGraph'
import MyMenu from '../../components/MyMenu'
import {Entypo} from 'react-native-vector-icons'
import ResultsLoader from '../../components/ResultLoader'
import {uriToBlob, uploadToFirebase} from '../../firebase/firebaseStorageUploader'
import ConsultFooter from '../../components/ConsultFooter'
import * as Animatable from 'react-native-animatable';
import ProductsList from '../../components/ProductsList'
import { Feather, FontAwesome5 } from "@expo/vector-icons";
import SelectPicker from 'react-native-form-select-picker';
import { Platform } from 'react-native';
import {firebaseAuth} from '../../firebase/config'
import AsyncStorage from '@react-native-async-storage/async-storage';
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import { NavigationEvents } from 'react-navigation';
import i18n from 'i18n-js'
import english from '../../lang/en.json'
import hebrew from '../../lang/he.json'
import { RadioButton } from 'react-native-paper';
import Constants from 'expo-constants';
import { LinearGradient } from 'expo-linear-gradient';
import * as Device from 'expo-device';
import AwesomeAlert from 'react-native-awesome-alerts';

const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const isWeb = Platform.OS === 'web' 
const WINDOW_WIDTH = isWeb ? 400 : Dimensions.get('window').width 

// const WINDOW_WIDTH = Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;
const headerRatio = 0.125
const FONT_SIZE =  PixelRatio.get() <= 2 ?  14 : 20;
const allDiseases = ['acne_mild','acne_medium','acne_severe','herpes','eczema','clean','rosi','small_face','no_face']
const OVERRIDE_MSG = 'Your diagnosis is:'
const PR = PixelRatio.get();

i18n.translations = {
    en: english,
    he: hebrew
}

const ResultsScreen = ({navigation}) => {
    const {state: {cart} , emptyCart} = useContext(CartContext)
    const {state: {analysis,lang,serverVersion, last_transaction_id, alertMsgFromContext} , 
    clearAnalysis, getDiagnosis, storeTransaction, updateTransactionOverride,
    updateTransactionCleanFace, dismissAlertFromContext} = useContext(TransactionContext)
    const {state: {currentUser}} = useContext(AuthContext)
    const imagesURL = navigation.state.params.images 
    const imagesURI = navigation.state.params.uris 
    const [isPro,setIsPro] = useState(null)
    const [SR_IL_Pro_Mail, set_SR_IL_Pro_Mail] = useState(null)
    const [SR_CA_US_Pro_Mail, set_SR_CA_US_ProMail] = useState(null)
    const [user,setUser] = useState(null)
    const [data, setData] = useState([]) //Here will be the percentage of the analysis
    const [url1, setUrl1] = useState(null)
    const [url2, setUrl2] = useState(null)
    const [disease,setDisease] = useState(null)
    const [secondDisease, setSecondDisease] = useState(null)
    const [override,setOverride] = useState(null)
    const [maxPercent, setMaxPercent] = useState(null)
    const [agree,setAgree] = useState(null) // false - disagreed, true - agreed, null - not pressed yet
    const [flex,setFlex] = useState(0)
    const [typing,setTyping] = useState(false)
    const [diagnosisMsg,setDiagnosisMsg] = useState(null)
    const [overrideReason, setOverrideReason] = useState(null)
    const [scars, setScars] = useState(false)
    const [scarsUser, setScarsUser] = useState(false)
    const [rosiUser, setRosiUser] = useState(false)
    const [manualHerpes, setManualHerpes] = useState(false)
    const [showProductsUser,setShowProductsUser] = useState(false)
    const [stains, setStains] = useState(false)
    const [wrinkles, setWrinkles] = useState(false)
    const [ageGroup, setAgeGroup] = useState('wrinkles_25_35')
    const [eyecure,setEyecure] = useState(false)

    // Alert stuff
    const [showAlert,setShowAlert] = useState(false)
    const [alertTitle,setAlertTitle] = useState(null)
    const [alertMsg,setAlertMsg] = useState(null)
    const [alertConfirmText,setAlertConfirmText] = useState(null)
    const [alertCancelText,setAlertCancelText] = useState(null)
    const [cancelPressedCallback,setCancelPressedCallback] = useState(null)
    const [confirmPressedCallback,setConfirmPressedCallback] = useState(() => () => {})
    const [alertShowCancelButton,setAlertShowCancelButton] = useState(false)
    


    useEffect(() => { // Things that happen once when page loads
        clearAnalysis();
        // startTransaction()
        // setIsPro(false)
        // setIsPro(true)
        checkIfUserOrPro();
        navigation.setParams({agree: null, forceAgreeNull: false, finishedOverride: false})
      }, []);

      useEffect(() => {
        if (alertMsgFromContext === 'DiagnosisTimeout') {
            setAlertTitle('')
            setAlertMsg(i18n.t("Alerts.DiagnosisTimeout"))
            setAlertConfirmText('OK')
            setAlertShowCancelButton(false)
            setConfirmPressedCallback(() => () => {navigation.navigate('UPLOAD'); setShowAlert(false); dismissAlertFromContext();       
        })
            setShowAlert(true)
            dismissAlertFromContext()    
        } else if (alertMsgFromContext === 'DiagnosisError')  {
            setAlertTitle('')
            setAlertMsg(i18n.t("Alerts.DiagnosisError"))
            setAlertConfirmText('OK')
            setAlertShowCancelButton(false)
            setConfirmPressedCallback(() => () => {navigation.navigate('UPLOAD'); setShowAlert(false); dismissAlertFromContext();       
        })
            setShowAlert(true) 
        }
      },[alertMsgFromContext])


      useEffect(() => { // setting flex according to pro/user
      if (isPro!==null) isPro ? setFlex(1) : setFlex(0)
      if (isPro === false) {
      }
      },[isPro])

      useEffect(() => { // here I will update the results of analysis
        if (analysis!=null && analysis.length>1 && disease!='no_face') {
            analysis.sort((a,b) => b.probability - a.probability)
            analysis.length = 2    
            var mydata = analysis.map(element => element.probability)
            var max = Math.max(...mydata) // find max
            var _disease
            mydata.sort((a,b) => b - a) // sorting array
            mydata.length = 2 // leave only first 2 elements
            setData(mydata) // Data for pie chart (array of numbers)
            setMaxPercent(max)
            _disease = analysis.find(element => element.probability == max)
            if (_disease.name !== 'herpes'){
                let isDisease = allDiseases.includes(_disease.name)
                let d = !isDisease ? 'no_face' : _disease.name
                setDisease(d)
                setSecondDisease(analysis[1].name)
            } else { // enforce switch between main and seocndary disease, 
                // to set herpes as secondary disease!!! even if it is main disease
                setDisease(analysis[1].name) 
                setSecondDisease(_disease.name)
            }

        }
        else if (analysis!=null && analysis.length==1 && disease!='no_face') {
            var mydata = analysis.map(element => element.probability)
            var max = Math.max(...mydata) // find max
            var _disease
            mydata.sort((a,b) => b - a) // sorting array
            mydata.length = 2 // leave only first 2 elements
            setData(mydata) // Data for pie chart (array of numbers)
            setMaxPercent(max)
            _disease = analysis.find(element => element.probability == max)
            // setDisease(_disease.name) 
            let isDisease = allDiseases.includes(_disease.name)
            let d = !isDisease ? 'no_face' : _disease.name
            setDisease(d) 
            // //console.log(new Date() ,': Start writing analysis results to the screen.. 1 picture')
        }
      },[analysis])


      useEffect(() => { // Things that will happen every time urls states gets updated
        var urls
        if (imagesURL.length == 1) urls = [imagesURL[0].uri]
        else urls = [imagesURL[0].uri,imagesURL[1].uri]


        if (Constants.manifest.version) urls.push(Constants.manifest.version)
        else urls.push("0")

        if (serverVersion) urls.push(serverVersion) // adding server version to the urls array
        else urls.push('production')

        getDiagnosis(urls)

        //console.log('&&&&&&', serverVersion)
      }, [imagesURL]);    


      useEffect(() => { // if i have disease i get it's products & write transaction - only for acne 1 2 3 , eczema, herpes, clean
        console.log('Disease = ', disease)
      if(disease && analysis){ 
          finishTransaction()
      }
      },[disease])
      
      useEffect(() => { // if i have disease i get it's products & write transaction - only for acne 1 2 3 , eczema, herpes, clean
        console.log('Override = ', override)
        },[override])
  

      const checkIfUserOrPro = () => { // First, check if it a user registered in Firebase Auth and his eail verified
        firebaseAuth.onAuthStateChanged(function(user) {
            if (user && user.emailVerified) {
                setUser(user)
                checkIfPro() // Second step is to check if user is pro. (still firebase user)
            } else {
                //console.log('User not logged in or not exists or not verified')
                setUser(null)
            }
        })}
    

      const checkIfPro = async() => {
        await AsyncStorage.getItem('is_pro', (err,result) => {
            if (result==null || result == 'no') {
              setIsPro(false)
              navigation.setParams({isPro : false})

            } else {
              setIsPro(true)
              navigation.setParams({isPro : true})
            }
          })
      }
    

      const openSimpleAlert = (title, msg) => { // alert for simple alerts - title + message + confirm button that dismisses alert
        setAlertTitle(title)
        setAlertMsg(msg)
        setAlertConfirmText('OK')
        setAlertShowCancelButton(false)
        setConfirmPressedCallback(() => () => {setShowAlert(false)})
        setShowAlert(true)
    }      


    const finishTransaction = async() => {
        var uid = await AsyncStorage.getItem('SR_Face_It_UID')
        const transaction = {
            // id : Date.now(),
            Device_ID: uid,
            user_mail: user ? user.email : '',
            date: new Date(),
            images: [imagesURL[0].uri, imagesURL[1] ? imagesURL[1].uri : null],
            isResult: maxPercent<51 ? false : true,
            result: disease,
            probability:maxPercent,
            pigmentation: stains,
            wrinkles: wrinkles,
            ageGroup: ageGroup,
            eyecare: eyecure,
            override: override ? override : null,
            override_comment: overrideReason? overrideReason : null,
            scars: isPro? scars : scarsUser,
            type: isPro? 'Pro' : 'User',
            environment: serverVersion,
            face_it_version: Constants.manifest.version,
            os: Platform.OS,
            device_model: Device.modelName,
            second_disease: analysis[1] ? analysis[1].name : null,
            second_disease_probability: analysis[1] ? analysis[1].probability : null,
            kid: last_transaction_id,
            rosacea_seborrhea: rosiUser
        }
        //FINISH TRANSACTION HERE WITH TRANSACTION CONTEXT
        //console.log('Writing transaction... ',transaction)
        storeTransaction(transaction)
    }
    const confirmOverride = () => {
        if (override === null) {
            openSimpleAlert('','You must choose disease')
            return;
        }

        setAgree(true)
        navigation.setParams({agree: true, forceAgreeNull:false, finishedOverride: true})
        setDiagnosisMsg(OVERRIDE_MSG)
        setFlex(0)
        
        //updating override in correct line in kibana db
        let data = {override_new_value: override, scars_new_value: scars, id:last_transaction_id ,reason: overrideReason}
        updateTransactionOverride(data)
    }

      
    const diseaseNameTranslator = (name) => {
        switch(name) {
            case 'acne_mild': 
                return i18n.t("Diseases.Acne1")
            case 'acne_medium':
                return i18n.t("Diseases.Acne2")
            case 'acne_severe':
                return i18n.t("Diseases.Acne3")
            case 'herpes':
                return i18n.t("Diseases.Herpes")
            case 'eczema': 
                return i18n.t("Diseases.Eczema")
            case 'clean':
                return i18n.t("Diseases.CleanFace")
            case 'pigmentation': 
                return i18n.t("Diseases.Stains")
            case 'wrinkles': 
                return i18n.t("Diseases.Wrinkles")
            case 'wrinkles_25_35':
                return i18n.t("Diseases.wrinkles2535")
            case 'wrinkles_35_45':
                return i18n.t("Diseases.wrinkles3545")
            case 'wrinkles_45_55':
                return i18n.t("Diseases.wrinkles4555")
            case 'wrinkles_55_above':
                return i18n.t("Diseases.wrinkles55+")
            case "Pigmentation and Wrinkles":
                return i18n.t("Diseases.PigmentationAndWrinkles")
            case "eyecure": 
                return i18n.t("Diseases.Eyecure")
            case "rosi":
                return i18n.t("Diseases.Rosi")
            default: // No disease here 
                return null
        }
    }

    const handleWrinklesStainsProductsClick = () => {
        if (wrinkles && ageGroup===null) {
            openSimpleAlert('Error',i18n.t("Alerts.MustAgeGroup"))
        }
        if (!isPro) {
            setShowProductsUser(true)
        }
        setAgree(true); 
        navigation.setParams({agree: true, forceAgreeNull:false})
        setFlex(0); 
        let data = 
        {
            wrinkles_new_value: wrinkles, 
            pigmentation_new_value: stains, 
            eyecare_new_value:eyecure, 
            age_group_new_value : ageGroup,
            id:last_transaction_id
        }
        updateTransactionCleanFace(data)
    }
    
        return (
            <ImageBackground
            source={require('../../../assets/app_background.png')}
            resizeMode='cover'
            style={styles.background}>
            <View style={styles.mainView}>

                    <ResultsLoader images={imagesURI} isLoading={ analysis===null ? true : false }/>
                    {/**************************** START OF CASE 2: ABOVE 80% ****************************/}

                    { analysis && (maxPercent >= 51) && disease !== 'no_face' &&        // CASE 2: ABOVE 80%, (not clean)
                    <View style={{flex:1}}>
                        <View style={styles.resultsView}>
                        { agree!==false && 
                        
                            <View style={{ alignItems:'center', 
                                           marginTop:  (override!==null || disease==='no_face') ? WINDOW_HEIGHT*0.1 + 20 
                                                                                                : isWeb ? 60 : 30, 
                                           justifyContent:'center',
                                           flex:(((disease ==='clean' && override === null) || 
                                                 (disease!=='clean' && override==='clean') || 
                                                 (override!==null) ||
                                                 (disease=='no_face')) && !isWeb)
                                                 ? 0 : isWeb ? 0.5 : 1}}>
                                {/* NOT CLEAN - TOP (TITLE + GRAPH + PERCENTAGE)  */}
                            {
                                (disease!=='clean' && disease!='no_face' && disease!='small_face' && override === null) || (override!==null && override!=='clean') || 
                                (override!==null && navigation.getParam('forceAgreeNull')) ?
                                <>
                                    <Text style={{fontSize:FONT_SIZE, fontFamily:'latoregular', color:'#1D202A'}}>
                                        { (diagnosisMsg!==OVERRIDE_MSG && !manualHerpes) ?   i18n.t("ResultPage.TheSysDiag") : manualHerpes ? i18n.t("ResultPage.TheSysDiag") : i18n.t("ResultPage.YouDiag")}
                                    </Text>
                                    <Text style={{fontFamily:'latobold', color:'#A9042B' , fontSize:FONT_SIZE*1.5}}>{override ? diseaseNameTranslator(override) : diseaseNameTranslator(disease)}</Text>
                                    { (diagnosisMsg !== OVERRIDE_MSG && (disease !== 'herpes' && secondDisease !== 'herpes')) &&
                                        <Text style={{fontSize:FONT_SIZE, fontFamily:'latoregular', color:'#1D202A'}}>
                                        {(disease !== 'herpes' && secondDisease !== 'herpes') && i18n.t("ResultPage.InProb")}
                                            <Text style={{color:'#A9042B', marginVertical:2, fontFamily:'latobold', fontSize:FONT_SIZE}}> 
                                            {" "}{maxPercent}%
                                            </Text>
                                        </Text>
                                    }
                                    { (diagnosisMsg !== OVERRIDE_MSG) && <AnalysisResultGraph analysis={analysis}/>}
                                </> : null
                            } 
                                {/* NOT CLEAN TOP END */}


                                {/* CLEAN - TOP */}
                            {
                                ((disease ==='clean' && override === null) || (disease!=='clean' && override==='clean')) &&
                                <View style={{marginTop: '15%', marginBottom:'7.5%'}}>
                                    <Text style={{fontSize:FONT_SIZE, fontFamily:'latoregular', color:'#000', textAlign:'center'}}>
                                            { (override === 'clean' || (stains || wrinkles || eyecure) && agree) ?  i18n.t("ResultPage.YouDecided") 
                                            : i18n.t("ResultPage.TheSysDiag")
                                            }
                                    </Text>

                                    <Text style={{fontFamily:'latoregular', color:'#A9042B' , fontSize:FONT_SIZE*1.35, flexWrap:'wrap', textAlign:'center'}}>
                                        { 
                                          (stains && wrinkles && agree) ? i18n.t("Diseases.PigmentationAndWrinkles")
                                        : (stains && agree)             ? i18n.t("Diseases.Stains")
                                        : (wrinkles && agree)           ? i18n.t("Diseases.Wrinkles")
                                        : (eyecure && agree)            ? i18n.t("Diseases.Eyecure")
                                        :                                 i18n.t("ResultPage.NoSkinLesions")
                                        }
                                    </Text>
                                    { (diagnosisMsg !== OVERRIDE_MSG && !((wrinkles || stains || eyecure) && agree===true)) && <AnalysisResultGraph analysis={analysis}/>}
                                    
                                </View>
                            }
                            </View>
                            
                        }
                            { /**************************** START OF CASE 2: ABOVE 80% - PRO ****************************/
                                isPro ? 
                                <View style={{flex:1, justifyContent:'space-around'}}>
                                    { agree===null || navigation.getParam('forceAgreeNull') ?

                                    /**************************** START OF CASE 2: ABOVE 80% - PRO - BEFORE PRO DECISION ****************************/
                                    <Animatable.View style={styles.proResponse}>
                                       { 
                                            (disease==='acne_medium' || disease==='acne_severe') &&
                                           <CheckBox title={i18n.t("ResultPage.ScarsMsg")} checked={scars} 
                                                center 
                                                iconRight
                                                checkedColor='#A9042B'
                                                uncheckedColor='#000'
                                                size={20}
                                                onPress={() => setScars(!scars)}
                                                containerStyle={{backgroundColor: 'transparent', borderColor:'transparent'}}
                                                textStyle={{color:'#000', fontWeight:'normal',fontSize: PR<=2 ? 14:18, alignSelf:'center'}}
                                                fontFamily='latoregular'
                                                titleProps={{textAlign:'center'}}
                                                  />
                                       }
                                       { (!stains && !wrinkles && !eyecure && disease!=='no_face' && disease!=='small_face')  &&
                                        <Animatable.View 
                                        style={styles.questionBox}>
                                            <Text style={styles.questionBoxTitle}>
                                                {i18n.t("ResultPage.ConfirmResult")}
                                            </Text>
                                            <Text style={{fontSize: PR<=2 ? 12:16, fontFamily:'latoregular', color:'#A9042B', marginHorizontal:15, marginVertical:5, textAlign: lang==='he' ? 'right' : 'left'}}>
                                                {i18n.t("ResultPage.SinceYouPro")}
                                            </Text>
                                            <View style={{flexDirection:'row', justifyContent: lang == 'en' ? 'flex-end' : 'flex-start'}}>
                                                <TouchableOpacity style={{backgroundColor:'transparent',
                                                                          borderRadius:50, borderWidth:3, borderColor:'#A9042B',
                                                                          height: WINDOW_HEIGHT*0.05, width:  WINDOW_HEIGHT*0.05, 
                                                                          justifyContent:'center', alignItems:'center',
                                                                          marginRight: lang == 'en' ? 10 : 0,
                                                                          marginLeft: lang == 'he' ? 20 : 0}}
                                                                    onPress={() => {setAgree(false);navigation.setParams({agree: false, forceAgreeNull:false}); setFlex(1);}}>
                                                    <Feather name="x" color="#A9042B" size={WINDOW_HEIGHT*0.03}	/>
                                                </TouchableOpacity>
                                                <TouchableOpacity style={{backgroundColor:'transparent',
                                                                          borderRadius:50, borderWidth:3, borderColor:'#A9042B',
                                                                          height: WINDOW_HEIGHT*0.05, width:  WINDOW_HEIGHT*0.05, 
                                                                          justifyContent:'center', alignItems:'center',
                                                                          marginRight: lang == 'en' ? 10 : 0,
                                                                          marginLeft: lang == 'he' ? 10 : 0}}
                                                                  onPress={() => {
                                                                      if(!stains && !wrinkles){
                                                                        setAgree(true);
                                                                        navigation.setParams({agree: true, forceAgreeNull:false})
                                                                        let data = {
                                                                            override_new_value: 'Accepted', 
                                                                            scars_new_value: scars, 
                                                                            id:last_transaction_id,
                                                                            reason: overrideReason
                                                                            }
                                                                        updateTransactionOverride(data)
                                                                        setFlex(0); 
                                                                      } else {
                                                                          // Something to do here if stains/wrinkles checked
                                                                      }
                                                                      }}>
                                                    <Feather name="check" color="#A9042B" size={WINDOW_HEIGHT*0.03}	/>
                                                </TouchableOpacity>
                                            </View>
                                        </Animatable.View>
                                    }
                                    { disease === 'clean' && 
                                        <View style={{flex :isWeb ? 0.5 : 1, }}>
                                            <View style={{justifyContent:'center'}}>
                                            <Text style={{color:'#A9042B', fontFamily:'latobold',fontSize:16,textAlign: lang === 'he' ? 'right' : 'left'}}>{i18n.t("ResultPage.DoYouSeeAny")}</Text>
                                                <View style={{flexDirection: lang === 'en'? 'row' : 'row-reverse', justifyContent:'center'}}>
                                                    <CheckBox title={i18n.t("ResultPage.StainsQuestion")} checked={stains} 
                                                        iconRight={lang==='en' ? true : false}
                                                        iconLeft={lang==='he' ? true : false}
                                                        onPress={() => setStains(!stains)}
                                                        disabled={ eyecure ? true : false}
                                                        checkedColor='#A9042B'
                                                        uncheckedColor={eyecure ? '#bababa' : '#000'}
                                                        size={18}
                                                        containerStyle={{backgroundColor: 'transparent', borderColor:'transparent',}}
                                                        textStyle={{color:eyecure ? '#bababa' : '#000', fontWeight:'normal',fontSize: PR<=2 ? 12:14, alignSelf:'center'}}
                                                        fontFamily='latoregular'
                                                        titleProps={{textAlign:'center'}}
                                                        />


                                                    <CheckBox title={ i18n.t("ResultPage.WrinklesQuestion")} checked={wrinkles}
                                                        iconRight={lang==='en' ? true : false}
                                                        iconLeft={lang==='he' ? true : false}
                                                        disabled={ eyecure ? true : false}
                                                        onPress={() => setWrinkles( !wrinkles )}
                                                        size={18}
                                                        checkedColor='#A9042B'
                                                        uncheckedColor={eyecure ? '#bababa' : '#000'}
                                                        containerStyle={{backgroundColor: 'transparent', borderColor:'transparent',}}
                                                        textStyle={{color:eyecure ? '#bababa' : '#000', fontWeight:'normal',fontSize: PR<=2 ? 12:14, alignSelf:'center'}}
                                                        fontFamily='latoregular'
                                                        titleProps={{textAlign:'center'}}
                                                        />
                                                </View>
                                                <View style={{ height: 1, width: '100%', borderRadius: 1, borderWidth: 1, borderColor: '#000', borderStyle: 'dashed' }} />
                                                <View style={{flexDirection: lang === 'en'? 'row' : 'row-reverse', justifyContent:'center'}}>
                                                    <CheckBox title={i18n.t("ResultPage.EyecureQuestion")} checked={eyecure} 
                                                                //   center={lang==='he' ? true : false}
                                                                iconRight={lang==='en' ? true : false}
                                                                iconLeft={lang==='he' ? true : false}
                                                                onPress={() => {
                                                                        setEyecure(!eyecure)
                                                                    }
                                                                }
                                                                disabled={ (stains||wrinkles) ? true : false}
                                                                checkedColor="#A9042B"
                                                                uncheckedColor={(stains||wrinkles) ? '#bababa' : '#000'}
                                                                size={18}
                                                                containerStyle={{backgroundColor: 'transparent', borderColor:'transparent',}}
                                                                textStyle={{color:(stains||wrinkles) ? '#bababa' : '#000', fontWeight:'normal',fontSize: PR<=2 ? 12:14, alignSelf:'center'}}
                                                                fontFamily='latoregular'
                                                                titleProps={{textAlign:'center'}}
                                                                />
                                                </View>
                                            </View>
                                        {   wrinkles &&
                                            <View style={{alignItems:'center'}}>
                                                <Text style={{color:'#A9042b', fontFamily:'latobold', fontSize:16, textAlign:'left'}}>{i18n.t("ResultPage.ChooseAgeGroup")}</Text>
                                                        <RadioButton.Group onValueChange={newValue => setAgeGroup(newValue)} value={ageGroup}>
                                                        <View style={{flexDirection: lang==='en' ? 'row' : 'row-reverse',alignItems:'center'}}>
                                                            <View style={{flexDirection: lang==='he'?'row':'row-reverse', alignItems:'center'}}>
                                                                <RadioButton.Android value="wrinkles_25_35" color="#A9042b" uncheckedColor="#000" />
                                                                <Text  style={styles.radioButtonText}>25-35</Text>
                                                            </View>
                                                            <View style={{flexDirection:lang==='he'?'row':'row-reverse',alignItems:'center'}}>
                                                                <RadioButton.Android value="wrinkles_35_45" color="#A9042b" uncheckedColor="#000" />
                                                                <Text style={styles.radioButtonText}>35-45</Text>
                                                            </View>
                                                            <View style={{flexDirection:lang==='he'?'row':'row-reverse',alignItems:'center'}}>
                                                                <RadioButton.Android value="wrinkles_45_55" color="#A9042b" uncheckedColor="#000"/>
                                                                <Text style={styles.radioButtonText}>45-55</Text>
                                                            </View>
                                                            <View style={{flexDirection:lang==='he'?'row':'row-reverse',alignItems:'center'}}>
                                                                <RadioButton.Android value="wrinkles_55_above" color="#A9042b" uncheckedColor="#000"/>
                                                                <Text style={styles.radioButtonText}>55+</Text>
                                                            </View>
                                                        </View>
                                                        </RadioButton.Group>

                                            </View>
                                        }
                                        { (stains || wrinkles || eyecure) &&
                                        <LinearGradient colors={['#A0002A', '#D31631']} 
                                        style={{borderRadius:6,justifyContent: 'center',alignItems:'center',alignSelf:'center',marginTop:20}}>
                                            <TouchableOpacity style={styles.gotoStainsWrinklesBtn} onPress={handleWrinklesStainsProductsClick}>
                                                <Text style={{color:'#fff',
                                                            fontFamily:'latobold', 
                                                            fontSize:PR<=2 ? 14:18, 
                                                            padding:5, 
                                                            textAlign:'center'}}>
                                                    { (stains && wrinkles)  ? i18n.t("ResultPage.gotoWrinklesPigmentation") 
                                                    : (stains && !wrinkles) ? i18n.t("ResultPage.gotoPigmentation")
                                                    : (!stains && wrinkles) ? i18n.t("ResultPage.gotoWrinkles")
                                                    : (eyecure)             ? i18n.t("ResultPage.gotoEyecure")
                                                    :                         ""}
                                                </Text>
                                            </TouchableOpacity>
                                        </LinearGradient>
                                        }
                                        </View>
                                        }

                                        


                                    </Animatable.View> 
                                    /**************************** END OF CASE 2: ABOVE 80% - PRO - BEFORE PRO DECISION ****************************/



                                    /**************************** START OF CASE 2: ABOVE 80% - PRO - AGREE ****************************/
                                    : (agree===true &&  disease!='no_face' && disease!='small_face') ? 
                                        <ProductsList 
                                                    disease={ 
                                                    (stains && wrinkles) ? diseaseNameTranslator('wrinkles')
                                                    : wrinkles ? diseaseNameTranslator('wrinkles')
                                                    : stains   ? diseaseNameTranslator('pigmentation')
                                                    : eyecure ? diseaseNameTranslator('eyecure')
                                                    : diseaseNameTranslator(disease)
                                                    } 
                                                    override={diseaseNameTranslator(override)} 
                                                    pro={isPro} 
                                                    showProductsForScars={scars}
                                                    showAlsoPigment={stains && wrinkles}
                                                    fromProtocols={false}
                                                    />
                                    /**************************** END OF CASE 2: ABOVE 80% - PRO - AGREE ****************************/




                                    /**************************** START OF CASE 2: ABOVE 80% - PRO - DISAGREE ****************************/
                                    : agree===false  &&

                                    <Animatable.View animation="fadeIn" duration={2000} 
                                                    style={{flex:1, alignItems:'center', justifyContent: 'space-evenly', marginHorizontal:10, marginTop:30}}>
                                             {!typing &&   <Text style={{fontFamily:'latoregular', alignSelf:'center', textAlign:'center', color: '#000', fontSize:WINDOW_HEIGHT*0.027}}>
                                                    {i18n.t("ProOverridePage.HaveAnother")}
                                                </Text>
                                             }
                                            {
                                                !typing && imagesURL.length == 2 ?
                                                <View style={{flexDirection:'row', justifyContent:'space-evenly'}}>
                                                        <Image source={{uri: imagesURL[0].uri}} style={styles.imageContainer} />
                                                        <Image source={{uri: imagesURL[1].uri}} style={styles.imageContainer}/>
                                                </View>
                                                
                                                : !typing && imagesURL.length == 1 ?
                                                <View style={{flexDirection:'row', justifyContent:'center'}}>
                                                        <Image source={{uri: imagesURL[0].uri}} style={styles.imageContainer}/>
                                                </View>

                                                : null
                                            }
                                    <View style={{ justifyContent:'space-around', padding:5, backgroundColor:'transparent', 
                                                   width: WINDOW_WIDTH*0.872, height: WINDOW_HEIGHT*0.38, borderTopRightRadius:50, 
                                                   borderBottomLeftRadius:50, marginBottom:15, borderColor:'#A9042B', borderWidth:3 }}>

                                        <Text style={{fontSize:PR<=2 ? 13:15, fontFamily:'latoregular', color:'#000', alignSelf:'center', marginBottom:10, marginHorizontal:10, textAlign: lang==='he' ? 'right' : 'left'}}>
                                            {i18n.t("ProOverridePage.TheSysDiag")} <Text style={{color:'#A9042B', fontFamily:'latobold'}}>{diseaseNameTranslator(disease)}.</Text> 
                                            {" "}{i18n.t("ProOverridePage.SelectCorrect")} 
                                        </Text>
                                        <View style={{alignSelf:'center', width:WINDOW_WIDTH*0.8, borderBottomWidth:2, borderBottomColor:'#000'}}>
                                            <Entypo name="triangle-down" color="#000" size={18} 
                                                    style={[{position:'absolute',top:9}, lang === 'en' && {right: 5}, lang ==='he' && {left:5}]} />
                                            <SelectPicker
                                                    onValueChange={(value) => {
                                                        if (value=='rosi'){
                                                            setOverride('acne_mild')
                                                        } else {
                                                            setOverride(value)
                                                        }
                                                    }}
                                                    doneButtonText= {i18n.locale === 'en'? "Done" : "סיום"}
                                                    style={{textAlign: lang==='he' ? 'right' : 'left'}}
                                                    onSelectedStyle={{color:'#000', fontFamily:'latoregular'}}
                                                    placeholder={i18n.t("ProOverridePage.ChooseDiseasePlaceHolder")}
                                                    placeholderStyle={{color: '#000', fontFamily:'latoregular', fontSize:PR<=2 ? 12:14, textAlign: 'left' }}
                                                    >
                                                    {allDiseases.filter((val) => val!==disease && val !== 'no_face' && val !== 'small_face').map((val, index) => 
                                                        (
                                                            <SelectPicker.Item label={diseaseNameTranslator(val)} value={val} key={index} />
                                                        )
                                                    )}

                                            </SelectPicker>
                                        </View>
                                        <TextInput autoCapitalize='none' 
                                                   autoCorrect={false} 
                                                   style={[styles.input, {textAlign: lang==='he' ? 'right' : 'left'}]}
                                                   placeholder={i18n.t("ProOverridePage.WriteExp")}
                                                   placeholderTextColor={'#000'}
                                                   placeholderStyle={{fontFamily:'latoregular',textAlign: lang==='he' ? 'right' : 'left'}}
                                                   returnKeyType='done'
                                                   onFocus={() => { if(Platform.OS === 'ios') setTyping(true)}}
                                                   onEndEditing={() => { if(Platform.OS === 'ios') setTyping(false)}}
                                                   onChangeText={ (text) => setOverrideReason(text) }
                                                   />
                                       { 
                                            (override==='acne_medium' || override==='acne_severe') &&
                                           <CheckBox title={i18n.t("ResultPage.ScarsMsg")} checked={scars} 
                                                  center 
                                                  iconRight
                                                  size={14}
                                                  checkedColor='#A9042B'
                                                  onPress={() => setScars(!scars)}
                                                  containerStyle={{alignself:'flex-start', backgroundColor: 'transparent', borderColor:'transparent'}}
                                                  uncheckedColor='#000'
                                                  textStyle={{color:'#000', fontWeight:'normal',fontSize: 14}}
                                                  fontFamily='latoregular'
                                                  />
                                       }

                                        <View style={{flexDirection:'row', justifyContent:'space-around', marginTop:10, width: WINDOW_WIDTH*0.85}}>
                                            <LinearGradient colors={['#A0002A', '#D31631']} 
                                                            style={{ borderRadius:6,justifyContent: 'center',alignItems:'center',
                                                                    alignSelf:'center',marginTop:15, width: WINDOW_WIDTH*0.345, 
                                                                    height: WINDOW_HEIGHT*0.065, marginHorizontal:5.  }}>
                                                <TouchableOpacity style={styles.btn} onPress={confirmOverride}>
                                                    <Text style={{color:'#fff',fontFamily:'latobold',fontSize:15,alignSelf:'center' }}>{i18n.t("ProOverridePage.ConfirmBtn")}</Text>
                                                </TouchableOpacity>
                                            </LinearGradient>
                                            <LinearGradient colors={['#bdc3c7', '#2c3e50']} 
                                                            style={{ borderRadius:6,justifyContent: 'center',alignItems:'center',
                                                                    alignSelf:'center',marginTop:15, width: WINDOW_WIDTH*0.345, 
                                                                    height: WINDOW_HEIGHT*0.065, marginHorizontal:5 }}>
                                                <TouchableOpacity style={styles.btn} onPress={() => {setAgree(null); navigation.setParams({agree: null, forceAgreeNull:true, finishedOverride: false}) ;setOverride(null);}}>
                                                    <Text style={{color:'#fff',fontFamily:'latobold',fontSize:15,alignSelf:'center' }}>{i18n.t("ProOverridePage.BackBtn")}</Text>
                                                </TouchableOpacity>
                                            </LinearGradient>
                                        </View>

                                    </View> 
                                    </Animatable.View>
                                    /**************************** END OF CASE 2: ABOVE 80% - PRO - DISAGREE ****************************/



                                    }
                                </View>
                                /**************************** END OF CASE 2: ABOVE 80% - PRO ****************************/


                                /**************************** START OF CASE 2: ABOVE 80% - REGULAR USER ****************************/
                                : 
                                <View style={{flex:1, width:'100%'}}>
                                {/* REGULAR USER - SECOND DISEASE = HERPES - QUESTION BEFORE PRODUCTS */}
                                {
                                    
                                    (secondDisease === 'herpes' || disease === 'herpes') ?
                                    <>
                                    { agree===null &&
                                    <Animatable.View 
                                        style={styles.questionBox}>
                                            <Text style={styles.questionBoxTitle}>
                                                { i18n.t("ResultPage.HerpesSecondDiseaseMsg") }
                                            </Text>
                                            <View style={{flexDirection:'row', justifyContent: lang == 'en' ? 'flex-end' : 'flex-start'}}>
                                                <TouchableOpacity style={{borderColor: '#A9042B', borderRadius:50,  borderWidth:3, backgroundColor:'transparent',
                                                                          height: WINDOW_HEIGHT*0.05, width:  WINDOW_HEIGHT*0.05, 
                                                                          justifyContent:'center', alignItems:'center',
                                                                          marginRight: lang == 'en' ? 10 : 0,
                                                                          marginLeft: lang == 'he' ? 20 : 0}}
                                                                    onPress={() => {setAgree(true); navigation.setParams({agree: true, forceAgreeNull:false}); setFlex(0)}}>
                                                    <Feather name="x" color="#A9042B" size={WINDOW_HEIGHT*0.03}	/>
                                                </TouchableOpacity>
                                                <TouchableOpacity style={{borderColor: '#A9042B', borderRadius:50,  borderWidth:3, backgroundColor:'transparent', 
                                                                          height: WINDOW_HEIGHT*0.05, width:  WINDOW_HEIGHT*0.05, 
                                                                          justifyContent:'center', alignItems:'center',
                                                                          marginRight: lang == 'en' ? 10 : 0,
                                                                          marginLeft: lang == 'he' ? 10 : 0}}
                                                                  onPress={() => {
                                                                          setDisease('herpes')                                                                        
                                                                          setAgree(true)
                                                                          navigation.setParams({agree: true, forceAgreeNull:false})
                                                                          setFlex(0)
                                                                          setManualHerpes(true)
                                                                      }}
                                                                      >
                                                    <Feather name="check" color="#A9042B" size={WINDOW_HEIGHT*0.03}	/>
                                                </TouchableOpacity>
                                            </View>
                                        </Animatable.View>
                                    }
                                        
                                        { // PRODUCTS - USER - SECOND DISEASE HERPES
                                            agree &&
                                            <ProductsList disease={diseaseNameTranslator(disease)} 
                                                          pro={isPro} 
                                                          override={null} 
                                                          showProductsForScars={scarsUser} 
                                                          showAlsoPigment={false}
                                                          fromProtocols={false}  
                                                          showCosList={true}  
                                                          />  
                                        }

                                    </>
                                    :

                                    (disease==='acne_medium' || disease==='acne_severe') && !showProductsUser ?
                                    <>
                                    {/* REGULAR USER - ACNE2/3 - SCARS + ROSI QUESTION BEFORE PRODUCTS */}
                                    
                                        <CheckBox title={i18n.t("ResultPage.ScarsMsg")} checked={scarsUser} 
                                                    center 
                                                    iconRight
                                                    checkedColor='#A9042B'
                                                    uncheckedColor='#000'
                                                    size={20}
                                                    onPress={() => {setScarsUser(!scarsUser);}}
                                                    containerStyle={{backgroundColor: 'transparent', borderColor:'transparent', alignSelf:'center'}}
                                                    textStyle={{color:'#000', fontWeight:'normal',fontSize: PR<=2 ? 14:18, alignSelf:'center'}}
                                                    fontFamily='latoregular'
                                                    titleProps={{textAlign:'center'}}
                                        />
                                        {/* <View style={{ height: 1, width: '80%', borderRadius: 1, borderWidth: 1, borderColor: '#d2d2d2', borderStyle: 'dashed', alignSelf:'center' }} /> */}
                                        <CheckBox title={i18n.t("ResultPage.RosiMessage")} checked={rosiUser} 
                                                    center 
                                                    iconRight
                                                    checkedColor='#A9042B'
                                                    uncheckedColor='#000'
                                                    size={20}
                                                    onPress={() => {setRosiUser(!rosiUser);}}
                                                    containerStyle={{backgroundColor: 'transparent', borderColor:'transparent', alignSelf:'center', textAlign:'center'}}
                                                    textStyle={{color:'#000', fontWeight:'normal',fontSize: PR<=2 ? 14:18, alignSelf:'center', textAlign:'center'}}
                                                    fontFamily='latoregular'
                                                    titleProps={{alignSelf:'center', textAlign:'center'}}
                                        />
                                        <LinearGradient colors={['#A0002A', '#D31631']} 
                                                        style={{borderRadius:6,justifyContent: 'center',alignItems:'center',alignSelf:'center',marginTop:20}}>
                                            <TouchableOpacity style={styles.gotoProductsBtn} 
                                                            onPress={ () => {
                                                                let data = {
                                                                    override_new_value: null, 
                                                                    scars_new_value: scarsUser, 
                                                                    id:last_transaction_id,
                                                                    reason: overrideReason
                                                                }
                                                                updateTransactionOverride(data)
                                                                setShowProductsUser(true)
                                                                }
                                                                }>
                                                <Text style={{color:'#fff',fontFamily:'latoregular', fontSize: isComputerWeb? 18 : 16}}>
                                                    NEXT
                                                </Text>
                                            </TouchableOpacity>
                                        </LinearGradient>
                                        {
                                            //PRODUCTS LIST FOR USER - ACNE 2/3 - AFTER MARKING SCARS/NO SCARS or ROSI/NO ROSI
                                            showProductsUser &&
                                            <ProductsList disease={diseaseNameTranslator(disease)} 
                                                          pro={isPro} 
                                                          override={null} 
                                                          showProductsForScars={scarsUser} 
                                                          showAlsoPigment={false}
                                                          fromProtocols={false}
                                                          showCosList={true}  
                                                          rosiUser={rosiUser}
                                                          />  
                                        }
                                    {/* REGULAR USER ACNE 2/3 SCARS - END */}
                                    
                                        {/* REGULAR USER - CLEAN */}
                                    </> 
                                        : (disease === 'clean' && !showProductsUser) ? 
                                        <>
                                            <View style={{justifyContent:'center', marginVertical: !isWeb ? 15 : 0}}>
                                                <Text style={{color:'#A9042B', fontFamily:'latobold',fontSize:16,textAlign: lang === 'he' ? 'right' : 'left'}}>{i18n.t("ResultPage.DoYouSeeAny")}</Text>
                                                <View style={{flexDirection: lang === 'en'? 'row' : 'row-reverse', justifyContent:'center'}}>
                                                    <CheckBox title={i18n.t("ResultPage.StainsQuestion")} checked={stains} 
                                                        center 
                                                        iconRight
                                                        checkedColor='#A9042B'
                                                        uncheckedColor={eyecure ? '#bababa' : '#000'}
                                                        size={20}
                                                        containerStyle={{backgroundColor: 'transparent', borderColor:'transparent', alignSelf:'center'}}
                                                        textStyle={{color:eyecure ? '#bababa' : '#000', fontWeight:'normal',fontSize: PR<=2 ? 14:18, alignSelf:'center'}}
                                                        fontFamily='latoregular'
                                                        titleProps={{textAlign:'center'}}
                                                        onPress={() => setStains(!stains)}
                                                        disabled={ eyecure ? true : false}
                                                        />
                                                    <CheckBox title={i18n.t("ResultPage.WrinklesQuestion")} checked={wrinkles}
                                                        center 
                                                        iconRight
                                                        checkedColor='#A9042B'
                                                        uncheckedColor={eyecure ? '#bababa' : '#000'}
                                                        size={20}
                                                        containerStyle={{backgroundColor: 'transparent', borderColor:'transparent', alignSelf:'center'}}
                                                        textStyle={{color:eyecure ? '#bababa' : '#000', fontWeight:'normal',fontSize: PR<=2 ? 14:18, alignSelf:'center'}}
                                                        fontFamily='latoregular'
                                                        titleProps={{textAlign:'center'}}
                                                        disabled={ eyecure ? true : false}
                                                        onPress={() => setWrinkles(!wrinkles)}
                                                        />
                                                </View>
                                                <View style={{ height: 1, width: '100%', borderRadius: 1, borderWidth: 1, borderColor: '#000', borderStyle: 'dashed' }} />
                                                <View style={{flexDirection: lang === 'en'? 'row' : 'row-reverse', justifyContent:'center'}}>
                                                    <CheckBox title={i18n.t("ResultPage.EyecureQuestion")} checked={eyecure} 
                                                            center 
                                                            iconRight
                                                            checkedColor='#A9042B'
                                                            uncheckedColor={(stains||wrinkles) ? '#bababa' : '#000'}
                                                            size={20}
                                                            disabled={(stains||wrinkles) ? true : false}
                                                            containerStyle={{backgroundColor: 'transparent', borderColor:'transparent', alignSelf:'center'}}
                                                            textStyle={{color: (stains||wrinkles) ? '#bababa' : '#000', fontWeight:'normal',fontSize: PR<=2 ? 14:18, alignSelf:'center'}}
                                                            fontFamily='latoregular'
                                                            titleProps={{textAlign:'center'}}
                                                            onPress={() => {
                                                                    setEyecure(!eyecure)
                                                                }}
                                                            />
                                                </View>
                                            </View>
                                            {   wrinkles &&
                                            <View style={{alignItems:'center'}}>
                                                <Text style={{color:'#A9042B', fontFamily:'latobold', fontSize:16}}>{i18n.t("ResultPage.ChooseAgeGroup")}</Text>
                                                        <RadioButton.Group onValueChange={newValue => setAgeGroup(newValue)} value={ageGroup}>
                                                        <View style={{flexDirection: lang==='en' ? 'row' : 'row-reverse',alignItems:'center'}}>
                                                            <View style={{flexDirection: lang==='he'?'row':'row-reverse', alignItems:'center'}}>
                                                                <RadioButton.Android value="wrinkles_25_35" color="#A9042B" uncheckedColor="#000" />
                                                                <Text  style={styles.radioButtonText}>25-35</Text>
                                                            </View>
                                                            <View style={{flexDirection:lang==='he'?'row':'row-reverse',alignItems:'center'}}>
                                                                <RadioButton.Android value="wrinkles_35_45" color="#A9042B" uncheckedColor="#000" />
                                                                <Text style={styles.radioButtonText}>35-45</Text>
                                                            </View>
                                                            <View style={{flexDirection:lang==='he'?'row':'row-reverse',alignItems:'center'}}>
                                                                <RadioButton.Android value="wrinkles_45_55" color="#A9042B" uncheckedColor="#000"/>
                                                                <Text style={styles.radioButtonText}>45-55</Text>
                                                            </View>
                                                            <View style={{flexDirection:lang==='he'?'row':'row-reverse',alignItems:'center'}}>
                                                                <RadioButton.Android value="wrinkles_55_above" color="#A9042B" uncheckedColor="#000"/>
                                                                <Text style={styles.radioButtonText}>55+</Text>
                                                            </View>
                                                        </View>
                                                        </RadioButton.Group>
                                            </View>
                                        }
                                        { 
                                        <LinearGradient colors={['#A0002A', '#D31631']} 
                                                    style={{borderRadius:6,justifyContent: 'center',alignItems:'center',alignSelf:'center',marginTop:20}}>

                                            <TouchableOpacity style={styles.gotoProductsBtn} onPress={() => {
                                            if (stains || wrinkles || eyecure) { 
                                                handleWrinklesStainsProductsClick()   
                                            } else {
                                                finishTransaction()
                                                setShowProductsUser(true)
                                            } 
                                            }
                                        }>
                                            <Text style={{color:'#fff',
                                                          fontFamily:'latobold', 
                                                          fontSize: isComputerWeb? 18 : 16, 
                                                          padding:5, 
                                                          textAlign:'center'}}>
                                                { (stains && wrinkles)  ? i18n.t("ResultPage.gotoWrinklesPigmentation") 
                                                : (stains && !wrinkles) ? i18n.t("ResultPage.gotoPigmentation")
                                                : (!stains && wrinkles) ? i18n.t("ResultPage.gotoWrinkles")
                                                : (eyecure)             ? i18n.t("ResultPage.gotoEyecure")
                                                :                         i18n.t("ResultPage.UserContinueToProducts")}
                                            </Text>
                                        </TouchableOpacity>
                                        </LinearGradient>
                                        }

                                        {/* REGULAR USER - CLEAN - END*/}
                                        </>

                                            
                                        
                                        // REGULAR USER - ECZEMA
                                        : (disease === 'eczema' && !showProductsUser) ? 
                                        <>
                                        <CheckBox title={i18n.t("ResultPage.RosiMessage")} checked={rosiUser} 
                                                    center 
                                                    iconRight
                                                    checkedColor='#A9042B'
                                                    uncheckedColor='#000'
                                                    size={20}
                                                    onPress={() => {setRosiUser(!rosiUser);}}
                                                    containerStyle={{backgroundColor: 'transparent', borderColor:'transparent'}}
                                                    textStyle={{color:'#000', fontWeight:'normal',fontSize: PR<=2 ? 14:18, alignSelf:'center'}}
                                                    fontFamily='latoregular'
                                                    titleProps={{textAlign:'center'}}
                                        />
                                        <LinearGradient colors={['#A0002A', '#D31631']} 
                                        style={{borderRadius:6,justifyContent: 'center',alignItems:'center',alignSelf:'center',marginTop:20}}>
                                            <TouchableOpacity style={styles.gotoProductsBtn} 
                                                            onPress={ () => {
                                                                let data = {
                                                                    override_new_value: null, 
                                                                    scars_new_value: scarsUser, 
                                                                    id:last_transaction_id,
                                                                    reason: overrideReason
                                                                    }
                                                                updateTransactionOverride(data)
                                                                setShowProductsUser(true)
                                                                //console.log('ROSI = ', rosiUser)
                                                                }
                                                                }>
                                                <Text style={{color:'#fff',fontFamily:'latoregular', fontSize: isComputerWeb? 18 : 16}}>
                                                    {i18n.t("ResultPage.UserContinueToProducts")}
                                                </Text>
                                            </TouchableOpacity>
                                        </LinearGradient>
                                        {
                                            //PRODUCTS LIST FOR USER - ECZEMA - AFTER MARKING ROSI/NO ROSI
                                            showProductsUser &&
                                            <ProductsList disease={diseaseNameTranslator(disease)} 
                                                          pro={isPro} 
                                                          showProductsForScars={scarsUser} 
                                                          showAlsoPigment={false}
                                                          fromProtocols={false}
                                                          showCosList={true}  
                                                          rosiUser={rosiUser}
                                                          />  
                                        }
                                        {/* // REGULAR USER - ECZEMA */}
                                        </>
                                        
                                        :
                                        
                                        <ProductsList 
                                                    disease={ 
                                                    (stains && wrinkles) ? diseaseNameTranslator(ageGroup)
                                                    : wrinkles ? diseaseNameTranslator(ageGroup)
                                                    : stains   ? diseaseNameTranslator('pigmentation')
                                                    : eyecure ? diseaseNameTranslator('eyecure')
                                                    : diseaseNameTranslator(disease)}
                                                    override={diseaseNameTranslator(override)} 
                                                    pro={isPro} 
                                                    showProductsForScars={scarsUser}
                                                    showAlsoPigment={stains && wrinkles}
                                                    fromProtocols={false}
                                                    showCosList={true}
                                                    rosiUser={rosiUser}
                                                    />
                                    
                                }
                                </View>

                                /**************************** END OF CASE 2: ABOVE 80% - REGULAR USER ****************************/

                            } 
                            
                            { 
                                (   (disease!=='no_face' && disease!=='small_face') 

                                && 

                                !isWeb 

                                && 

                                ( showProductsUser || (agree === true && !navigation.getParam('forceAgreeNull') )  )    )
                                
                                &&
                                
                                
                                <ConsultFooter/>
                            }
                        </View>
                    </View>
                    }
                    {/**************************** END OF CASE 2: ABOVE 80% ****************************/}


                    
                    { /**************************** START OF CASE 1: BELOW 80% (FOR BOTH PRO & REGULAR USER) ****************************/
                        (analysis && maxPercent && maxPercent < 51) && 
                        <View style={styles.noResult}>
                            <Text style={{fontFamily:'latobold', color: '#000', fontSize: WINDOW_HEIGHT*0.032, textAlign:'center'}}>{i18n.t("ResultPage.Uncertainity1")}</Text>
                            <Image source={{uri: 'https://firebasestorage.googleapis.com/v0/b/face-it-29c11.appspot.com/o/redbell.png?alt=media&token=f8d13714-70c3-45e3-b885-4023c481a8c4'}} style={{width:WINDOW_HEIGHT*0.216,height:WINDOW_HEIGHT*0.216, alignSelf:'center'}}/>
                            <Text style = {{fontFamily:'latoregular', color:'#A9042B', fontSize: WINDOW_HEIGHT*0.032*0.7, alignSelf:'center'}}>{i18n.t("ResultPage.Uncertainity2")}</Text>
                            <TouchableOpacity style={{width: WINDOW_WIDTH*0.77, height: WINDOW_HEIGHT*0.07, backgroundColor:'#A9042B', borderRadius:30, justifyContent:'center', alignItems:'center'}}
                            onPress={() => {navigation.navigate('UPLOAD')}}>
                                <Text style={{color:'#000', fontFamily:'latoregular', fontSize:WINDOW_HEIGHT*0.06*0.3, textAlign:'center'}}>{i18n.t("ResultPage.Uncertainity3")}</Text>
                            </TouchableOpacity>
                            <ConsultFooter/>
                        </View>
                        /**************************** END OF CASE 1: BELOW 80% (FOR BOTH PRO & REGULAR USER) ****************************/
                    } 

                    { /**************************** START OF CASE 3: NO FACE (FOR BOTH PRO & REGULAR USER) ****************************/
                        (analysis && disease==='no_face')  && 
                                                
                            <View style={styles.noResult}>
                                <Text style={{fontFamily:'latobold', color: '#000', fontSize: WINDOW_HEIGHT*0.032, textAlign:'center'}}>{i18n.t("ResultPage.NoFace1")}</Text>
                                <Image source={{uri: 'https://firebasestorage.googleapis.com/v0/b/face-it-29c11.appspot.com/o/redbell.png?alt=media&token=f8d13714-70c3-45e3-b885-4023c481a8c4'}}
                                 style={{width:WINDOW_HEIGHT*0.216,height:WINDOW_HEIGHT*0.216, alignSelf:'center'}}/>
                                <Text style = {{fontFamily:'latoregular', color:'#A9042B', fontSize: WINDOW_HEIGHT*0.032*0.6, alignSelf:'center', textAlign:'center'}}>{i18n.t("ResultPage.NoFace2")}</Text>
                                <TouchableOpacity style={{width: WINDOW_WIDTH*0.77, height: WINDOW_HEIGHT*0.07, backgroundColor:'#A9042B', borderRadius:30, justifyContent:'center', alignItems:'center'}}
                                        onPress={() => {navigation.navigate('UPLOAD')}}>
                                    <Text style={{color:'#fff', fontFamily:'latoregular', fontSize:WINDOW_HEIGHT*0.06*0.3, textAlign:'center'}}>{i18n.t("ResultPage.NoFace3")}</Text>
                                </TouchableOpacity>
                                <ConsultFooter/>
                            </View>
                        /**************************** END OF CASE 3:  NO FACE (FOR BOTH PRO & REGULAR USER) ****************************/
                    } 
                    { 
                        /**************************** START OF CASE 4: SMALL FACE (FOR BOTH PRO & REGULAR USER) ****************************/
                        (analysis && disease==='small_face') && 
                        <View style={styles.noResult}>
                            <Text style={{fontFamily:'latobold', color: '#000', fontSize: WINDOW_HEIGHT*0.032, textAlign:'center'}}>{i18n.t("ResultPage.SmallFace1")}</Text>
                            <Image source={{uri: 'https://firebasestorage.googleapis.com/v0/b/face-it-29c11.appspot.com/o/redbell.png?alt=media&token=f8d13714-70c3-45e3-b885-4023c481a8c4'}} style={{width:WINDOW_HEIGHT*0.216,height:WINDOW_HEIGHT*0.216, alignSelf:'center'}}/>
                            <Text style = {{fontFamily:'latoregular', color:'#A9042B', fontSize: WINDOW_HEIGHT*0.032*0.5, alignSelf:'center', textAlign:'center'}}>{i18n.t("ResultPage.SmallFace2")}</Text>
                            <TouchableOpacity style={{width: WINDOW_WIDTH*0.77, height: WINDOW_HEIGHT*0.07, backgroundColor:'#A9042B', borderRadius:30, justifyContent:'center', alignItems:'center'}}
                            onPress={() => {navigation.navigate('UPLOAD')}}>
                                <Text style={{color:'#000', fontFamily:'latoregular', fontSize:WINDOW_HEIGHT*0.06*0.3, textAlign:'center'}}>{i18n.t("ResultPage.SmallFace3")}</Text>
                            </TouchableOpacity>
                            <ConsultFooter/>
                        </View>
                        /**************************** END OF CASE 4:  NO FACE (FOR BOTH PRO & REGULAR USER) ****************************/
                    } 
                    
            </View>
            <AwesomeAlert
                    show={showAlert}
                    title={alertTitle}
                    message={alertMsg}
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showConfirmButton={true}
                    showCancelButton={alertShowCancelButton}
                    cancelText={alertCancelText}
                    confirmText={alertConfirmText}
                    confirmButtonColor="#A9042B"
                    cancelButtonColor="#A9042B"
                    onDismiss={() => {setShowAlert(false);}}
                    onCancelPressed={cancelPressedCallback}
                    onConfirmPressed={confirmPressedCallback}
                    actionContainerStyle={{flexDirection: 'row-reverse'}}/>

            </ImageBackground>
        );
    
}


const styles = StyleSheet.create({
    mainView: {
        justifyContent:'space-evenly',
        alignItems: 'center',
        flex: 1,
    },

    resultsView:{
        flex:1,
        justifyContent:'space-around',
        alignItems:'center',
        marginTop:5,
        marginBottom:8,
        // backgroundColor:
    },

    proResponse: {
        // justifyContent:'space-around',
        flex:1,
        alignItems:'center',
    },
        headerRight: {
            flexDirection:'row',
            marginHorizontal: 5,
            justifyContent:'center',
            width: WINDOW_WIDTH/3,
            height: WINDOW_HEIGHT * headerRatio * 0.6
        },
        button: {
            // borderColor: '#ececec',
            // borderWidth: 2,
            borderRadius: 30,
            width:'40%',
            height: 50,
            marginVertical:5,
            // marginHorizontal:15,
            justifyContent:'center',
            alignItems:'center',
        },
    
        buttonText:{
            color: '#514e8a',
            fontSize:FONT_SIZE,
            fontWeight:'600'
        },
    

        btn: {
            marginHorizontal:5,
            borderRadius: 30,
            padding:8,
            // marginTop: 10,
            justifyContent:'center',
            // width: WINDOW_WIDTH*0.26,
            // height: WINDOW_HEIGHT*0.065,
        },

        imageContainer: {
            padding:10,
            marginHorizontal:10,
            height: WINDOW_HEIGHT*0.25,
            width: WINDOW_WIDTH*0.40,
            borderRadius: 30,
            resizeMode:'contain'
            },
    
    
            imageIcon: {
                paddingLeft:2,
                flex:1,
                justifyContent:'center',
                height:25,
                width: 25,
                backgroundColor:'#1f1f1f',
                opacity: 0.5,
                borderRadius: 20
            },
            zoomInIcon: {
                position: "absolute",
                bottom: 10,
                right: 20,
            },

              
            input: {
                marginTop:10,
                width: WINDOW_WIDTH*0.80, 
                borderBottomColor:'#000',
                borderBottomWidth:2,
                color:'#000',
                fontFamily:'latoregular',
                padding:3,
                fontSize:PR<=2 ? 12:14,
                alignSelf:'center',

            },
            noResult:{
                width: WINDOW_WIDTH*0.872, 
                height: WINDOW_HEIGHT*0.755,
                // backgroundColor:'#1D202A',
                // borderTopRightRadius:60,
                // borderBottomLeftRadius:60,
                paddingHorizontal:15,
                paddingVertical:25,
                justifyContent:'space-evenly',
                alignSelf:'center',
                // backgroundColor:'yellow'
                

            },

            gotoProductsBtn:{
                flexDirection:'row',
                borderRadius: 30,
                justifyContent: 'center',
                alignItems:'center',
                alignSelf:'center',    
                width: WINDOW_WIDTH * 0.65,
                height: WINDOW_HEIGHT * 0.07,
            
            },
            gotoStainsWrinklesBtn:{
                borderRadius: 30,
                width: WINDOW_WIDTH*0.6,
                height: WINDOW_HEIGHT*0.077,
                padding:5,
                justifyContent:'center',
                alignSelf:'center'
            },

            radioButtonText:{
                color:'#000',
                fontFamily:'latoregular',
                fontSize: 14,
                
            },
            background: {
                width: '100%',
                height: '100%',
                flex: 1,
                position: 'absolute',
                top: 0,
                left: 0
            },

            questionBox: {
                backgroundColor:'transparent', 
                borderColor:'#A9042B',
                borderWidth:3, 
                width: WINDOW_WIDTH*0.87,
                height: WINDOW_HEIGHT*0.21, 
                borderTopRightRadius:50, 
                borderBottomLeftRadius:50, 
                justifyContent:'space-evenly', 
                marginVertical:15
            },
            questionBoxTitle: {
                fontSize: PR<=2 ? 14:18, 
                fontFamily:'latobold', 
                color:'#A9042B', 
                paddingHorizontal:15, 
                textAlign: 'left'
            }
            
})


ResultsScreen.navigationOptions = ({navigation}) =>  {
    return {   
        headerLeft: () =>
        <TouchableOpacity onPress={() => {
            var isPro = navigation.getParam('isPro')
            if (isPro) {
                var agree = navigation.getParam('agree')
                var forceAgreeNull = navigation.getParam('forceAgreeNull')
                var finishedOverride = navigation.getParam('finishedOverride')
                if (forceAgreeNull || agree === null) { // didnt press confirm/override yet
                    navigation.navigate('UPLOAD',{resetPhotos: false})
                } else if (agree === true && !finishedOverride)  { // pressed V or checked eyecare/stains/wrinkles
                    navigation.setParams({forceAgreeNull: true})
                } else if (agree === false) { // pressed X
                    // navigation.setParams({forceAgreeNull: true})
                } else if (finishedOverride) { // confirmed override
                    navigation.navigate('UPLOAD',{resetPhotos: false})
                }
            } else { // Regular user will go to main page always
                navigation.navigate('UPLOAD',{resetPhotos: false})
            }
        }}>
                <FontAwesome5 name="chevron-left" color= {navigation.getParam('agree') === false ? 'transparent' : "#A9042B"} size={26} style={{marginLeft: 15, alignSelf:'center'}} />
        </TouchableOpacity>
          }
        }


export default ResultsScreen