import React, { useContext,useEffect,useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Context as AuthContext } from '../../context/AuthContext';

const ResolveAuthScreen = ({navigation}) => {
  const { tryLocalSignin,tryLocalSigninPro } = useContext(AuthContext);
  const [isUserDone, setIsUserDone] = useState(false)
  useEffect(() => {
    tryLocalSignin().then( result => {
       setIsUserDone(true)
    }
    )
     
  }, []);

  useEffect(() => {
    if(isUserDone){
    tryLocalSigninPro().then( () => {
       navigation.navigate('analyzerFlow')
    }
    )
  }  
  }, [isUserDone]);


  return null;
};

export default ResolveAuthScreen;
