import React from 'react';
import {StyleSheet, Text} from 'react-native';


const PrivacyPolicyTextEnglish = () => {
    return (
        <>
            <Text style={styles.paragraph}>Protecting your private information is our priority. This Statement of Privacy applies to FaceIt, and SMUK Brands, LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to SMUK Brands, LLC include www.srcosmeticsusa.com and SMUK. The SMUK application is a tool that is powered by artifical intelligence and designed to recognize and diagnose skin conditions via the use of a photograph. By using the SMUK application, you consent to the data practices described in this statement.</Text>
            <Text style={styles.subtitle}>Collection of your Personal Information </Text>
            <Text style={styles.paragraph}>In order to better provide you with products and services offered, SMUK may collect personally identifiable information, such as your:</Text>
            <Text style={styles.subparagraph}>• First and Last Name</Text>
            <Text style={styles.subparagraph}>• Mailing Address</Text>
            <Text style={styles.subparagraph}>• E-mail Address</Text>
            <Text style={styles.subparagraph}>• Phone Number</Text>
            <Text style={styles.subparagraph}>• Job Title</Text>
            <Text style={styles.subparagraph}>• Federal and State Licensing Information</Text>
            <Text style={styles.paragraph}>If you purchase SMUK's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.</Text>
            <Text style={styles.paragraph}>SMUK may also collect anonymous demographic information, which is not unique to you, such as your:</Text>
            <Text style={styles.subparagraph}>• Age</Text>
            <Text style={styles.subparagraph}>•	Gender</Text>
            <Text style={styles.paragraph}>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</Text>
            <Text style={styles.subtitle}>Use of your Personal Information</Text>
            <Text style={styles.paragraph}>SMUK collects and uses your personal information to operate and deliver the services you have requested.</Text>
            <Text style={styles.paragraph}>SMUK may also use your personally identifiable information to inform you of other products or services available from SMUK and its affiliates.</Text>
            <Text style={styles.subtitle}>Sharing Information with Third Parties</Text>
            <Text style={styles.paragraph}>SMUK does not sell, rent or lease its customer lists to third parties.</Text>
            <Text style={styles.paragraph}>SMUK may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to SMUK, and they are required to maintain the confidentiality of your information.</Text>
            <Text style={styles.paragraph}>SMUK may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on SMUK or the site; (b) protect and defend the rights or property of SMUK; and/or (c) act under exigent circumstances to protect the personal safety of users of SMUK, or the public.</Text>
            <Text style={styles.subtitle}>Right to Deletion</Text>
            <Text style={styles.paragraph}>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</Text>
            <Text style={styles.paragraph}>• Delete your personal information from our records; and</Text>
            <Text style={styles.paragraph}>• Direct any service providers to delete your personal information from their records.</Text>
            <Text style={styles.paragraph}>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:</Text>
            <Text style={styles.paragraph}>• Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;</Text>
            <Text style={styles.paragraph}>• Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;</Text>
            <Text style={styles.paragraph}>• Debug to identify and repair errors that impair existing intended functionality;</Text>
            <Text style={styles.paragraph}>• Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</Text>
            <Text style={styles.paragraph}>• Comply with the California Electronic Communications Privacy Act;</Text>
            <Text style={styles.paragraph}>• Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;</Text>
            <Text style={styles.paragraph}>• Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</Text>
            <Text style={styles.paragraph}>• Comply with an existing legal obligation; or</Text>
            <Text style={styles.paragraph}>• Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</Text>
            <Text style={styles.subtitle}>Children Under Thirteen</Text>
            <Text style={styles.paragraph}>SMUK does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this application.</Text>
            <Text style={styles.subtitle}>E-mail Communications</Text>
            <Text style={styles.paragraph}>From time to time, SMUK may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from SMUK or click on a link therein.</Text>
            <Text style={styles.paragraph}>If you would like to stop receiving marketing or promotional communications via email from SMUK, you may opt out of such communications by clicking on the UNSUBSCRIBE button.</Text>
            <Text style={styles.subtitle}>Changes to this Statement</Text>
            <Text style={styles.paragraph}>SMUK reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our application, and/or by updating any privacy information. Your continued use of the application and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</Text>
            <Text style={styles.subtitle}>Contact Information</Text>
            <Text style={styles.paragraph}>SMUK welcomes your questions or comments regarding this Statement of Privacy. If you believe that SMUK has not adhered to this Statement, please contact SMUK at:</Text>
            <Text style={styles.paragraph}>SMUK Brands, LLC PO Box 66602, Seattle, Washington 98166</Text>
            <Text style={styles.paragraph}>Email Address: info@srcosmeticsusa.com</Text>
            <Text style={styles.paragraph}>Telephone number:833-432-2348</Text>
            <Text style={styles.paragraph}>Effective as of February 01, 2022</Text>

        </>
    );
}

const styles = StyleSheet.create({
    subtitle:{
        fontFamily:'latobold', 
        color:'#000', 
        fontSize:18,
        paddingHorizontal:15,
        paddingVertical:5   
    },
    paragraph:{
        fontFamily:'latoregular', 
        color:'#000', 
        fontSize:14,
        paddingHorizontal:15,
        paddingVertical:5   

    },
    subparagraph: {
        fontFamily:'latoregular', 
        color:'#000', 
        fontSize:14,
        paddingHorizontal:25,

    }
})

export default PrivacyPolicyTextEnglish;