import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, Text, Dimensions, TouchableOpacity, TextInput, Platform, ScrollView, Alert} from 'react-native';
import { Overlay } from 'react-native-elements'
import TermsTextEnglish from './TermsTextEnglish.js'
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import {Context as AuthContext} from '../context/AuthContext'
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import * as Animatable from 'react-native-animatable';
import { navigate } from '../navigationRef';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NavigationEvents } from 'react-navigation';
import {Context as TransactionContext} from '../context/TransactionContext'
import * as Device from 'expo-device';
import { LinearGradient } from 'expo-linear-gradient';
import {CheckBox} from 'react-native-elements'
import AwesomeAlert from 'react-native-awesome-alerts';
import {MaterialCommunityIcons} from 'react-native-vector-icons'

const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const isWeb = Platform.OS === 'web'
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
const WINDOW_HEIGHT = Dimensions.get('window').height;
// const WINDOW_WIDTH = Dimensions.get('window').width;

i18n.translations = {
    en: english,
    he: hebrew
}

const Signup = ({navigation}) => {
    const [email,setEmail] = useState(null)
    const [name,setName] = useState(' ')
    const [password,setPassword] = useState(null)
    const {state: {errorMsg}, signout, set_pro, set_pro_name, signup, clearErrorMsg} = useContext(AuthContext)
    const [agree, setAgree] = useState(true)
    const [pro, setPro] = useState(false)
    const { state: {lang} } = useContext(TransactionContext)
    const [passwordHidden, setPasswordHidden] = useState(true)

    // Alert stuff
    const [showAlert,setShowAlert] = useState(false)
    const [alertTitle,setAlertTitle] = useState(null)
    const [alertMsg,setAlertMsg] = useState(null)
    const [alertConfirmText,setAlertConfirmText] = useState(null)
    const [alertCancelText,setAlertCancelText] = useState(null)
    const [cancelPressedCallback,setCancelPressedCallback] = useState(null)
    const [confirmPressedCallback,setConfirmPressedCallback] = useState(() => () => {})
    const [alertShowCancelButton,setAlertShowCancelButton] = useState(false)
    
    useEffect(()=> {
        clearErrorMsg()
    },[])


    useEffect(() => {
        console.log('Error msg =',errorMsg)
      },[errorMsg])  


    const openSimpleAlert = (title, msg) => { // alert for simple alerts - title + message + confirm button that dismisses alert
        setAlertTitle(title)
        setAlertMsg(msg)
        setAlertConfirmText('OK')
        setAlertShowCancelButton(false)
        setConfirmPressedCallback(() => () => {setShowAlert(false)})
        setShowAlert(true)
    }      



const setProToken = (isPro) => {
    return new Promise((resolve,reject) => {
      //console.log('Setting is_pro in asyncstorage = ' , isPro)
      AsyncStorage.setItem('is_pro', isPro)
      set_pro(isPro === 'no' ? false : true)
      resolve()
    })
    }

const setProName = (name) => {
    return new Promise((resolve,reject) => {
        //console.log('Setting pro name in asyncstorage = ' , name)
        AsyncStorage.setItem('pro_name', name)
        set_pro_name(name)
        resolve()
    })

    }
    
const handleSignUp = () => {
    if (email && password && email.includes('@')) { // valid login
        // with exception for mail addresses without @ - firebase can't handle them
        signup(email,password,name,pro) // Do sign up to firebase after validating email and password exists. (Extra validations are in signup in AuthContext)
        
        
        if (pro) { // valid login - pro
            setProToken('yes').then(() => 
                setProName(name).then(() => {
                    
                })
          )
        } else {// valid login - not pro
            setProToken('no').then(() => 
                setProName('').then(() => {
                
                })
            )
        }




    } else {
        openSimpleAlert('Error',i18n.t("Alerts.MustFillEmailPassword"))
    }
}

    return (
            <Animatable.View animation="fadeIn" duration={1000} style={styles.mainView}>
                <View style={styles.inputsContainer}>
                    <TextInput  autoCapitalize='none' 
                                autoCorrect={false} 
                                style={[styles.input, {textAlign : lang === 'he' ? 'right' : 'left'} ]}
                                placeholder={i18n.t("UserAuth.EmailPlaceholder",{locale: lang})}
                                placeholderTextColor={'#a3a3a3'}
                                placeholderStyle={{fontFamily:'latoregular'}}
                                returnKeyType='done'
                                onChangeText={ (text) => setEmail(text) }
                                                    />
                    <TextInput  autoCapitalize='none' 
                                autoCorrect={false} 
                                style={[styles.input, {textAlign : lang === 'he' ? 'right' : 'left'} ]}
                                placeholder={i18n.t("UserAuth.NamePlaceholder",{locale: lang})}
                                placeholderTextColor={'#a3a3a3'}
                                placeholderStyle={{fontFamily:'latoregular'}}
                                returnKeyType='done'
                                onChangeText={ (text) => setName(text) }
                                                    />
                    <View>
                        <TextInput  autoCapitalize='none' 
                                    autoCorrect={false} 
                                    style={[styles.input, {textAlign : lang === 'he' ? 'right' : 'left'} ]}
                                    placeholder={i18n.t("UserAuth.PasswordPlaceholder",{locale: lang})}
                                    placeholderTextColor={'#a3a3a3'}
                                    placeholderStyle={{fontFamily:'latoregular'}}
                                    returnKeyType='done'
                                    onChangeText={ (text) => setPassword(text) }
                                    secureTextEntry={passwordHidden ? true : false}
                                    />
                        <MaterialCommunityIcons 
                          style={{position:'absolute', right: 10, bottom: 15}}
                          name={passwordHidden ? "eye" : "eye-off"}
                          size={24}
                          onPress={() => {setPasswordHidden(!passwordHidden); console.log('hello')}}
                          color="#A9042B"/>

                    </View>
                </View>
                <Text style={{color:'#ff4747', alignSelf:'center',fontFamily:'latoregular', fontSize:16}}>{errorMsg}</Text>
                <CheckBox title='Are you a professional (aesthetician/cosmetician)?' checked={pro} 
                                                    center 
                                                    iconRight
                                                    checkedColor='#A9042B'
                                                    uncheckedColor='#000'
                                                    size={20}
                                                    onPress={() => {setPro(!pro);}}
                                                    containerStyle={{width: '100%',backgroundColor: 'transparent', borderColor:'transparent'}}
                                                    textStyle={{color:'#000', fontWeight:'normal',fontSize: 16}}
                                                    fontFamily='latoregular'
                                                    titleProps={{alignSelf:'center', textAlign:'center'}}
                                        />
                <LinearGradient colors={['#A0002A', '#D31631']} style={{borderRadius: 11}}>
                    <View>
                        <TouchableOpacity onPress={handleSignUp} style={styles.button}>
                            <Text style={styles.buttonText}>{i18n.t("UserAuth.SignUpBtn",{locale: lang})}</Text>
                        </TouchableOpacity>                        
                    </View>
                </LinearGradient>
                <AwesomeAlert
                    show={showAlert}
                    title={alertTitle}
                    message={alertMsg}
                    closeOnTouchOutside={true}
                    closeOnHardwareBackPress={false}
                    showConfirmButton={true}
                    showCancelButton={alertShowCancelButton}
                    cancelText={alertCancelText}
                    confirmText={alertConfirmText}
                    confirmButtonColor="#A9042B"
                    cancelButtonColor="#A9042B"
                    onDismiss={() => setShowAlert(false)}
                    onCancelPressed={cancelPressedCallback}
                    onConfirmPressed={confirmPressedCallback}
                    actionContainerStyle={{flexDirection: 'row-reverse'}}/>
            </Animatable.View>
        );
    }


    

const styles = StyleSheet.create({
    mainView:{
        flexDirection:'column',
        justifyContent:'space-evenly',
        alignItems: 'center',
        flex: 1,
        marginTop: isComputerWeb ? '2%' : '5%',
        marginBottom: WINDOW_HEIGHT*0.1
        // backgroundColor:'green'
    },

    inputsContainer: {
        width: WINDOW_WIDTH * 0.872,
    },

    button: {
        // borderRadius: 11,
        width: WINDOW_WIDTH * 0.7,
        height: WINDOW_HEIGHT * 0.07,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems:'center'

    },
    buttonText: {
        fontFamily:'latobold',
        fontSize:18,
        color: '#fff',
        alignSelf:'center',
    },


input: {
    marginVertical:10,
    width: WINDOW_WIDTH*0.80, 
    borderBottomColor:'#A9042B',
    borderBottomWidth:2,
    color:'#000',
    fontFamily:'latoregular',
    padding:3,
    fontSize: 16,
    alignSelf:'center',
},


});

export default Signup;