import createDataContext from './createDataContext';
import { firebaseAuth } from '../firebase/config';
import { navigate } from '../navigationRef';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Alert } from 'react-native';
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import * as GoogleSignIn from 'expo-google-sign-in';
import * as Google from 'expo-google-app-auth';

const baseURL = 'https://api.srcosmeticsusa.com:8000'


i18n.translations = {
  en: english,
  he: hebrew
}


const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'add_error':
      console.log('Setting errormsg = ', action.payload)
      return { ...state, errorMsg: action.payload}
    case 'clear_error':
      console.log('Clearing errormsg')
      return { ...state, errorMsg: ''}
    case 'signin':
      return { ...state, errorMsg: '', currentUser: action.payload}
    case 'signout':
      return {  ...state, errorMsg: '', currentUser: null}
    case 'set_pro':
      return { ...state , isPro: action.payload}
    case 'set_pro_name':
      return { ...state , proName: action.payload}
    case 'set_pro_mail':
      return { ...state ,errorMsg: '', proMail: action.payload}
    case 'set_alert_msg':
      return {...state, alertMsgFromAuthContext: action.payload}
    case 'dismiss_alert':
      return {...state, alertMsgFromAuthContext: ''}
    case 'set_alert_msg_with_user_cred':
      return {... state, alertMsgFromAuthContext: action.payload.name, userCred: action.payload.userCred }
    default:
      return state;
  }
};

const clearErrorMsg = dispatch => () => {
    dispatch({type: 'clear_error'})
}

const signup = dispatch => (email,password,name,pro) => {
  firebaseAuth.createUserWithEmailAndPassword(email,password)
  .then((userCredentials) => {
      var user = firebaseAuth.currentUser;
      user.sendEmailVerification().then(() => {
      }).catch((error) => {
        console.log(error)
      });

      userCredentials.user.updateProfile({displayName: name}).then(()=> {
      // dispatch({type: 'signin', payload:userCredentials})
      firebaseAuth.signOut().then(()=>{
        dispatch({type: 'signout'})            
        dispatch( {type: 'set_alert_msg_with_user_cred', payload: {name: 'AlmostDoneEmailVerification', userCred: userCredentials.user}} )
        navigate('analyzerFlow')
      })
    })
  })
  .catch(error => {
    switch(error.code){
      case 'auth/invalid-email':
        dispatch({type: 'add_error', payload: i18n.t('Alerts.InvalidEmail')})
        break;
      case 'auth/email-already-in-use':
        dispatch({type: 'add_error', payload: i18n.t('Alerts.EmailInUse')})
        break;
      case 'auth/weak-password':
        dispatch({type: 'add_error', payload: i18n.t('Alerts.WeakPass')})
        break;
      default:
        dispatch({type: 'add_error', payload: i18n.t('Alerts.UnknownError')})
        break;

    }
  })
  }

const signinSocial = dispatch => (userData) => {
  dispatch({type: 'signin', payload: userData})
}

const tryWriteNewUserToKibana = dispatch => async (user) => { 
  // google/facebook/apple, Email login writing to kibana is in signin method
  //console.log('TRYING TO WRITE NEW USER')
  const method = "POST"
  const headers = {Accept: 'application/json','Content-Type': 'application/json'}
  const body = JSON.stringify(user)
  const url = baseURL+'/update_new_user_in_db'
  const requestInfo = {method, headers, body}
  //console.log(url,requestInfo)
  fetch(url,requestInfo).then( response => response.json() ).then(response => {
    //console.log(response)
} )

} 

const signin = dispatch => async ({email,password}) => {

  firebaseAuth.signInWithEmailAndPassword(email,password)
  .then(({user}) => {
    // //console.log(user)
    if (user.emailVerified){
      dispatch({type: 'signin', payload:user})
      dispatch( {type: 'set_alert_msg', payload: 'NowLoggedIn'} )
      navigate('analyzerFlow')

      //check if need to write new user to kibana 
      //console.log('TRYING TO WRITE NEW USER')
      const method = "POST"
      const headers = {Accept: 'application/json','Content-Type': 'application/json'}
      let new_user = {
        Email: user.email,
        Provider: 'Email',
        Date: Date.now(),

        Name: user.displayName,
        Firebase_UID: user.uid
      }
      const body = JSON.stringify(new_user)
      const url = baseURL+'/update_new_user_in_db'
      const requestInfo = {method, headers, body}
      fetch(url,requestInfo).then( response => response.json() ).then(response => {
        //DO HERE SOMETHING WITH RESPONSE?
    } )
      // end of writing to kibana if new user
    } else {
      // user not verified yet
      dispatch( {type: 'set_alert_msg_with_user_cred', payload: {name: 'NotVerifiedYet', userCred: user}} )
      navigate('analyzerFlow')            
}
  })
  .catch(error => {
      switch(error.code){
        case 'auth/invalid-email':
          dispatch({type: 'add_error', payload: i18n.t('Alerts.InvalidEmail')})
          break;
        case 'auth/wrong-password':
          dispatch({type: 'add_error', payload: i18n.t('Alerts.WrongPassword')})
          break;
        case 'auth/user-not-found':
          dispatch({type: 'add_error', payload: i18n.t('Alerts.UserNotFound')})
          break;
        case 'auth/too-many-requests':
          dispatch({type: 'add_error', payload: i18n.t('Alerts.TooManyRequests')})
          break;
        default:
          dispatch({type: 'add_error', payload: i18n.t('Alerts.UnknownError')})
          break;
      }
      //console.log(error.code)
  })
}

const tryLocalSignin = dispatch => () => {
  return new Promise(  (resolve, reject)=> {
    firebaseAuth.onAuthStateChanged(function(user) {
      // //console.log(user)
    if (user && user.emailVerified) {
      dispatch({type: 'signin', payload:user})
      // //console.log(user)
      resolve(user)
    } 
    else {
    resolve(null)
    }

  })})}


const tryLocalSigninPro = dispatch => () => {
  return new Promise(  (resolve, reject)=> {

    AsyncStorage.multiGet(['is_pro','pro_name'], (err,result) => {
      if (result[0][1]==null || result[0][1] == 'no') {
        dispatch({type:'set_pro', payload: false})
        resolve('not pro')
      } else {
        dispatch({type: 'set_pro', payload: true})
        dispatch({type: 'set_pro_name', payload: result[1][1]})
        resolve('pro')
      }
  })
  })
}




    //check if pro --- PROBLEM IN AUTO LOGIN FOR REGULAR USERS HERE
 // either way we navigate to analyzer, with current user parameter (null if no user currently)

const signout = dispatch => async (showAlert) => { 
  if (firebaseAuth.currentUser){
    firebaseAuth.signOut().then(() => {
      dispatch({type: 'signout'})            
    })
    if(showAlert) {
      dispatch( {type: 'set_alert_msg', payload: 'ByeBye'} )
    }
  } 
  
  else { 
    if(showAlert){
      dispatch( {type: 'set_alert_msg', payload: 'AlreadyLoggedOut'} )
    }  
  }
  navigate('UPLOAD');

}



const setAlertMsgFromAuthContext = dispatch => (msgName) => {
  dispatch( {type: 'set_alert_msg', payload: msgName} )
}


const dismissAlertFromAuthContext = dispatch => () => {
  dispatch({type: 'dismiss_alert'})
}


const set_pro = dispatch => (isPro) => {
  dispatch({type:'set_pro', payload: isPro})
}

const set_pro_name = dispatch => (name) => {
  dispatch({type:'set_pro_name', payload: name})
}

const set_pro_mail = dispatch => (mail) => {
  dispatch({type:'set_pro_mail', payload: mail})
}

export const { Context, Provider } = createDataContext(
    AuthReducer,
  { 
    signup, clearErrorMsg, signout, signin, tryLocalSignin,
    tryLocalSigninPro, set_pro, set_pro_name, set_pro_mail,
    signinSocial, tryWriteNewUserToKibana,setAlertMsgFromAuthContext , dismissAlertFromAuthContext
  },
  { errorMsg: '', currentUser: null, isPro : false, proName: null, alertMsgFromAuthContext:'', userCred:''} 
);
