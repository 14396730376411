import React, { useContext,useState } from 'react';
import { StyleSheet, View, Text, ImageBackground, ScrollView, Dimensions, Platform} from 'react-native';
import TermsTextEnglish from '../components/TermsTextEnglish';
import PrivacyPolicyTextEnglish from '../components/PrivacyPolicyTextEnglish';
import {Context as TransactionContext} from '../context/TransactionContext'
import * as Device from 'expo-device';


const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 500 : Dimensions.get('window').width 
// const WINDOW_WIDTH = Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;


const TernsScreen = () => {
    const { state: {lang} } = useContext(TransactionContext)
    
    return (
      <ImageBackground
      source={require('../../assets/app_background.png')}
      resizeMode='cover'
      style={styles.background}>
        <View style={styles.mainContainer}>
          <ScrollView showsVerticalScrollIndicator={true} indicatorStyle='black'>
              <>
                <Text style={styles.title}>Face-It Terms & Conditions</Text>
                <TermsTextEnglish/>
                <Text style={styles.title}>Face-It Privacy Policy</Text>
                <PrivacyPolicyTextEnglish/>
              </> 
          </ScrollView>
        </View>
        </ImageBackground>
    );
}

const styles = StyleSheet.create({
    mainContainer: {
        width: isComputerWeb ? WINDOW_WIDTH*1.3 : WINDOW_WIDTH, 
        backgroundColor: 'transparent',
        flex:1, 
        paddingHorizontal:10,
        marginTop: WINDOW_HEIGHT*0.1 + 5,
        alignSelf:'center',
        marginBottom: WINDOW_HEIGHT*0.1 + 5,
      },
      title: {
        fontFamily:'latobold', 
        color:'#000', 
        padding: 20, 
        textAlign:'center',
        fontSize:22
      },
      background: {
        width: '100%',
        height: '100%',
        flex: 1,
        position: 'absolute',
        top: 0,
        left: 0
    },

    
});

export default TernsScreen;