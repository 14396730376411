
import firebase from "firebase/app" 
import "firebase/auth";
import "firebase/firestore"
import "firebase/storage"

const app = firebase.initializeApp({
  apiKey: 'AIzaSyCfucQYYm2pa_ZfndrTYnMUyAXaWwF5GVc',
  authDomain: 'face-it-usa.firebaseapp.com',
  databaseURL: 'https://face-it-usa.firebaseio.com/',
  projectId: 'face-it-usa',
  storageBucket: 'face-it-usa.appspot.com',
  messagingSenderId: '2332342585',
  appId: '1:2332342585:web:8a1d5e8c4b8350a07310e8',
})

export default app

export const firebaseAuth = app.auth()
export const firebaseFirestore = app.firestore()
export const firebaseStorage = app.storage()

export const firebaseGoogleAuthProvider = firebase.auth.GoogleAuthProvider
export const firebaseFacebookAuthProvider = firebase.auth.FacebookAuthProvider
export const firebaseOAuthProvider = firebase.auth.OAuthProvider


