import React, { useContext,useEffect,useState } from 'react';
import { View, StyleSheet, Text, FlatList,Dimensions,ActivityIndicator,ImageBackground, Platform , TouchableOpacity} from 'react-native';
import {Context as HistoryContext} from '../context/HistoryContext'
import {Context as TransactionContext} from '../context/TransactionContext'
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import * as MediaLibrary from 'expo-media-library';
import { NavigationEvents } from 'react-navigation';
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Device from 'expo-device';
import { LinearGradient } from 'expo-linear-gradient';


const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
// const WINDOW_WIDTH = Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;

i18n.translations = {
    en: english,
    he: hebrew
}
const HistoryScreen = ({navigation}) => {
    const { state: {historyFileNames} } = useContext(HistoryContext)
    const { state: {lang} } = useContext(TransactionContext)
    // const [galleryAssets,setGalleryAssets] = useState([]);
    const [loadingHistory, setLoadingHistory] = useState(false)
    const [historyAssets, setHistoryAssets] = useState([])
    const [pro,setPro] = useState(null)


    useEffect(() => {
        // getAllAssetsFromGallery()
        checkIfPro();
    }, []);


    useEffect(() => {
        if(historyFileNames && historyFileNames.length>0 && Platform.OS !== 'web')
            getAllAssetsFromGallery()
        else {
            setHistoryAssets([])
        }
    }, [historyFileNames])

    const checkIfPro = async () => {
        const ispro = await AsyncStorage.getItem('is_pro')
        if (ispro === null || ispro==='no'){
            setPro(false)
        }
        else setPro(true)


        // setPro(true)
    }
    const getAllAssetsFromGallery = async() => {
            setLoadingHistory(true)
            //console.log('1: ', historyFileNames)
            const albumsResult = await MediaLibrary.getAlbumsAsync()
            const face_it_album = albumsResult.find(element => {
                return element.title === 'Face-It'
            })
            const face_it_album_id = face_it_album.id 
            const face_it_assets = await MediaLibrary.getAssetsAsync({first: 100000, album:face_it_album_id})
            if (historyFileNames.length > 0 ){
                const _historyAssets = []
                face_it_assets.assets.forEach(element => {
                    if (historyFileNames.includes(element.filename)) {
                        _historyAssets.push(element)
                        //console.log('PUSHING: ', element)
                    }
                });
                setHistoryAssets(_historyAssets)

            } else {
                setHistoryAssets([])
            }
            setLoadingHistory(false)
    }


    const _renderItem = ({item,index}) => {

        let assetDate = new Date(item.modificationTime) // assetDate has the gallery asset date in milis from 1970
        let nowDate = new Date() // nows date in milis from 1970
        assetDate.setHours(0,0,0,0) // setting time to 00:00 to measure exact days difference
        nowDate.setHours(0,0,0,0) // setting time to 00:00 to measure exact days difference
        let differenceInTime = nowDate.getTime() - assetDate.getTime() // difference between now and asset dates in milis
        var differenceInDays = differenceInTime / (1000*3600*24) // difference between now date and asset date in days. 


        let y = assetDate.getFullYear() // For label
        let m = assetDate.getMonth() + 1 // For label
        let d = assetDate.getDate(); // For label
        var fullAssetDateString = d + "/" + m + "/" + y // For label
        return (
            <View style={styles.itemContainer}>
                <ImageBackground style={{ height: WINDOW_HEIGHT * 0.232,width: WINDOW_WIDTH * 0.42}} 
                                 imageStyle={{borderRadius: 8}} 
                                 source={{uri: item.uri}}>
                    <View style={styles.bottomInfo}>
                        <Text style={{fontFamily:'latoregular', color:'#A0002A', padding:5,fontSize:12}}>
                            {   differenceInDays == 0  ? 'Today'     : 
                                differenceInDays == 1  ? 'Yesterday' :
                                `${differenceInDays} days ago`
                            } 
                        </Text>
                        <Text style={{fontFamily:'latoregular', color:'#A0002A', padding:5,fontSize:12}}>
                            {fullAssetDateString}
                        </Text>
                    </View>
                </ImageBackground>
            </View>
        );
    }

    return (
        <ImageBackground
        source={require('../../assets/app_background.png')}
        resizeMode='cover'
        style={styles.background}>
            <View style={[styles.container]}>
                <NavigationEvents onWillFocus={() => checkIfPro()}/>
            { pro && historyAssets.length > 0 && !loadingHistory ?
            <>
                <LinearGradient colors={['#A0002A', '#D31631']} style={styles.backToAnalyzeButton}>
                    <View>
                        <TouchableOpacity 
                                        onPress={() => navigation.navigate('analyzerFlow')}>
                            <Text style={styles.backToAnalyzeButtonText}>
                                Back To Analyze Screen
                            </Text>
                        </TouchableOpacity>
                    </View>
                </LinearGradient>        
                <FlatList data={historyAssets.sort((a,b) => a.modificationTime < b.modificationTime)} renderItem={_renderItem}
                    showsVerticalScrollIndicator={false}
                    keyExtractor={(item) => item.filename}
                    horizontal={false}
                    numColumns={2}
                    columnWrapperStyle = {{alignItems:'flex-start'}}
                    ListFooterComponent= {() => (
                        <View style={{height: WINDOW_HEIGHT * 0.5}}>
                            {/* Empty footer to allow last row to be shown fully */}
                        </View>
                    )}
                />
            </>
                : pro && historyAssets && historyAssets.length === 0 && loadingHistory ?   <ActivityIndicator size="small" color="#A9042B"/>
                : historyAssets.length === 0 && pro && Platform.OS !== 'web'? 
                    <Text style={{padding:10, color:'#000', fontFamily:'latobold', textAlign:'center'}}>{i18n.t("History.HistoryEmpty", {locale:lang})}</Text>
                : Platform.OS === 'web' ? 
                    <Text style={{padding:10, color:'#000', fontFamily:'latobold', textAlign:'center'}}>History feature is available only on mobile version of Face-It USA</Text>
                :   
                    <Text style={{padding:10, color:'#000', fontFamily:'latobold', textAlign:'center'}}>{i18n.t("History.HistoryOnlyPros", {locale:lang})}</Text>
                }
            </View>
        </ImageBackground>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection:'column',
        flex:1,
        marginTop: WINDOW_HEIGHT*0.1 + 5,
        marginHorizontal:15
    },

    itemContainer: {
        justifyContent: 'space-between',
        flex:0.5,
        alignSelf:'center',
        alignItems: 'center',
        marginBottom:10,
        padding: 10,
        borderRadius: 8

    },

    background: {
        width: '100%',
        height: '100%',
        flex: 1,
        position: 'absolute',
        top: 0,
        left: 0
    },

    bottomInfo:{
        position:'absolute',
        bottom: 0,
        backgroundColor:'#fff',
        opacity: 0.6,
        elevation: 20,
        borderRadius: 8,
        shadowColor: "#A6022B3D",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 20,
        shadowRadius: 16.32,
        justifyContent:'space-between',
        flexDirection:'row',
        alignItems:'center',
        width:'100%',
        height: '20%',
        paddingHorizontal: 5,
    },

    backToAnalyzeButton: {
        // backgroundColor:'#A9042B',
        // background: linear-gradient('#A0002A', '#D31631'),
        width: WINDOW_WIDTH * 0.55,
        height: 45,
        justifyContent:'center',
        alignSelf:'center',
        borderRadius: 6,
        marginBottom:10
    },
    
    
    backToAnalyzeButtonText: {
        color: '#fff',
        fontSize:14,
        textAlign: "center",
        fontFamily:'latoregular'
    },
    
    
    
});

export default HistoryScreen;