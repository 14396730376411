import React, {useContext, useEffect, useState} from 'react';
import { Text,View, TouchableOpacity, PixelRatio, Image, StyleSheet, Dimensions,Platform} from 'react-native';
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import i18n from 'i18n-js'
import english from '../lang/en.json'
import hebrew from '../lang/he.json'
import { ProgressBar } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import * as Linking from 'expo-linking';
import { startActivityAsync, ActivityAction } from 'expo-intent-launcher';
import * as Application from 'expo-application';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { navigate } from '../navigationRef';
import * as Device from 'expo-device';


i18n.translations = {
    en: english,
    he: hebrew
}

const pkg = Application.applicationId
const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const isWeb = Platform.OS === 'web' 
const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
const WINDOW_HEIGHT = Dimensions.get('window').height;
const PR = PixelRatio.get()
const NUM_OF_STEPS = 5
const TUTORIAL_1 = require('../../assets/tutorial_1.png')
const TUTORIAL_2 = require('../../assets/tutorial_2.png')
const TUTORIAL_3 = require('../../assets/tutorial_3.png')
const TUTORIAL_ICONS = [TUTORIAL_1,TUTORIAL_2,TUTORIAL_3,TUTORIAL_1,TUTORIAL_1]

const Tutorial = ({onConfirm,isFromMenu}) => {
    const [stepNum, setStepNum] = useState(1) // count steps, 1 2 3 4 5
    const [icon,setIcon] = useState(TUTORIAL_1)

    useEffect(() => {
        // onConfirm()
    },[])
    
    useEffect(() => {
        setIcon(TUTORIAL_ICONS[stepNum-1]) // set new icon
        //console.log('step:' , stepNum)
    },[stepNum])

    const getStepTitle = () => {
        switch (stepNum) {
            case 1:
                return 'FIRST STEP'
            case 2:
                return 'SECOND STEP'
            case 3: 
                return 'THIRD STEP'
            case 4:
                return 'BEFORE WE BEGIN...'
            case 5:
                return 'We\'re set to go!'
            default:
                return null
            
        }
    }

    const getInstruction = () => {
        switch (stepNum) {
            case 1:
                return 'Scan or upload an\nimage of your face'
            case 2:
                return 'Get your\nanalysis result'
            case 3: 
                return 'View matching products\nto your skin condition'
            case 4:
                if (Platform.OS === 'android'){
                    return 'For full App performance (Analyzing the skin) click \'Allow\', mark the Permission check box of \'Camera\' & \'Gallery/Media\' and return to Face-it.'
                }
                else if (Platform.OS === 'ios') {
                    return 'For full App performance, while using the app, tap on \'Take a picture\' and choose \'Camera\', and then tap on \'Upload an Image\' and choose \'Gallery\''
                }
            case 5:
                return 'Note: you can change permissions anytime from phone\'s settings.'    
            default:
                return null
            
        }
    }

    const getBtnText = () => {
        switch (stepNum) {
            case 1:
                return 'Next Step'
            case 2:
                return 'Next Step'
            case 3: 
                return 'Next Step'
            case 4:
                if (Platform.OS === 'android') {
                    return 'Approve'
                }
                else if (Platform.OS === 'ios') {
                    return 'Next Step'
                }
            case 5:
                return 'LETS GO'
            default:
                return null
            
        }
    }



    const handleBtnPress = async () => {
        switch (stepNum) {
            case 1:
                setStepNum(stepNum + 1) // increase step
                break;
            case 2:
                setStepNum(stepNum + 1) // increase step
                break;
            case 3:
                if (Platform.OS === 'web' || isFromMenu) {
                    setStepNum(stepNum + 2) // skip the permissions part on web or if we came from menu
                } else  {
                    setStepNum(stepNum + 1)
                } // increase step
                break;
            case 4:
                //web platform skips this step
                // go to permissions page
                // then, increase step number
                if (Platform.OS === 'android') {
                    await startActivityAsync(ActivityAction.APPLICATION_DETAILS_SETTINGS, {data: 'package:' + pkg});
                } setStepNum(stepNum + 1) // increase step
                break;
            case 5:
                //console.log('case 5')
                onConfirm()
                navigate('ResolveAuthScreen')
            default:
                break;

        }
    }

    const handleSkipPress = () => {
        if (Platform.OS === 'web'){
            setStepNum(5) // go to 'Lets start page'
        } else { // Mobile
            stepNum<4 ? setStepNum(4) : setStepNum(5) // if before permissions page - go to permissions page, else go to final page
        }
    }

    return ( // already in flex=space-around from parent
        <>
            <Image style={styles.logoContainer} source={require('../../assets/tutorial_logo.png')}/>
            <Image style={styles.imageContainer} source={icon} resizeMode='contain'/>
            <View style={styles.instructionsContainer}>
                <Text style={styles.title}>{getStepTitle()}</Text>
                <Text style={styles.instruction}>{getInstruction()}</Text>

                <ProgressBar style={styles.progressBar} progress={stepNum/NUM_OF_STEPS} color="#ce1c38">test</ProgressBar>
                <LinearGradient colors={['#A0002A', '#D31631']} style={{borderRadius:6}}>
                    <View>
                        <TouchableOpacity  onPress={handleBtnPress} style={styles.btn}>
                            <Text style={styles.btnText}>{getBtnText()}</Text>
                        </TouchableOpacity>
                    </View>
                </LinearGradient>

                { 
                    stepNum < 5 &&
                    <TouchableOpacity onPress={handleSkipPress}>
                        <Text style={styles.skipText}>Skip</Text>
                    </TouchableOpacity>
                }
                
            </View>
        </>        
)
}


const styles = StyleSheet.create({
    logoContainer: {
        height: 26,
        width: 118,
        marginTop:20
    },

    imageContainer: {
        height: 244,
        width: 211,
    },

    instructionsContainer: {
        alignItems:'center',
        justifyContent:'space-between',
        height: WINDOW_HEIGHT*0.46,
        backgroundColor:'#fff',
        width:WINDOW_WIDTH,
        borderTopLeftRadius:45,
        borderTopRightRadius:45,
        paddingVertical: isComputerWeb ? '5%' : '10%',
    },

    title: {
        fontSize: PR <= 2 ? 30 : 36,
        fontFamily:'latobold',
        color: '#D31631',
        textAlign:'center'
    },

    instruction: {
        fontSize: PR <= 2 ? 14 : 16,
        fontFamily:'latoregular',
        color: '#000',
        textAlign:'center',
        paddingHorizontal:20,
        flexWrap:'wrap'

    },

    progressBar: {
        backgroundColor:'#f7d0d4',
        height:10,
        width:WINDOW_WIDTH*0.4,
        borderRadius:20,
        marginVertical:15
    },

    btn: {
        width: WINDOW_WIDTH * 0.7,
        height: WINDOW_HEIGHT * 0.07,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems:'center',
        alignSelf:'center',
    },

    btnText: {
        textAlign:'center',
        color:'#fff',
        fontFamily:'latoregular',
        fontSize:PR <= 2 ? 14 : 18
    },

    skipText: {
        textAlign:'center',
        color:'#000',
        fontFamily:'latoregular',
        fontSize:PR <= 2 ? 12 : 16,
        textDecorationStyle:'solid',
        textDecorationLine: 'underline',
        marginVertical:10
    }

})

export default Tutorial