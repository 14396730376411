import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, Text,Dimensions, ImageBackground , TouchableOpacity, Platform} from 'react-native';
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import Signin from '../../components/Signin'
import Signup from '../../components/Signup'
import {Context as AuthContext} from '../../context/AuthContext'
import {Context as TransactionContext} from '../../context/TransactionContext'
import i18n from 'i18n-js';
import english from '../../lang/en.json'
import hebrew from '../../lang/he.json'
import * as Device from 'expo-device';

const isComputerWeb = Platform.OS === 'web' && (Device.osName !== 'Android' && Device.osName !== 'iOS' && Device.osName !== 'iPadOS')
const isWeb = Platform.OS === 'web'

const WINDOW_WIDTH = isComputerWeb ? 400 : Dimensions.get('window').width 
const WINDOW_HEIGHT = Dimensions.get('window').height;
// const WINDOW_WIDTH = Dimensions.get('window').width;
var toggleDrawer

i18n.translations = {
  en: english,
  he: hebrew,
}

const AuthMainScreen = ({navigation}) => {
  const { state: {currentUser}, signout } = useContext(AuthContext)
  const { state: {lang} } = useContext(TransactionContext)

  const [currentForm, setCurrentForm] = useState('signin')

  useEffect(() => {
    toggleDrawer = navigation.toggleDrawer
  }, []);


  

    return (
      <ImageBackground source={require('../../../assets/app_background.png')} resizeMode='cover' style={styles.background}>
        <View style={styles.mainView}>
            <View style={{flexDirection: i18n.locale === 'he' ? 'row-reverse' :'row'}}> 
                <TouchableOpacity style={currentForm == 'signin' ? styles.activeBtn : styles.unactiveBtn} onPress={() => setCurrentForm('signin')}>
                    <Text style={[currentForm == 'signin' ? styles.activeTxt : styles.unactiveTxt]}>{i18n.t('UserAuth.SignIn',{locale: lang})}</Text>
                </TouchableOpacity>
                {!currentUser && <TouchableOpacity style={[currentForm == 'signup' ? styles.activeBtn : styles.unactiveBtn]} onPress={() => setCurrentForm('signup')}>
                    <Text style={[currentForm == 'signup' ? styles.activeTxt : styles.unactiveTxt]}>{i18n.t('UserAuth.SignUp',{locale: lang})}</Text>
                </TouchableOpacity>}
            </View>
            {currentForm === 'signin' ? <Signin navigation={navigation}/> : <Signup navigation={navigation}/>}
        </View>
      </ImageBackground>
    );
    }
  
const styles = StyleSheet.create({
  mainView:{
    flexDirection:'column',
    alignItems: 'center',
    flex: 1,
    marginTop: isComputerWeb ? WINDOW_HEIGHT*0.1 + 35 : WINDOW_HEIGHT*0.1 + 10, // litlle bit more than header height
    marginBottom: isComputerWeb ? '2%' : '5%',
    marginHorizontal: 40
  },

  activeBtn:
  {
    marginHorizontal:10, 
    backgroundColor:'#A9042B',
    borderRadius:11
  },

  activeTxt: {
    fontFamily:'latobold', 
    color:'#fff', 
    paddingVertical:8,
    paddingHorizontal:15
  },

  unactiveBtn: {
    marginHorizontal:10, 
  },

  unactiveTxt:{
    fontFamily:'latobold', 
    color:'#000', 
    paddingVertical:8,
    paddingHorizontal:15
  },

  background: {
    width: '100%',
    height: '100%',
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0
  }

})
export default AuthMainScreen;